var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"vh-footer pd20 lynn-web-in overout"},[_c('div',{staticClass:"web-side"}),_c('div',{staticClass:"location-box np"},[_c('div',{staticClass:"web-logo"},[_c('Weblogo')],1),_c('a',{staticClass:"back",on:{"click":function($event){return _vm.$router.push({ name: 'dwelling' })}}},[_c('Back')],1),_c('strong',{staticClass:"lo-title"},[_vm._v("라운지")])]),_c('v-card',{staticClass:"mx-auto nops",staticStyle:{"border-radius":"0"},attrs:{"max-width":"750"}},[_c('v-card-text',{staticClass:"pd0 pt36"},[_c('div',{staticClass:"tabs-box full"},[_c('ul',{staticClass:"tab-menu"},[_c('li',{class:{ active: _vm.openTab === 0 }},[_c('a',{attrs:{"href":"#n"},on:{"click":function($event){_vm.openTab = 0;
                _vm.infiniteId = 1;}}},[_vm._v("전체 글")])]),_c('li',{class:{ active: _vm.openTab === 1 }},[_c('a',{attrs:{"href":"#n"},on:{"click":function($event){_vm.openTab = 1;
                _vm.infiniteId = 2;}}},[_vm._v("내 글")])])])]),_c('div',{staticClass:"sel-inbtn type3 max mt10"},[_c('v-text-field',{staticClass:"sel-cus ft-l",staticStyle:{"width":"90%"},attrs:{"label":"검색","hide-details":"","single-line":"","name":"name","id":"id"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search()}},model:{value:(_vm.search_param),callback:function ($$v) {_vm.search_param=$$v},expression:"search_param"}}),_c('a',{staticClass:"ft-r",on:{"click":function($event){return _vm.search()}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),_c('div',{staticClass:"tab-contents"},[(_vm.openTab == 0)?_c('div',[_c('div',{staticClass:"write-btn"},[_c('router-link',{attrs:{"to":"/loungeWrite/"}},[_c('Write')],1)],1),_c('ul',{staticClass:"dan-list"},[_c('li',{staticClass:"dan-text"},[_c('p',[_vm._v(" ※ 게시글에는 작성자의 실명이 공개되며 이는 게시판 악용을 방지하기 위함입니다. 부적절한 게시글은 관리자에 의해 임의로 삭제될 수 있습니다. ")])]),(_vm.boardList.length == 0)?_c('li',{staticClass:"no-list"},[_c('a',[_vm._v(" 게시물이 없습니다. ")])]):_vm._e(),_vm._l((_vm.boardList),function(board){return _c('li',{key:board.id,class:[{ 'done-list': board.is_reported }]},[(board.is_reported)?_c('div',{staticClass:"icon-box"},[_c('Done')],1):_vm._e(),(board.is_reported)?_c('p',[_vm._v(" 이 게시글은 10명 이상의 신고접수 또는 아파트 관리 정책에 위배되어 관리자에 의해 차단되었습니다. ")]):_vm._e(),(!board.is_reported)?_c('router-link',{attrs:{"to":{
                  name: 'loungeView',
                  params: { id: board.id, search_param: _vm.search_param },
                }}},[_c('strong',{staticClass:"kitbox sm"},[_vm._v(_vm._s(board.title))]),_c('div',{staticClass:"bottom-cont"},[_c('span',{staticClass:"name pwidth100"},[_vm._v(_vm._s(board.author_name))]),_c('ul',{staticClass:"cont pwidth50"},[_c('li',[_vm._v("공감 "+_vm._s(board.like_count))])]),_c('span',{staticClass:"date pwidth50"},[_vm._v(_vm._s(_vm.date(board.createdAt)))])])]):_vm._e()],1)})],2)]):_vm._e(),(_vm.openTab == 1)?_c('div',[_c('ul',{staticClass:"dan-list"},[(_vm.myBoardList.length == 0)?_c('li',{staticClass:"no-list"},[_c('a',[_vm._v(" 게시물이 없습니다. ")])]):_vm._e(),_vm._l((_vm.myBoardList),function(board){return _c('li',{key:board.id,class:[{ 'done-list': board.is_reported }]},[(board.is_reported)?_c('div',{staticClass:"icon-box"},[_c('Done')],1):_vm._e(),(board.is_reported)?_c('p',[_vm._v(" 이 게시글은 10명 이상의 신고접수 또는 아파트 관리 정책에 위배되어 관리자에 의해 차단되었습니다. ")]):_vm._e(),(!board.is_reported)?_c('router-link',{attrs:{"to":{
                  name: 'loungeView',
                  params: { id: board.id, search_param: _vm.search_param },
                }}},[_c('strong',{staticClass:"kitbox sm"},[_vm._v(_vm._s(board.title))]),_c('div',{staticClass:"bottom-cont"},[_c('span',{staticClass:"name pwidth100"},[_vm._v(_vm._s(board.author_name))]),_c('ul',{staticClass:"cont pwidth50"},[_c('li',[_vm._v("공감 "+_vm._s(board.like_count))])]),_c('span',{staticClass:"date pwidth50"},[_vm._v(_vm._s(_vm.date(board.createdAt)))])])]):_vm._e()],1)})],2)]):_vm._e(),_c('infinite-loading',{attrs:{"identifier":_vm.infiniteId,"spinner":"waveDots"},on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"},[_vm._v("마지막 게시물입니다.")]),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"},[_vm._v("마지막 게시물입니다.")])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }