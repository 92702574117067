<template>
  <v-container class="vh-footer ps-re lynn-mobile lynn-web-in bg-gray" fluid>
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.go(-2)" class="back">
        <Back />
      </a>
      <strong class="lo-title">입주 예약</strong>
    </div>
    <!-- <v-card-subtitle class="fts14 lh24"
      >입주 예정단지의 옵션 선택・변경을 위한 견본주택 방문예약</v-card-subtitle
    > -->
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text>
        <div style="padding-bottom: 100px">
          <!-- TODO LYN-895: 예약에 관한 설명을 나타내는 필드. 완료 by bong
          <ul class="resinfo-list" v-if="reservationMaster.description">
            <li class="ril-text" v-html="reservationMaster.description">
            </li>
          </ul>
          -->
          <v-form>
            <!-- Step 1: 단지,동,호수로 예약 가능 날짜 조회  -->
            <v-text-field
              :value="village_name"
              label="단지"
              readonly
            ></v-text-field>
            <v-text-field
              v-model="form.dong_no"
              label="동"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.ho_no"
              label="호"
              required
            ></v-text-field>

            <v-btn
              color="primary"
              class="fts18 ftw7 mw-400 pwidth100 mt15"
              min-height="50"
              block
              :disabled="!form.dong_no && !form.ho_no"
              @click="getReservationDetailAPI()"
            >
              예약가능일자 조회
            </v-btn>

            <!-- 입주 일, 시간 선택  -->
            <div v-if="show_calendar">
              <v-subheader class="pd0">입주일자</v-subheader>
              <v-date-picker
                v-model="form.date"
                :allowed-dates="allowedDates"
                @change="sync"
                :picker-date.sync="pickerDate"
                :day-format="(date) => new Date(date).getDate()"
                locale="KR"
                full-width
                no-title
              ></v-date-picker>
              <v-subheader class="pd0">입주시간</v-subheader>
              <v-slide-group :value="allowed_times.indexOf(form.timeinfo)">
                <v-slide-item
                  class="mr10 nh-50"
                  v-for="timeinfo in allowed_times"
                  :key="timeinfo.id"
                  v-slot="{ active, toggle }"
                >
                  <v-btn
                    :value="timeinfo"
                    :disabled="timeinfo.disabled"
                    :input-value="active"
                    active-class="v-active"
                    v-bind:class="[
                      {
                        'v-active':
                          timeinfo.id === form.timeinfo.id &&
                          form.date === sel_date &&
                          is_modify,
                      },
                      'pd10 color-2 bgc-3',
                    ]"
                    depressed
                    @click="
                      toggle;
                      form.timeinfo = timeinfo;
                      sel_date = '';
                    "
                  >
                    {{ timeinfo.text }} <br />
                    {{ timeinfo.tooltip }}
                  </v-btn>
                </v-slide-item>
              </v-slide-group>
              <!-- <div class="ladder-truck mt10">
                사다리차 사용 가능합니다. <br />
                <span style="font-size: 12px"
                  >(단, 불가 동호수 있을경우 엘리베이터 이용 바랍니다.)</span
                >
              </div> -->

              <!-- Step 3: 계약자,세입자 구분에 따라 정보 입력 -->

              <v-subheader class="pd0 mt30">정보</v-subheader>
              <v-radio-group
                class="pd0"
                label="신청자"
                v-model="form.ty_ipju"
                row
              >
                <v-radio value="O" label="계약자"></v-radio>
                <v-radio value="L" label="세입자"></v-radio>
              </v-radio-group>
              <!-- 계약자-->
              <v-subheader class="pd0">계약자</v-subheader>
              <v-radio-group class="pd0 mg0" v-model="form.ty_contractor" row>
                <v-radio value="P" label="개인"></v-radio>
                <v-radio value="C" label="법인"></v-radio>
              </v-radio-group>
              <v-text-field
                v-if="form.ty_contractor == 'P'"
                v-model="form.ds_contractor"
                label="이름"
                required
              ></v-text-field>
              <v-text-field
                v-if="form.ty_contractor == 'P'"
                v-model="form.dt_birth"
                label="생년월일"
                required
                :rules="birthRules"
              ></v-text-field>

              <v-text-field
                v-if="form.ty_contractor == 'C'"
                v-model="form.ds_vendor"
                label="상호"
                required
              ></v-text-field>
              <v-text-field
                v-if="form.ty_contractor == 'C'"
                v-model="form.cd_vendor"
                label="사업자번호"
                required
                :rules="vendorRules"
              ></v-text-field>

              <!-- 세입자 -->
              <v-subheader class="pd0" v-if="form.ty_ipju == 'L'"
                >세입자</v-subheader
              >
              <v-subheader class="pd0" v-else>신청자</v-subheader>
              <v-text-field
                v-model="form.nm_l"
                label="이름"
                required
              ></v-text-field>
              <v-text-field
                v-model="form.no_tel_l"
                label="전화번호"
                :rules="phoneRules"
                required
              ></v-text-field>
            </div>
          </v-form>
          <v-btn
            color="primary"
            class="fts18 ftw7 mw-400 pwidth100 mt15"
            min-height="50"
            v-if="!is_modify"
            block
            :disabled="!is_form_ready"
            @click="postReservationAPI"
          >
            입주 예약하기
          </v-btn>
          <v-btn
            color="primary"
            class="fts18 ftw7 mw-400 pwidth100 mt15"
            min-height="50"
            v-if="is_modify"
            block
            :disabled="!is_form_ready"
            @click="putReservationAPI"
          >
            수정하기
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import { get_now_string, isFuture, get_time_string } from "@/utils/time.js";
import {
  getReservationMasterList,
  // getReservationMaster,
  getMoveInReservationMaster,
  getReservationList,
  // postReservation,
  postMoveInReservation,
  getReservation,
  // putReservation,
  putMoveInReservation,
  delReservation,
} from "@/api/index.js";
export default {
  name: "ContractReservationForm",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
      reservation_code: "MOVEIN_RESERVATION", // 입주예약 타입
      show_calendar: false,
      form: {
        master: 0, // 예약마스터 id
        user: 0, // 유저 id
        time: 0, // 선택한 차수 id
        home: 0,
        date: get_now_string(), // 선택한 일자
        timeinfo: { text: "" }, // 선택한 차수 정보
        name: "",
        contact: "",
        dong_no: "",
        ho_no: "",

        ty_ipju: "",

        //계약자
        ty_contractor: "",
        ds_contractor: "",
        dt_birth: "",
        ds_vendor: "",
        cd_vendor: "",

        //세입자
        nm_l: "",
        no_tel_l: "",
      },
      menu: false,
      pickerDate: null,
      village: {},
      allowed_dates: [],
      allowed_times: [],
      is_modify: false,
      sel_date: "", // (수정의 경우) 저장된 날짜
      hometext: "",
      reservationMaster: {},

      reservationID: 0,
      phoneRules: [
        (v) => !!v || "휴대전화번호 '-'를 제외하고 입력",
        (v) => /^[0-9]+$/.test(v) || "휴대전화번호 '-'를 제외하고 입력",
        (v) => v.length < 13 || "전화번호가 너무 깁니다.",
      ],
      birthRules: [
        (v) => !!v || "생년월일 '-'를 제외하고 8자로 입력",
        (v) => /^[0-9]+$/.test(v) || "생년월일 '-'를 제외하고 입력",
        (v) => v.length < 9 || "8자로 입력해주세요.",
      ],
      vendorRules: [
        (v) => !!v || "'-'를 제외하고 8자로 입력",
        (v) => /^[0-9]+$/.test(v) || "'-'를 제외하고 입력",
      ],
    };
  },
  watch: {
    pickerDate(newval, oldval) {
      this.next_prev_month(newval);
    },
  },
  computed: {
    formed_time() {
      return `${this.form.date} ${this.form.timeinfo.text}`;
    },
    is_form_ready() {
      if (this.form.ty_ipju == "O") {
        if (this.form.ty_contractor == "P") {
          return (
            this.form.master &&
            this.form.date &&
            this.form.timeinfo.id &&
            this.form.ds_contractor &&
            this.form.dt_birth &&
            this.form.nm_l &&
            this.form.no_tel_l
          );
        } else if (this.form.ty_contractor == "C") {
          return (
            this.form.master &&
            this.form.date &&
            this.form.timeinfo.id &&
            this.form.ds_vendor &&
            this.form.cd_vendor &&
            this.form.nm_l &&
            this.form.no_tel_l
          );
        }
      } else {
        return (
          this.form.master &&
          this.form.date &&
          this.form.timeinfo.id &&
          this.form.nm_l &&
          this.form.no_tel_l
        );
      }
      return false;
    },
    user() {
      return this.$store.getters.getUser;
    },
    home() {
      return this.$store.getters.getHome;
    },
    village_name() {
      return this.$route.params.village_name || this.home.village_name;
    },
  },

  methods: {
    // APIs
    init() {
      // 자동완성 및 사용자의 단지정볼르 가져오기 위한 API
      if (this.reservationID) {
        this.getReservationAPI(this.reservationID);
        // this.getReservationListAPI(this.user.id);
      } else {
        this.form.user = this.user.id;
        this.form.name = this.user.name;
        this.form.contact = this.user.mobile;
        this.village = this.$route.params.selectVillageId || this.home.village;
      }
      //this.getMasterListAPI(this.user.id);
      this.getReservationDetailAPI();
    },
    getReservationAPI(id) {
      getReservation(id)
        .then((re) => {
          //console.log(re);
          this.is_modify = true;
          this.form.contact = re.data.contact;
          this.form.name = re.data.name;
          this.form.master = re.data.master;
          this.form.timeinfo = re.data.timeinfo;
          this.form.date = re.data.date;
          this.form.time = re.data.time;
          this.sel_date = re.data.date;
          this.form.id = re.data.id;
          this.form.home = re.data.home;
          this.form.user = re.data.user;
          this.form.dong_no = re.data.dong_no;
          this.form.ho_no = re.data.ho_no;
          this.village = re.data.village;
          this.getReservationDetailAPI();
        })
        .catch((er) => {
          console.log(er);
        });
    },
    delReservationAPI() {
      /*
                delete
                TODO: 예약 자체를 삭제가 아닌, 삭제 필드를 만들어 patch 해야할듯.
                      현재는 테스트용으로 레코드 삭제.
            */
      delReservation(this.form.id);
    },
    getMasterListAPI(user_id) {
      // 해당 단지에서 진행중인 사전점검 예약의 최신본을 가져온다.

      this.allowed_dates = [];

      let query = `user_id=${user_id}&&code=${
        this.reservation_code
      }&&date=${get_now_string()}&&home=${this.home.id}`;

      getReservationMasterList(query).then((re) => {
        //console.log(re);
        if (re.data.length) {
          this.reservationMaster = re.data[0];
          this.form.master = re.data[0].id;
          this.getReservationDetailAPI();
          this.hometext = this.home.village_name;
        } else {
          this.$store.commit("ERROR", "예약가능한 단지 정보가 없습니다.");
          this.hometext = "예약가능한 단지 정보가 없습니다.";
        }
      });
    },
    getReservationDetailAPI(custom_date) {
      // 특정 요청한 날짜의 예약마스터의 상세정보(예약가능 시간 등)을 불러온다.
      //if (!this.form.master) return;

      let query;
      if (custom_date) {
        query = `date=${custom_date}`;
      } else {
        if (!this.form.date) return "";
        query = `date=${this.form.date}`;
        this.pickerDate = this.form.date.slice(0, 7);
      }

      //TODO: example에 해당하는 모든 정보가 전달되어야함.
      // example: query = 'date=2022-01-27&village=30&kind_code=MOVEIN_RESERVATION&no_dong=3001&no_ho=1801'
      // let village_id = 30; // TODO: use User input
      // let no_dong = 3001; // TODO: use User input
      // let no_ho = 1801; // TODO: use User input

      if (!this.form.dong_no && !this.form.ho_no) {
        return;
      }

      let village_id = this.village; // TODO: use User input
      let no_dong = this.form.dong_no; // TODO: use User input
      let no_ho = this.form.ho_no; // TODO: use User input
      query += `&kind_code=MOVEIN_RESERVATION&village=${village_id}&no_dong=${no_dong}&no_ho=${no_ho}`;
      //console.log(query);

      return getMoveInReservationMaster(query)
        .then((re) => {
          console.log(re);
          if (re.data.error) {
            this.show_calendar = false;
            this.$store.commit("ERROR", "예약 가능한 일자가 없습니다.");
            return;
          }

          this.show_calendar = true;
          this.reservationMaster = re.data;
          this.form.master = re.data.id;
          this.allowed_dates = re.data.allowed_dates;
          if (!custom_date) {
            this.allowed_times = re.data.allowed_times;

            // 가능한 첫 시간을 지정한다.
            for (let idx in this.allowed_times) {
              if (this.form.time) {
                if (this.form.time == this.allowed_times[idx].id) {
                  // 수정 시, 선택 된 시간으로 스크롤.
                  this.form.timeinfo = this.allowed_times[idx];
                  break;
                }
              } else if (!this.allowed_times[idx].disabled) {
                this.form.timeinfo = this.allowed_times[idx];
                break;
              }
            }

            var tomorrow = get_time_string(1);
            var idx = re.data.allowed_dates.indexOf(tomorrow);

            if (idx > 0) {
              // 내일 날짜까지 배열 삭제
              this.allowed_dates = re.data.allowed_dates.slice(idx + 1);

              if (
                this.form.date == get_now_string() ||
                this.fomr.date == tomorrow
              ) {
                // 오늘 날짜 선택 불가
                this.allowed_times.forEach((x) => {
                  this.form.timeinfo = { text: "" };
                  x.disabled = true;
                });
              }
            }

            let sync_done = false;
          }
        })
        .catch((er) => {
          let errors = er.response.data.error;
          this.show_calendar = false;
          if (errors) {
            if (errors.length) {
              this.$store.commit("ERROR", errors);
            }
          }
        });
    },
    postReservationAPI() {
      if (this.is_form_ready) {
        this.form.time = this.form.timeinfo.id;
        this.form.home = this.village;

        // TODO: form 이 아래와 같아야함.

        postMoveInReservation(this.form)
          .then((re) => {
            this.$store.commit("SUCCESS", "예약 되었습니다.");
            this.$router.push({ name: "moveInReservationView" });
          })
          .catch((er) => {
            //console.log(er);
            console.log(er.response);
            let errors = er.response.data.error;
            if (errors) {
              if (errors.length) {
                this.$store.commit("ERROR", errors);
                this.getReservationDetailAPI();
              }
            }
          });
      }
    },
    putReservationAPI() {
      if (this.is_form_ready) {
        this.form.time = this.form.timeinfo.id;

        // TODO: form 이 아래와 같아야함.
        // {
        //   "master": 350,
        //   "time": 1795,
        //   "date": "2022-01-27",
        //   "dong_no": "3001",
        //   "ho_no": "1801",

        //   "ty_ipju":"O",

        //   "ty_contractor":"P",
        //   "ds_contractor":"정경구",
        //   "dt_birth":"19820614",

        //   "nm_l":"정경구",
        //   "no_tel_l":"010-6653-1954",
        //   "ds_vendor":"",
        //   "cd_vendor":""
        // }

        putMoveInReservation(this.form.id, this.form)
          .then((re) => {
            this.$store.commit("SUCCESS", "수정 되었습니다.");
            this.$router.push({ name: "moveInReservationView" });
          })
          .catch((er) => {
            let errors = er.response.data.non_field_errors;
            if (errors) {
              if (errors.length) {
                this.$store.commit("ERROR", errors[0]);
                this.getReservationDetailAPI();
              }
            }
          });
      }
    },
    // Utils
    allowedDates(val) {
      return this.allowed_dates.indexOf(val) !== -1;
    },
    sync() {
      this.getReservationDetailAPI(null);
    },
    next_prev_month(newval) {
      var search_date = `${newval}-01`;
      var today = get_now_string();

      // 같은 달의 경우, 오늘 날짜를 기준으로 조회한다.
      if (isFuture(search_date, today) && newval == today.slice(0, 7)) {
        search_date = today;
      }
      this.getReservationDetailAPI(search_date);
    },
  },
  created() {
    this.reservationID = Number(this.$route.params.id);
    this.init();
  },
};
</script>
