<template>
  <v-container class="vh-footer pd20 lynn-web-in bg-gray">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">투표하기</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops bg-gray">
      <div class="vote-incont">
        <!-- 제목 -->
        <div class="vtc-title">
          <p>{{ vote.creator_name }}</p>
          <strong>{{ vote.title }}</strong>
        </div>

        <!-- 일반 텍스트 상자 -->
        <ul class="vtc-box">
          <li class="vtc-ntext">
            {{ vote.detail }}
          </li>
          <li class="vtc-date">
            <div class="left">
              <strong>투표 시작일</strong>
              <p>{{ vote.from_datetime }}</p>
            </div>
            <div class="right">
              <strong>투표 종료일</strong>
              <p>{{ vote.to_datetime }}</p>
            </div>
          </li>
          <li class="vtc-ntext">투표번호 : {{ vote.vote_no }}</li>
        </ul>
        <!-- 투표 결과 폼 -->
        <strong class="vote-formtitle">투표 결과</strong>
        <ul class="vtc-box">
          <li v-for="item in vote.result_items" :key="item.id">
            <div :class="['vote-complete', { light: item.rank == 1 }]">
              <div class="title">
                <strong
                  >{{ item.rank }}위
                  <span v-if="union(item.rank)">(공동)</span>
                </strong>
                <p>{{ item.percent }}%({{ item.count }}표)</p>
              </div>
              <div class="progress">
                <span
                  class="in-progress"
                  :style="`width: ${item.percent}%`"
                ></span>
              </div>
              <p>{{ item.item_name }}</p>
            </div>
          </li>
        </ul>

        <!-- 안내메세지 -->
        <div class="vtc-etc">
          <strong>※ 투표 안내</strong>
          <p>
            투표는 아파트 입주자대표회의의 구성원들에 의해 생성되며 관련한
            문의는 대표자 또는 투표 생성자에게 문의해 주세요~
          </p>
        </div>

        <a href="#none" class="vtc-btn" @click="$router.go(-1)">확인</a>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import Vote from "@/assets/images/btn/btn-vote.svg";
import VoteDone from "@/assets/images/btn/btn-vote-round.svg";
import VoteBig from "@/assets/images/btn/btn-vote-big.svg";
import { getVote } from "@/api/index";

export default {
  name: "Faq",
  components: {
    Back,
    Weblogo,
    //Vote,
    //VoteDone,
    //VoteBig,
  },
  data: () => ({
    votepopup: false,
    vote: {},
  }),
  computed: {},
  methods: {
    getVoteTotalResultAPI(id) {
      getVote(id, "result=Y").then((res) => {
        this.vote = res.data;
      });
    },
    union(rank) {
      // 공동 n위인지 여부
      var rankcount = this.vote.items.filter((item) => {
        return item.rank == rank;
      }).length;

      if (rankcount > 1) return true;
      else return false;
    },
  },
  created() {
    this.getVoteTotalResultAPI(this.$route.params.id);
  },
};
</script>
