<template>
  <v-container class="vh-footer pd20 lynn-web-in bg-gray">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">투표하기</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops bg-gray">
      <div class="vote-incont">
        <!-- 제목 -->
        <div class="vtc-title">
          <p>{{ vote.creator_name }}</p>
          <strong>{{ vote.title }}</strong>
        </div>

        <!-- 일반 텍스트 상자 -->
        <ul class="vtc-box">
          <li class="vtc-ntext">
            {{ vote.detail }}
          </li>
          <li class="vtc-date">
            <div class="left">
              <strong>투표 시작일</strong>
              <p>{{ vote.from_datetime }}</p>
            </div>
            <div class="right">
              <strong>투표 종료일</strong>
              <p>{{ vote.to_datetime }}</p>
            </div>
          </li>
          <li class="vtc-ntext">투표번호 : {{ vote.vote_no }}</li>
        </ul>

        <!-- 투표 폼 -->
        <ul class="vtc-box">
          <li class="vtc-ftitle">
            <strong>{{
              vote.sub_title == null ? "투표해주세요." : vote.sub_title
            }}</strong>
            <p v-if="vote.choice_count > 1">중복투표 가능</p>
          </li>
          <li v-for="item in vote.items" :key="item.id">
            <div class="vote-checkbox">
              <input
                type="checkbox"
                name="vote"
                :id="item.id"
                :value="item"
                v-model="voteResult"
                @change="checkVoteCount()"
              />
              <label :for="item.id" class="icon-box">
                <Vote class="check" />
                <VoteDone class="done" />
              </label>
              <label :for="item.id" class="text-box">{{
                item.item_name
              }}</label>
            </div>
          </li>
        </ul>

        <!-- 안내메세지 -->
        <div class="vtc-etc">
          <strong>※ 투표 안내</strong>
          <p>
            투표는 아파트 입주자대표회의의 구성원들에 의해 생성되며 관련한
            문의는 대표자 또는 투표 생성자에게 문의해주세요.
          </p>
        </div>

        <!-- 유저 정보 -->
        <ul class="vtc-user">
          <li>{{ vote.userinfo.village_name }}</li>
          <li>{{ vote.userinfo.dong_no }}동 {{ vote.userinfo.ho_no }}호</li>
          <li>투표자 {{ user.name }}</li>
        </ul>
        <a
          class="vtc-btn"
          v-if="this.home.is_host && vote.is_voter"
          @click="voteDialogOpen()"
          >투표하기</a
        >
        <a class="vtc-btn disabled" v-else>투표는 세대관리자만 가능합니다.</a>
      </div>
    </v-card>

    <!-- 투표 재확인 다이얼로그 -->
    <v-dialog v-model="votepopup" max-width="320">
      <v-card>
        <!--확인팝업-->
        <div class="vote-popup">
          <p class="vp-text">
            투표는 한 번 제출하면 수정하실 수 없습니다. 아래 내용으로
            투표하시겠습니까?
          </p>
          <p class="vp-name">선택사항 : {{ voteText }}</p>
          <div class="vp-btnbox">
            <a @click="votepopup = false">아니오</a>
            <a
              @click="
                votepopup = false;
                postVoteResultAPI();
              "
              >예</a
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="votepopup2" max-width="320">
      <v-card>
        <!--투표 완료팝업-->
        <div class="vote-popup pt30">
          <p class="vp-icon"><VoteBig /></p>
          <p class="vp-vtitie">투표완료</p>
          <p class="vp-text center">소중한 한 표 고맙습니다!</p>
          <div class="vp-btnbox">
            <a
              href="#none"
              @click="
                votepopup2 = false;
                goList();
              "
              >확인</a
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="warnpopup" max-width="320">
      <v-card>
        <div class="vote-popup">
          <p class="vp-text">{{ warnpopupmsg }}</p>
          <div class="vp-btnbox">
            <a href="#none" @click="warnpopup = false">확인</a>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import Vote from "@/assets/images/btn/btn-vote.svg";
import VoteDone from "@/assets/images/btn/btn-vote-round.svg";
import VoteBig from "@/assets/images/btn/btn-vote-big.svg";
import { getVote, postVoteResult } from "@/api/index";

export default {
  name: "VoteForm",
  components: {
    Back,
    Weblogo,
    Vote,
    VoteDone,
    VoteBig,
  },
  data: () => ({
    warnpopup: false,
    warnpopupmsg: "투표는 세대주만 가능합니다",

    votepopup: false,
    votepopup2: false,
    vote: { userinfo: {}, items: [] },
    voteResult: [],
  }),
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    home() {
      return this.$store.getters.getTenantHome;
    },
    noVote() {
      return !this.voteResult.length;
    },
    voteText() {
      var text = "";
      this.voteResult.forEach((item, i) => {
        if (i == 0) text = item.item_name;
        else text += "," + item.item_name;
      });

      return text;
    },
  },
  methods: {
    getVote(id) {
      getVote(id).then((res) => {
        this.vote = res.data;
        console.log(res.data);
        if (this.vote.vote_yn == "Y") {
          // 이미 투표진행 경우
          this.$router.push({
            name: "voteProcess",
            params: { id: this.vote.id },
          });
        }
      });
    },
    postVoteResultAPI() {
      let data = {
        user: this.user.id,
        items: this.voteResult,
      };
      postVoteResult(data).then((res) => {
        this.votepopup2 = true;
      });
    },
    checkVoteCount() {
      // 개수초과한 경우 첫번째 선택한 것부터 지움!
      if (this.voteResult.length > this.vote.choice_count) {
        this.voteResult.shift();
      }
    },
    voteDialogOpen() {
      if (!this.check(this.vote)) {
        return;
      } else if (this.noVote) {
        this.$store.commit("ERROR", "투표 항목을 선택하세요.");
      } else {
        this.votepopup = true;
      }
    },
    goList() {
      this.$router.push({ name: "voteList" });
    },
    check(vote) {
      let leftMinutes = parseInt(
        (new Date(vote.to_datetime).getTime() - new Date().getTime()) /
          (1000 * 60)
      );
      let beforeMinutes = parseInt(
        (new Date(vote.from_datetime).getTime() - new Date().getTime()) /
          (1000 * 60)
      );

      if (leftMinutes < 0) {
        // 기간 만료 시
        this.warnpopup = true;
        this.warnpopupmsg = `투표 종료시간 ${vote.to_datetime} 이후 투표 불가능합니다.`;
      } else if (beforeMinutes > 0) {
        // 투표 가능시간 이전
        this.warnpopup = true;
        this.warnpopupmsg = `투표 시작시간 ${vote.from_datetime} 이후 투표가능합니다.`;
      } else if (!this.home.is_host) {
        // 세대 관리자가 아닌경우 불가
        this.warnpopup = true;
        this.warnpopupmsg = "투표는 세대주만 가능합니다";
      } else {
        return true;
      }
      return false;
    },
  },
  created() {
    this.getVote(this.$route.params.id);
  },
};
</script>
