<template>
  <v-container class="bg-gray vh-full pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">스마트린 회원이신가요?</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text>
        <v-form>
          <v-text-field
            v-model.trim="email"
            label="아이디(이메일)"
            required
          ></v-text-field>
          <v-text-field
            @keyup.enter="signIn()"
            v-model.trim="password"
            label="비밀번호"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            required
            ref="password"
          ></v-text-field>
          <v-card-text class="error--text lh18" v-if="errMsg != ''">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              class="mr3 v_t"
            >
              <g
                id="그룹_73"
                data-name="그룹 73"
                transform="translate(-301 -229)"
              >
                <circle
                  id="타원_23"
                  data-name="타원 23"
                  cx="1"
                  cy="1"
                  r="1"
                  transform="translate(309 241)"
                  fill="red"
                />
                <rect
                  id="사각형_136"
                  data-name="사각형 136"
                  width="2"
                  height="7"
                  rx="1"
                  transform="translate(309 233)"
                  fill="red"
                />
                <g
                  id="타원_24"
                  data-name="타원 24"
                  transform="translate(301 229)"
                  fill="none"
                  stroke="red"
                  stroke-width="2"
                >
                  <circle cx="9" cy="9" r="9" stroke="none" />
                  <circle cx="9" cy="9" r="8" fill="none" />
                </g>
              </g>
            </svg>
            {{ errMsg }}
          </v-card-text>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          class="fts18 ftw7 mw-400 pwidth100"
          min-height="50"
          @click="signIn()"
          >로그인</v-btn
        >
      </v-card-actions>

      <v-card-subtitle class="fts14 ftw5">
        <v-btn class="fts16 ftw5 mr5" text @click="openFindEmail()"
          >아이디 찾기</v-btn
        >
        |
        <v-btn class="fts16 ftw5 mr5" text :to="{ path: 'forgetPassword' }"
          >비밀번호 재설정</v-btn
        >
        |
        <v-btn class="fts16 ftw5 ml5" text :to="{ path: 'signupAgreement' }"
          >신규 회원등록</v-btn
        ></v-card-subtitle
      >
    </v-card>

    <div class="text-center" v-if="loadingBar">
      <v-progress-circular
        fullscreen
        indeterminate
        color="red"
        style="position: fixed; top: calc(50% - 16px); left: calc(50% - 16px)"
      ></v-progress-circular>
    </div>
    <FindEmailResultDialog
      :data="findEmail"
      @close="findEmail.dialog = false"
      @login="loginAs"
    />
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import FindEmailResultDialog from "@/components/User/FindEmailResultDialog";
import { loadNICEMainWithNoToken } from "@/api/NICE_id.js";

export default {
  components: { Back, Weblogo, FindEmailResultDialog },
  name: "LoginPage",
  data() {
    return {
      showPassword: false,
      valid: false,
      password: "",
      email: "",
      errMsg: "",
      loadingBar: false,
      fcm_token: "",

      findEmail: {
        dialog: false,
        items: [
          { email: "123451234512345@wikey.com" },
          { email: "123451234512345@wikey.com" },
          { email: "123451234512345@wikey.com" },
          { email: "123451234512345@wikey.com" },
          { email: "123451234512345@wikey.com" },
        ],
        msg: "'01012345678'로 등록된 아이디가 없습니다.",
      },
    };
  },
  computed: {},
  methods: {
    getFcmToken(token) {
      return new Promise((resolve, reject) => {
        window.flutter_inappwebview
          .callHandler("getAppInfo")
          .then(function(result) {
            var json = JSON.parse(result);
            console.log("####fcm_token : " + json.fcm_token);
            token = json.fcm_token;

            resolve(token);
          });
      });
    },
    signIn() {
      if (this.email == "") {
        this.errMsg = "아이디를 입력하세요.";
      } else if (!/.+@.+/.test(this.email)) {
        this.errMsg = "아이디는 이메일 형식이어야합니다.";
      } else if (this.password == "") {
        this.errMsg = "비밀번호를 입력하세요.";
      } else {
        this.loadingBar = true;

        console.log("############# fcm_token signIn() : " + this.fcm_token);
        this.$store
          .dispatch("authenticateUser", {
            email: this.email,
            password: this.password,
            fcm_token: this.fcm_token,
          })
          .then((result) => {
            this.loadingBar = false;
            if (
              this.$route.query.redirect != undefined &&
              this.$route.query.redirect != "/mypage"
            ) {
              this.$router.push({
                path: this.$route.query.redirect,
              });
            } else {
              this.$router.push({ name: "intro" });
            }
          })
          .catch((err) => {
            this.loadingBar = false;

            // Logging err
            this.$store.dispatch("LOGGING", {
              error: err,
              tags: `LoginPage|cognitoAPI|authenticateUser(email=${this.email})`,
              etc: {}, // Put anythings you want to log as object
            });

            if (
              err.code == "UserNotFoundException" ||
              err.code == "InvalidPasswordException"
            ) {
              this.errMsg = "아이디 혹은 비밀번호가 올바르지 않습니다.";
            } else if (err.code == "UserNotConfirmedException") {
              this.$store.commit(
                "ERROR",
                "인증되지 않은 회원입니다. 인증 번호를 확인해주세요."
              );
              this.$router.push({
                name: "confirm",
                params: { email: this.email, userData: null },
              });
            } else if (err.code == "NotAuthorizedException") {
              this.errMsg = "이메일 또는 비밀번호가 잘못되었습니다.";
            } else {
              this.errMsg = "로그인할 수 없습니다. 관리자에게 문의해주세요.";
            }
          });
      }
    },
    openFindEmailResult(msg, data) {
      this.findEmail.msg = msg;
      this.findEmail.items = data || [];
      this.findEmail.dialog = true;
    },
    loginAs(email) {
      this.email = email;
      this.findEmail.dialog = false;
    },
    openFindEmail() {
      this.loadingBar = true;
      let form = {
        redirect: "login",
        type: "find_user_email",
      };
      loadNICEMainWithNoToken(form)
        .then((re) => {
          this.loadingBar = false;
          this.nice_form = re.data;
          this.OpenPage();
          this.close();
        })
        .catch((er) => {
          this.loadingBar = false;
          this.$store.commit("ERROR", "본인인증 호출에 실패하였습니다.");
        });
    },
    OpenPage() {
      // window.open(
      //   "",
      //   "_self",
      //   "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
      // );

      let form = document.createElement("form");

      var EncodeData = document.createElement("input");
      EncodeData.type = "hidden";
      EncodeData.name = "EncodeData";
      EncodeData.value = this.nice_form.enc_data;

      var m = document.createElement("input");
      m.type = "hidden";
      m.name = "m";
      m.value = "checkplusSerivce";

      form.action =
        "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
      form.target = "_self";
      form.appendChild(EncodeData);
      form.appendChild(m);

      document.body.appendChild(form);
      form.submit();
    },
    urlCheck() {
      if (this.$route.query.type) {
        let type = this.$route.query.type;
        let msg = this.$route.query.msg;
        let data = this.$route.query.data;
        if (data) {
          data = this.$route.query.data.split(",");
        }

        this.$router.push(this.$route.path);
        this.openFindEmailResult(msg, data);
      }
    },
  },
  created() {
    this.urlCheck();

    this.$store.dispatch("signOut", {});
    this.$store.commit("INFO_UPDATE", {
      type: "onetimeLoad",
      text: true,
    });

    // 앱으로 온 경우 fcm_token 저장
    let browserInfo = navigator.userAgent.toLowerCase();
    if (browserInfo.indexOf("wikey_flutter") > 0) {
      this.getFcmToken().then((result) => {
        this.fcm_token = result;
      });
    }
  },
};
</script>
