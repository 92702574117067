var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding-bottom":"100px"}},[(_vm.reservationMaster.description)?_c('ul',{staticClass:"resinfo-list"},[_c('li',{staticClass:"ril-text",domProps:{"innerHTML":_vm._s(_vm.reservationMaster.description)}})]):_vm._e(),_c('v-form',[_c('v-text-field',{attrs:{"type":"text","value":_vm.hometext,"label":"방문 단지","readonly":""}}),_c('v-subheader',{staticClass:"pd0"},[_vm._v("방문일자")]),_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedDates,"picker-date":_vm.pickerDate,"day-format":function (date) { return new Date(date).getDate(); },"locale":"KR","full-width":"","no-title":""},on:{"change":_vm.sync,"update:pickerDate":function($event){_vm.pickerDate=$event},"update:picker-date":function($event){_vm.pickerDate=$event}},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}}),_c('v-subheader',{staticClass:"pd0"},[_vm._v("방문시간")]),_c('v-slide-group',{attrs:{"value":_vm.allowed_times.indexOf(_vm.form.timeinfo)}},_vm._l((_vm.allowed_times),function(timeinfo){return _c('v-slide-item',{key:timeinfo.id,staticClass:"mr10 nh-50",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{class:[
            {
              'v-active':
                timeinfo.id === _vm.form.timeinfo.id &&
                _vm.form.date === _vm.sel_date &&
                _vm.is_modify,
            },
            'pd10 color-2 bgc-3' ],attrs:{"value":timeinfo,"disabled":timeinfo.disabled,"input-value":active,"active-class":"v-active","depressed":""},on:{"click":function($event){toggle;
            _vm.form.timeinfo = timeinfo;
            _vm.sel_date = '';}}},[_vm._v(" "+_vm._s(timeinfo.text)+" "),_c('br'),_vm._v(" "+_vm._s(timeinfo.tooltip)+" ")])]}}],null,true)})}),1),_c('v-text-field',{attrs:{"label":"이름","required":"","readonly":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('v-text-field',{attrs:{"type":"number","label":"방문자 전화번호","counter":12,"required":""},model:{value:(_vm.form.contact),callback:function ($$v) {_vm.$set(_vm.form, "contact", $$v)},expression:"form.contact"}})],1),(!_vm.is_modify)?_c('v-btn',{staticClass:"fts18 ftw7 mw-400 pwidth100 mt15",attrs:{"color":"primary","min-height":"50","block":"","disabled":!_vm.is_form_ready},on:{"click":_vm.postReservationAPI}},[_vm._v(" 사전점검 예약하기 ")]):_vm._e(),(_vm.is_modify)?_c('v-btn',{staticClass:"fts18 ftw7 mw-400 pwidth100 mt15",attrs:{"color":"primary","min-height":"50","block":"","disabled":!_vm.is_form_ready},on:{"click":_vm.putReservationAPI}},[_vm._v(" 수정하기 ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }