<template>
  <v-container class="pd16">
    <strong class="t-cont-title">납입금액<span>[금액 단위:원]</span></strong>
    <div class="text-set">
      <p>
        납부일자에 따라 금액이 변동될수 있으니 납부가능한 일자에 맞춰 조회하시기
        바랍니다
      </p>
    </div>
    <div class="sel-inbtn type4">
      <div class="left">
        <label>기준일</label>
      </div>
      <div class="right">
        <!--클릭 하면 달력-->
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          max-width="290"
          class="ds-ib"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="baseDate"
              class="sel-cus"
              label="기준일"
              hide-details
              single-line
              prepend-inner-icon="mdi-magnify"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="baseDate"
            @change="
              menu = false;
              getBalance();
            "
            :day-format="(date) => new Date(date).getDate()"
            :min="today"
            locale="KR"
            full-width
            no-title
          ></v-date-picker>
        </v-menu>
      </div>
    </div>

    <!-- 아파트 -->
    <strong class="t-sub-title pl5">아파트</strong>
    <table class="t-table cont-table" v-if="baseDate != ''">
      <tr class="bottom" v-if="balanceData.balance.balance != 0">
        <td class="bg2">
          <div class="inbox">
            <div class="left">
              <span
                class="tx4"
                v-if="
                  balanceData.balance.paymentStartDate &&
                    balanceData.balance.paymentDate !==
                      balanceData.balance.paymentStartDate
                "
                >약정기간</span
              >
              <span class="tx4" v-else>약정일자</span>
            </div>
            <div class="right">
              <span
                class="tx4"
                v-if="
                  balanceData.balance.paymentStartDate &&
                    balanceData.balance.paymentDate !==
                      balanceData.balance.paymentStartDate
                "
                >{{ balanceData.balance.paymentStartDate }}~{{
                  balanceData.balance.paymentDate
                }}</span
              >
              <span class="tx4" v-else>{{
                balanceData.balance.paymentDate
              }}</span>
            </div>
          </div>
          <div class="inbox">
            <div class="left"><span class="tx4">약정금액</span></div>
            <div class="right">
              <span class="tx4">{{ balanceData.balance.balance | comma }}</span>
            </div>
          </div>
          <div class="inbox" v-if="balanceData.balance.discount != 0">
            <div class="left">
              <span class="tx4"
                >할인금액<span
                  class="small"
                  v-if="balanceData.balance.DD_DAY != 0"
                  >(적용일수 : {{ balanceData.balance.DD_DAY }})</span
                ></span
              >
            </div>
            <div class="right">
              <span class="tx4"
                >-{{ balanceData.balance.discount | comma }}</span
              >
            </div>
          </div>
          <div class="inbox" v-if="balanceData.balance.delayedFee != 0">
            <div class="left">
              <span class="tx4"
                >연체금액<span
                  class="small"
                  v-if="balanceData.balance.DD_DAY != 0"
                  >(적용일수 : {{ balanceData.balance.DD_DAY }})</span
                ></span
              >
            </div>
            <div class="right">
              <span class="tx4">{{
                balanceData.balance.delayedFee | comma
              }}</span>
            </div>
          </div>
        </td>
      </tr>
      <tr class="bottom" v-if="balanceData.balance.balance != 0">
        <td class="bg2">
          <div class="left"><span class="tx1">납부예정금액</span></div>
          <div class="right">
            <span class="tx1">{{
              balanceData.balance.paidAmount | comma
            }}</span>
          </div>
        </td>
      </tr>
      <tr v-if="balanceData.balance.balance != 0">
        <td class="bg2">
          <span class="tx1">납부계좌</span>
          <span class="tx1"
            >{{ balanceData.balanceBankInfo.name }}
            {{ balanceData.balanceBankInfo.account }}</span
          >
          <span class="tx1">{{ balanceData.balanceBankInfo.holder }}</span>
        </td>
      </tr>
    </table>

    <!-- 별도품목 -->
    <strong
      class="t-sub-title pl5"
      v-if="balanceData.option != null && balanceData.option.AM_RECEIPT != 0"
      >별도품목</strong
    >
    <table
      class="t-table cont-table"
      v-if="balanceData.option != null && balanceData.option.AM_RECEIPT != 0"
    >
      <tr class="bottom">
        <td class="bg2">
          <div class="inbox">
            <div class="left"><span class="tx4">약정금액</span></div>
            <div class="right">
              <span class="tx4">{{ balanceData.option.AM_TOTAL | comma }}</span>
            </div>
          </div>
          <div
            class="inbox"
            v-if="
              balanceData.option.AM_DISCOUNT != 0 &&
                balanceData.option.AM_DISCOUNT != null
            "
          >
            <div class="left">
              <span class="tx4">할인금액</span>
            </div>
            <div class="right">
              <span class="tx4"
                >-{{ balanceData.option.AM_DISCOUNT | comma }}</span
              >
            </div>
          </div>
          <div
            class="inbox"
            v-if="
              balanceData.option.AM_DELAY != 0 &&
                balanceData.option.AM_DELAY != null
            "
          >
            <div class="left">
              <span class="tx4">연체금액</span>
            </div>
            <div class="right">
              <span class="tx4">{{ balanceData.option.AM_DELAY | comma }}</span>
            </div>
          </div>
        </td>
      </tr>
      <tr class="bottom">
        <td class="bg2">
          <div class="left"><span class="tx1">납부예정금액</span></div>
          <div class="right">
            <span class="tx1">{{ balanceData.option.AM_RECEIPT | comma }}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td class="bg2">
          <span class="tx1">납부계좌</span>
          <span class="tx1"
            >{{ balanceData.optionsBankifno.name }}
            {{ balanceData.optionsBankifno.account }}</span
          >
          <span class="tx1">{{ balanceData.optionsBankifno.holder }}</span>
        </td>
      </tr>
    </table>

    <!-- 기타금액 (후불+보증수수료 복수) -->
    <div
      v-if="
        balanceData.sellReceipt != null &&
          balanceData.sellReceipt.AM_LOANINTEREST_LEFT != 0 &&
          balanceData.sellReceipt != null &&
          balanceData.sellReceipt.AM_ASSULEFT != 0 &&
          balanceData.gfBankInfo.account == balanceData.interestBankInfo.account
      "
    >
      <strong class="t-sub-title pl5">기타금액</strong>
      <table class="t-table cont-table">
        <tr class="bottom">
          <td class="bg2">
            <div class="inbox">
              <div class="left"><span class="tx4">후불이자</span></div>
              <div class="right">
                <span class="tx4">{{
                  balanceData.sellReceipt.AM_LOANINTEREST_LEFT | comma
                }}</span>
              </div>
            </div>
            <div class="inbox">
              <div class="left"><span class="tx4">보증수수료</span></div>
              <div class="right">
                <span class="tx4">{{
                  balanceData.sellReceipt.AM_ASSULEFT | comma
                }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr class="bottom">
          <td class="bg2">
            <div class="left"><span class="tx1">납부예정금액</span></div>
            <div class="right">
              <span class="tx1">{{
                (balanceData.sellReceipt.AM_LOANINTEREST_LEFT +
                  balanceData.sellReceipt.AM_ASSULEFT)
                  | comma
              }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td class="bg2">
            <span class="tx1">납부계좌</span>
            <span class="tx1"
              >{{ balanceData.gfBankInfo.name }}
              {{ balanceData.gfBankInfo.account }}</span
            >
            <span class="tx1">{{ balanceData.gfBankInfo.holder }}</span>
          </td>
        </tr>
      </table>
    </div>

    <div v-else>
      <!-- 후불이자 (단일) -->
      <strong
        class="t-sub-title pl5"
        v-if="
          balanceData.sellReceipt != null &&
            balanceData.sellReceipt.AM_LOANINTEREST_LEFT != 0
        "
        >후불이자</strong
      >
      <table
        class="t-table cont-table"
        v-if="
          balanceData.sellReceipt != null &&
            balanceData.sellReceipt.AM_LOANINTEREST_LEFT != 0
        "
      >
        <tr class="bottom">
          <td class="bg2">
            <div class="left"><span class="tx1">납부예정금액</span></div>
            <div class="right">
              <span class="tx1">{{
                balanceData.sellReceipt.AM_LOANINTEREST_LEFT | comma
              }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td class="bg2">
            <span class="tx1">납부계좌</span>
            <span class="tx1"
              >{{ balanceData.gfBankInfo.name }}
              {{ balanceData.gfBankInfo.account }}</span
            >
            <span class="tx1">{{ balanceData.gfBankInfo.holder }}</span>
          </td>
        </tr>
      </table>

      <!-- 보증수수료 (단일) -->
      <strong
        class="t-sub-title pl5"
        v-if="
          balanceData.sellReceipt != null &&
            balanceData.sellReceipt.AM_ASSULEFT != 0
        "
        >보증수수료</strong
      >
      <table
        class="t-table cont-table"
        v-if="
          balanceData.sellReceipt != null &&
            balanceData.sellReceipt.AM_ASSULEFT != 0
        "
      >
        <tr class="bottom">
          <td class="bg2">
            <div class="left"><span class="tx1">납부예정금액</span></div>
            <div class="right">
              <span class="tx1">{{
                balanceData.sellReceipt.AM_ASSULEFT | comma
              }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td class="bg2">
            <span class="tx1">납부계좌</span>
            <span class="tx1"
              >{{ balanceData.interestBankInfo.name }}
              {{ balanceData.interestBankInfo.account }}</span
            >
            <span class="tx1">{{ balanceData.interestBankInfo.holder }}</span>
          </td>
        </tr>
      </table>
    </div>

    <div class="text-set mt0 mb15">
      <p>
        {{ home.payment_information }}
      </p>
    </div>
    <table class="t-table cont-table">
      <tr class="bottom">
        <td class="bg1">
          <div class="left"><span class="tx2">합계</span></div>
          <div class="right">
            <span class="tx2">{{ amount | comma }}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td class="bg3">
          <div class="text-right"><span class="tx2">입금증 지참</span></div>
        </td>
      </tr>
    </table>

    <div class="text-set mt0 mb30">
      <p>
        ※ 선납세대의 경우 할인, 미납세대의 경우 연체료가 반영된 금액입니다.
        상세사항은 수납내역 화면을 참고바라며, 고객별 가상계좌 이오니 해당호수
        이외에 공유가 불가한점 안내드립니다.
      </p>
    </div>

    <!-- 대출 내역 -->
    <strong
      class="t-cont-title"
      v-if="balanceData.loans != null && loansAmount != 0"
      >대출(실행)내역<span>[금액 단위:원]</span></strong
    >
    <table
      class="t-table cont-table"
      v-if="balanceData.loans != null && loansAmount != 0"
    >
      <tr class="bottom">
        <td class="bg2">
          <div class="inbox">
            <div class="left">
              <span class="tx1">은행</span> <span class="tx1"></span>
            </div>
            <div class="right">
              <span class="tx1">금액/</span>
              <span class="tx1" v-if="loanBankTelNum">상환문의 연락처</span>
            </div>
          </div>
        </td>
      </tr>
      <tbody v-for="(loan, i) in balanceData.loans" :key="i">
        <tr v-if="loan.balance != 0">
          <td class="bg2">
            <div class="left">
              <span class="tx3">{{ loan.bankName }} </span>
              <span class="tx5"></span>
            </div>
            <div class="right">
              <span class="tx3">{{ loan.balance | comma }}</span>
              <span class="tx5">{{ loan.bankTelNum | hyphen }}</span>
            </div>
          </td>
        </tr>
      </tbody>
      <tr class="bottom">
        <td class="bg1">
          <div class="left"><span class="tx2">합계</span></div>
          <div class="right">
            <span class="tx2">{{ loansAmount | comma }}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td class="bg3">
          <div class="text-right">
            <span class="tx2">대출상환 영수증 지참</span>
          </div>
        </td>
      </tr>
    </table>

    <div
      class="text-set mt0 mb30"
      v-if="balanceData.loans != null && loansAmount != 0"
    >
      <p>
        ※ 상기금액은 최초 신청한 금액으로 개별상환은 미반영 되어 있으니
        상환내역은 해당은행으로 문의바랍니다
      </p>
    </div>

    <strong
      class="t-cont-title"
      v-if="
        balanceData.prepaidMgmtFee != null &&
          balanceData.prepaidMgmtFee.prepaidMgmtFee != 0
      "
      >선수관리비<span>[금액 단위:원]</span></strong
    >
    <table
      class="t-table cont-table"
      v-if="
        balanceData.prepaidMgmtFee != null &&
          balanceData.prepaidMgmtFee.prepaidMgmtFee != 0
      "
    >
      <colgroup>
        <col width="33%" />
        <col width="34%" />
        <col width="33%" />
      </colgroup>
      <tr class="bottom">
        <td class="bg2 text-left"><span class="tx1">구분</span></td>
        <td class="bg2 text-center"><span class="tx1">타입</span></td>
        <td class="bg2 text-right"><span class="tx1">금액</span></td>
      </tr>
      <tr class="bottom">
        <td class="bg2 text-left"><span class="tx3">선수관리비</span></td>
        <td class="bg2 text-center">
          <span class="tx3">{{ balanceData.areaType }}</span>
        </td>
        <td class="bg2 text-right">
          <span class="tx3">{{
            balanceData.prepaidMgmtFee.prepaidMgmtFee | comma
          }}</span>
        </td>
      </tr>
      <tr>
        <td class="bg2" colspan="3">
          <span class="tx1">납부계좌</span>
          <span class="tx1"
            >{{ balanceData.prepaidMgmtFee.prepaidMgmtFeeBankName }}
            {{ balanceData.prepaidMgmtFee.prepaidMgmtFeeBankAccount }}</span
          >
          <span class="tx1">{{
            balanceData.prepaidMgmtFee.prepaidMgmtFeeBankAccountHolder
          }}</span>
        </td>
      </tr>
    </table>

    <div
      class="text-set mt0 mb30"
      v-if="
        balanceData.prepaidMgmtFee != null &&
          balanceData.prepaidMgmtFee.prepaidMgmtFee != 0
      "
    >
      <p>
        ※ 선수관리비 입금후 관리사무소 내방하셔서 영수증 수령하시기 바랍니다.
      </p>
      <p>
        ※ 상기 각 항목에 대한 납부확인이 완료된 후 입주증 발급 및 키불출이
        진행됩니다.
      </p>
    </div>

    <strong class="t-cont-title">입주증 발급순서</strong>
    <ul class="darrow-list">
      <li class="text">입금</li>
      <li class="arrow"><Darrow /></li>
      <li class="text">
        <span class="ds-b">대출상환 및 상환영수증 발행</span>
        <span class="ds-b color-9">(대출세대에 한함)</span>
      </li>
      <li class="arrow"><Darrow /></li>
      <li class="text">관리사무소 선수관리비 영수증 수령</li>
      <li class="arrow"><Darrow /></li>
      <li class="text">입주지원센터 입주증 발급</li>
      <li class="arrow"><Darrow /></li>
      <li class="text">키불출</li>
    </ul>

    <div class="text-center" v-if="loadingBar">
      <v-progress-circular
        fullscreen
        indeterminate
        color="red"
        style="position: fixed; top: calc(50% - 16px); left: calc(50% - 16px)"
      ></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import { getChargesSellBalance } from "@/api/contract.js";
import { get_now_string } from "@/utils/time.js";
import Darrow from "@/assets/images/icon/icon-darrow.svg";
export default {
  name: "BalancePayment",
  components: {
    Darrow,
  },
  data() {
    return {
      baseDate: "",
      menu: false,
      today: get_now_string(),
      balanceData: {
        balance: {},
        option: {},
        sellReceipt: {},
        loans: {},
        prepaidMgmtFee: {},
        balanceBankInfo: {},
        optionsBankifno: {},
        gfBankInfo: {},
        interestBankInfo: {},
      },
      amount: 0,
      loansAmount: 0,
      loadingBar: false,
      loanBankTelNum: true,
    };
  },
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
  },
  methods: {
    getBalance() {
      this.loadingBar = true;

      let query = "";
      if (this.baseDate != "") {
        query = `&&want_date=${this.baseDate}`;
      }
      getChargesSellBalance(this.home.id, query).then((result) => {
        this.loadingBar = false;
        if (this.baseDate == "") this.baseDate = result.data.baseDate;
        this.balanceData = result.data;

        this.amount = 0;
        if (this.balanceData.balance.paidAmount != 0) {
          this.amount += Number(this.balanceData.balance.paidAmount);
        }
        if (this.balanceData.option != null) {
          if (this.balanceData.option.AM_RECEIPT != 0)
            this.amount += Number(this.balanceData.option.AM_RECEIPT);
        }
        if (this.balanceData.sellReceipt.AM_LOANINTEREST_LEFT != 0) {
          this.amount += Number(
            this.balanceData.sellReceipt.AM_LOANINTEREST_LEFT
          );
        }
        if (this.balanceData.sellReceipt.AM_ASSULEFT != 0) {
          this.amount += Number(this.balanceData.sellReceipt.AM_ASSULEFT);
        }

        this.loansAmount = 0;
        this.loanBankTelNum = false;
        if (this.balanceData.loans != null) {
          this.balanceData.loans.forEach((element) => {
            this.loansAmount += Number(element.balance);
            if (element.bankTelNum != "" && element.bankTelNum != null)
              this.loanBankTelNum = true;
          });
        }
      });
    },
    getLoansAmount() {},
  },
  created() {
    this.baseDate = this.today;
    this.getBalance();
  },
};
</script>
