<template>
  <v-container class="vh-footer bg-gray ps-re lynn-mobile lynn-web" fluid>
    <v-container class="pad nb mt15 mt-p3 web-contract">
      <div class="web-side"></div>
      <div class="mypi-contbox">
        <!-- 계약 단지가 하나일땐 그냥 단지명 보여줌 -->
        <strong
          class="t-cont-title text-center"
          v-if="contractList.length === 1"
          >{{ contractList[0].text }}</strong
        >

        <!-- 단지가 2개 이상일 땐 셀렉트 박스 -->
        <strong class="t-cont-title" v-if="contractList.length > 1"
          >계약정보</strong
        >
        <div class="cus-sel">
          <v-select
            v-if="contractList.length > 1"
            v-model="selHome"
            class="sel-cus"
            :items="contractList"
            item-text="text"
            return-object
            menu-props="auto"
            hide-details
            single-line
          ></v-select>
        </div>
      </div>
      <!-- 분양 -->
      <ul class="list-box">
        <li v-if="!home.is_rent">
          <a @click="moveto('/contractInfo/status/sell')" class="l-btn"
            >계약내용 조회</a
          >
          <a @click="moveto('/contractInfo/status/sell')" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><Iconfirst /></span>
            </span>
          </a>
        </li>
        <li v-if="!home.is_rent">
          <a class="l-btn" @click="moveto('/contractInfo/payment/payment')"
            >납부현황 조회</a
          >
          <a class="r-btn" @click="moveto('/contractInfo/payment/payment')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><Iconsecond /></span>
            </span>
          </a>
        </li>
        <li v-if="!home.is_rent">
          <a class="l-btn" @click="moveto('/contractInfo/loan/loan')"
            >융자현황 조회</a
          >
          <a class="r-btn" @click="moveto('/contractInfo/loan/loan')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><Iconthird /></span>
            </span>
          </a>
        </li>
        <li v-if="!home.is_rent">
          <a class="l-btn" @click="moveto('/contractInfo/interest/interest')"
            >대납이자·보증수수료</a
          >
          <a class="r-btn" @click="moveto('/contractInfo/interest/interest')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><Iconforth /></span>
            </span>
          </a>
        </li>
        <li
          :class="{ active: this.home.is_display_balance_info }"
          v-if="!home.is_rent"
        >
          <!--disabled 넣으면 비활성화 느낌으로 변경-->
          <a class="l-btn" @click="moveto('/contractInfo/balance/balance')"
            >잔금납부 안내</a
          >
          <a class="r-btn" @click="moveto('/contractInfo/balance/balance')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><IconBalance /></span>
            </span>
          </a>
        </li>
        <li v-if="home.is_rent">
          <a @click="moveto('/contractInfo/status/rent')" class="l-btn"
            >계약내용 조회</a
          >
          <a @click="moveto('/contractInfo/status/rent')" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><Iconfirst /></span>
            </span>
          </a>
        </li>
        <li v-if="home.is_rent">
          <a class="l-btn" @click="moveto('/contractInfo/receipt/deposit')"
            >보증금·임대료 조회</a
          >
          <a class="r-btn" @click="moveto('/contractInfo/receipt/deposit')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><Iconsecond /></span>
            </span>
          </a>
        </li>
        <li v-if="home.is_rent && home.contract_reservation.CHANGE">
          <a class="l-btn" @click="moveto('/contractInfo/request/change')"
            >계약 변경 방문 예약</a
          >
          <a class="r-btn" @click="moveto('/contractInfo/request/change')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><Iconsixth /></span>
            </span>
          </a>
        </li>
        <li v-if="home.is_rent && home.contract_reservation.RENEW">
          <a class="l-btn" @click="moveto('/contractInfo/renew/renew')"
            >계약 갱신 방문 예약</a
          >
          <a class="r-btn" @click="moveto('/contractInfo/renew/renew')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><Iconsixth /></span>
            </span>
          </a>
        </li>
        <li
          v-if="
            home.is_rent && home.contract_reservation.TERMINATE_BEFORE_CONTRACT
          "
        >
          <a class="l-btn" @click="moveto('/contractInfo/cancel/cancel')"
            >계약 해지 방문 예약</a
          >
          <a class="r-btn" @click="moveto('/contractInfo/cancel/cancel')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><Iconfifth /></span>
            </span>
          </a>
        </li>
        <li
          v-if="
            home.is_rent && home.contract_reservation.TERMINATE_BEFORE_PERIOD
          "
        >
          <a class="l-btn" @click="moveto('/contractInfo/before/leave')"
            >중도 퇴거 신청</a
          >
          <a @click="moveto('/contractInfo/before/leave')" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><IconMoveOut /></span>
            </span>
          </a>
        </li>
        <li
          v-if="home.is_rent && home.contract_reservation.TERMINATE_ON_PERIOD"
        >
          <a class="l-btn" @click="moveto('/contractInfo/on/leave')"
            >만기 퇴거 신청</a
          >
          <a @click="moveto('/contractInfo/on/leave')" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><IconMoveOut /></span>
            </span>
          </a>
        </li>
      </ul>

      <!-- 예약 목록 -->
      <!-- TODO : 신청 열린 예약 목록만 보여줌 -->
      <!-- 아이콘
          IconReserve : 계약자, 중도금신청, 공동명의신청
          IconRev : 사전점검예약
          IconHomein : 입주예약 -->
      <strong class="t-cont-title" v-if="reservableList.length"
        >방문 예약</strong
      >
      <ul class="list-box">
        <li
          class="single"
          v-for="item in reservableList"
          :key="`contract-reservation-${item.id}`"
        >
          <a @click="moveToReservation(item)" class="l-btn">
            <span class="text-item">{{ item.title }}</span>
            <span class="date-item"
              >신청기간 : {{ item.start_date }} ~ {{ item.end_date }}</span
            >
          </a>
          <a @click="moveToReservation(item)" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <IconRev
                  v-if="['PRE_CHECK_RESERVATION'].includes(item.kind_code)"
                />
                <IconHomein
                  v-else-if="['MOVEIN_RESERVATION'].includes(item.kind_code)"
                />
                <IconReserve v-else />
              </span>
            </span>
          </a>
        </li>
      </ul>
    </v-container>
  </v-container>
</template>

<script>
import Iconfirst from "@/assets/images/contract/1.svg"; //계약현황
import Iconsecond from "@/assets/images/contract/2.svg"; //수납현황, 보증금·임대료
import Iconthird from "@/assets/images/contract/3.svg"; //융자신청·납부
import Iconforth from "@/assets/images/contract/4.svg"; //대납이자 납부
import Iconfifth from "@/assets/images/contract/5.svg"; //계약 해지
import Iconsixth from "@/assets/images/contract/6.svg"; //계약변경·갱신
import IconBalance from "@/assets/images/contract/7.svg"; //잔금납부
import IconReserve from "@/assets/images/homein/2.svg"; //A/S 접수
import IconRev from "@/assets/images/homein/1.svg"; //사전점검 예약
import IconHomein from "@/assets/images/homein/3.svg"; //입주 예약
import IconMoveOut from "@/assets/images/homein/4.svg"; //퇴거 신청
import { get_now_string } from "@/utils/time.js";
import { getReservableList } from "@/api/index.js";

export default {
  components: {
    Iconfirst,
    Iconsecond,
    Iconthird,
    Iconforth,
    Iconfifth,
    Iconsixth,
    IconBalance,
    IconReserve,
    IconRev,
    IconHomein,
    IconMoveOut,
  },
  name: "Contract",
  data: () => ({
    selHome: "",
    reservableList: [], // 예약 가능한 예약 목록
  }),
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
    contractList() {
      return this.$store.getters.getContractList;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {
    selHome() {
      this.$store.state.auth.selected_home = this.selHome.id;
      // Update ReservableList on Switch home
      this.getReservableListAPI();
    },
  },
  methods: {
    getReservableListAPI() {
      // 당첨자,예비당첨자는 제외 is_win=N
      getReservableList(this.home.id, "is_win=N").then((res) => {
        this.reservableList = res.data;
        console.log(this.reservableList);
      });
    },
    moveto(tab) {
      /*
        'RENEW' = '갱신'
        'CHANGE' = '변경'
        'TERMINATE_BEFORE_CONTRACT' = '미입주 해지'
        'TERMINATE_BEFORE_PERIOD' = '중도 퇴거'
        'TERMINATE_ON_PERIOD' = '만기 퇴거'

        home.contract_reservation.RENEW = true or false
        home.contract_reservation.CHANGE = true or false
        ...
      */
      if (tab == "/contractInfo/balance/balance") {
        if (!this.home.is_display_balance_info) {
          this.$store.commit("ERROR", "잔금납부 안내 기간이 아닙니다.");
          return;
        }
      } else if (tab == "/contractInfo/request/change") {
        // 계약 변경 신청 가능 여부
        if (!this.home.contract_reservation.CHANGE) {
          this.$store.commit("ERROR", "계약변경 신청기간이 아닙니다.");
          return;
        }
      } else if (tab == "/contractInfo/renew/renew") {
        // 계약 갱신 신청 가능 여부
        if (!this.home.contract_reservation.RENEW) {
          this.$store.commit("ERROR", "계약갱신 신청기간이 아닙니다.");
          return;
        }
      } else if (tab == "/contractInfo/cancel/cancel") {
        // 계약 해지
        if (!this.home.contract_reservation.TERMINATE_BEFORE_CONTRACT) {
          this.$store.commit("ERROR", "계약해지 신청기간이 아닙니다.");
          return;
        }
      } else if (tab == "/contractInfo/before/leave") {
        // 중도퇴거
        if (!this.home.contract_reservation.TERMINATE_BEFORE_PERIOD) {
          this.$store.commit("ERROR", "중도퇴거 신청기간이 아닙니다.");
          return;
        }
      } else if (tab == "/contractInfo/on/leave") {
        // 만기퇴거
        if (!this.home.contract_reservation.TERMINATE_ON_PERIOD) {
          this.$store.commit("ERROR", "만기퇴거 신청기간이 아닙니다.");
          return;
        }
      }
      this.$router.push({
        path: tab,
      });
    },
    moveToReservation(item) {
      if (!item.is_reservable) {
        // 예약 불가능
        this.$store.commit("ERROR", "신청 기간이 아닙니다.");
        return;
      }

      if (item.kind_code == "PRE_CHECK_RESERVATION") {
        this.$router.push({
          name: "preCheckForm",
          params: { reservation_code: item.kind_code },
        });
      } else if (item.kind_code == "MOVEIN_RESERVATION") {
        this.$router.push({
          name: "moveInReservationView",
          params: { reservation_code: item.kind_code },
        });
      } else {
        this.$router.push({
          name: "commonReservationForm",
          params: { reservation_code: item.kind_code },
        });
      }
    },
  },
  created() {
    this.$store.dispatch("USER_REFRESH");
    this.selHome = this.home;

    if (!this.contractList.length) {
      this.$store.commit("SUCCESS", "계약자를 위한 서비스입니다.");
      this.$router.push({ name: "mypage" });
    } else {
      this.getReservableListAPI();
    }
  },
};
</script>
