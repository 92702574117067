<template>
  <v-container class="bg-gray vh-footer pd20 lynn-web-in bg-gray">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">가족구성원</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text class="pt10 pl0 pr0 pb0">
        <strong class="t-cont-title mb15">가족구성원</strong>
        <ul class="member-box">
          <li v-for="item in tenant_list" :key="'tenant' + item.id">
            <div class="member-item">
              <div class="top">
                <span class="name">{{ item.name }}</span>
                <span class="set" v-if="item.is_host">세대관리자</span>
                <span class="set" v-else>세대원</span>
              </div>
              <p class="phone">{{ item.mobile_form }}</p>
              <div class="bottom">
                <span class="text">{{ item.email }}</span>
                <span class="del" v-if="item.is_host"></span>
                <span
                  class="del"
                  v-else-if="showButton"
                  @click="
                    type = 'delete';
                    selectedTenant = item.id;
                    cancelDialog = true;
                  "
                  ><Delete /> 삭제</span
                >
              </div>
            </div>
          </li>
          <li class="no-list" v-if="!tenant_list.length">
            <p>구성된 가족이 없습니다.</p>
          </li>
        </ul>
        <strong class="t-cont-title color-1">가족구성원 신청</strong>
        <ul class="member-box">
          <li
            v-for="item in tenant_pending_list"
            :key="'tenant_pending' + item.id"
          >
            <div class="member-item">
              <div class="top">
                <span class="name">{{ item.name }}</span>
              </div>
              <p class="phone">{{ item.mobile_form }}</p>
              <div class="bottom">
                <span class="text">{{ item.email }}</span>
              </div>
            </div>
            <div class="btn-box" v-if="showButton">
              <a
                href="#n"
                @click="
                  type = 'reject';
                  selectedTenant = item.id;
                  cancelDialog = true;
                "
                >거절</a
              >
              <a href="#n" class="ok" @click="patchTenantAPI(item.id)">수락</a>
            </div>
          </li>
          <li class="no-list" v-if="!tenant_pending_list.length">
            <p>가족구성원 신청자가 없습니다.</p>
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <v-dialog v-model="cancelDialog" max-width="290">
      <v-card>
        <v-card-title class="justify-center"> </v-card-title>
        <v-card-text class="text-left ftw7" v-if="type == 'delete'">
          가족구성원 신청을 취소하시겠습니까?
        </v-card-text>
        <v-card-text class="text-left ftw7" v-else>
          해당 인원을 가족구성원에서 제외 하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="delTenantAPI()">
            예
          </v-btn>
          <v-btn color="primary darken-1" text @click="cancelDialog = false">
            아니오
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getTenantList, patchTenant, delTenant } from "@/api/index";
import Back from "@/assets/images/icon/icon-back.svg";
import Delete from "@/assets/images/mypage/icon-delete.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";

export default {
  components: {
    Back,
    Delete,
    Weblogo,
  },
  name: "FamilyMember",
  data() {
    return {
      tenant_list: [],
      tenant_pending_list: [],

      // Utils
      cancelDialog: false,
      selectedTenant: 0,
      type: "",
    };
  },
  computed: {
    tenantHome() {
      return this.$store.getters.getTenantHome;
    },
    showButton() {
      return this.tenantHome.is_host;
    },
  },
  methods: {
    getTenantListAPI() {
      if (this.tenantHome && this.tenantHome.id) {
        getTenantList(this.tenantHome.id).then((res) => {
          this.tenant_list = res.data.tenant_list;
          this.tenant_pending_list = res.data.tenant_pending_list;
        });
      }
    },
    patchTenantAPI(id) {
      patchTenant(id, { status_code: "TENANT_LIVING" }).then((res) => {
        this.getTenantListAPI();
        this.$store.commit("SUCCESS", "가족구성원 신청을 수락하였습니다.");
      });
    },
    delTenantAPI() {
      delTenant(this.selectedTenant).then((res) => {
        this.getTenantListAPI();
        this.cancelDialog = false;
        this.$store.commit("SUCCESS", "가족구성원 신청을 거절하였습니다.");
      });
    },
  },
  created() {
    this.$store.dispatch("USER_REFRESH");
    this.getTenantListAPI();
  },
};
</script>
