<template>
  <v-container class="bg-gray vh-full pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.push({ name: 'mypage' })" class="back">
        <Back />
      </a>
      <strong class="lo-title">시설예약 내역</strong>
      <!--추가 기능적인것은 대기신청 참고 WaitRequestView.vue-->
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <div class="complete-box">
        <div
          class="mt20 comin-box"
          v-for="item in items"
          :key="'reservation-history-' + item.id"
        >
          <div class="comin-cont">
            <strong class="text-left ds-b fts14 ftw7 lh24 color-5"
              >시설명({{ item.facility.name }})</strong
            >
          </div>
          <div class="comin-cont">
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
              >날짜</strong
            >
            <span class="text-left ds-b fts14 lh24 color-5">{{
              item.date_form
            }}</span>
          </div>
          <div class="comin-cont" v-if="item.reservation_seat">
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
              >좌석번호</strong
            >
            <span class="text-left ds-b fts14 lh24 color-5">{{
              item.reservation_seat.seat_name
            }}</span>
            <span
              class="text-left ds-b fts14 lh24 color-5"
              v-if="item.reservation_seat.price"
              >{{ item.reservation_seat.price | comma }}원</span
            >
          </div>

          <div
            class="comin-cont"
            v-for="option in mainOptions(item.reservation_options)"
            :key="'history-option-' + option.id"
          >
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">{{
              option.type_text
            }}</strong>
            <span class="text-left ds-b fts14 lh24 color-5">{{
              option.name
            }}</span>
            <span class="text-left ds-b fts14 lh24 color-5"
              >{{
                (option.price * getDays(item.date, item.end_date)) | comma
              }}원</span
            >
          </div>
          <div
            class="comin-cont"
            v-for="option in subOptions(item.reservation_options)"
            :key="'history-option-' + option.id"
          >
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5">{{
              option.type_text
            }}</strong>
            <span class="text-left ds-b fts14 lh24 color-5">{{
              option.name
            }}</span>
            <span class="text-left ds-b fts14 lh24 color-5"
              >{{
                (option.price * getDays(item.date, item.end_date)) | comma
              }}원</span
            >
          </div>

          <div class="comin-cont" v-if="item.number_of_users">
            <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
              >사용인원</strong
            >
            <span class="text-left ds-b fts14 lh24 color-5"
              >{{ item.number_of_users }} 명</span
            >
          </div>
          <!--  <v-card-actions class="pt-0 pb-0">
            <v-btn
              color="#3c3c3c"
              text
              :ripple="false"
              class="ftw5 fts14 no-background-hover"
              >{{ item.status_text }}</v-btn
            >
            <v-spacer></v-spacer>
            <div class="comin-cont text-right">
              <v-btn
                text
                class="ftw5 fts14 no-background-hover"
                @click="cancelDialog = true"
              >
                <v-icon>mdi-delete</v-icon> 취소하기
              </v-btn>
            </div>
          </v-card-actions> -->
          <v-card-actions class="pt-0 pb-0">
            <v-btn
              color="#3c3c3c"
              text
              :ripple="false"
              class="ftw5 fts14 no-background-hover"
              >{{ item.status_text }}</v-btn
            >
            <v-spacer></v-spacer>
            <v-tooltip top :disabled="item.actions.is_deletable">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn
                    v-if="item.show_application"
                    color="#3c3c3c"
                    text
                    :ripple="false"
                    class="ftw5 fts14 no-background-hover mr-5"
                    @click="openApplication(item)"
                  >
                    <v-icon>mdi-information</v-icon> 상세보기
                  </v-btn>

                  <v-btn
                    color="#3c3c3c"
                    text
                    :ripple="false"
                    class="ftw5 fts14 no-background-hover mr-5"
                    :disabled="!item.actions.is_deletable"
                    @click="delPopup(item.id)"
                  >
                    <v-icon>mdi-delete</v-icon> 취소
                  </v-btn>
                </div>
              </template>
              <span>{{ item.actions.no_delete_text }}</span>
            </v-tooltip>
          </v-card-actions>
        </div>

        <!-- 신청 내역이 없을 때 -->
        <div class="mt20 complete-box" v-if="!items.length">
          <div class="comin-box">
            <div class="comin-cont no-mes">
              <span class="text-center ds-b fts14 lh24 color-5"
                >신청내역이 없습니다</span
              >
            </div>
          </div>
        </div>
      </div>
      <v-btn
        color="primary"
        elevation="0"
        class="fts18 ftw7 mw-400 pwidth100 mt15"
        min-height="50"
        block
        @click="$router.push({ name: 'mypage' })"
        >확인</v-btn
      >
    </v-card>
    <v-dialog v-model="cancelDialog" max-width="290">
      <v-card>
        <v-card-title class="justify-center"> </v-card-title>
        <v-card-text class="text-left ftw7">
          예약을 취소하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="delFacilityReservationSeedAPI()"
          >
            예
          </v-btn>
          <v-btn color="primary darken-1" text @click="cancelDialog = false">
            아니오
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="application.dialog" width="500">
      <template v-slot:activator="{ on2, attrs }">
        <v-btn
          color="#3c3c3c"
          text
          :ripple="false"
          v-bind="attrs"
          v-on="on2"
          class="ftw5 fts14 no-background-hover mr-5"
          v-show="false"
        >
        </v-btn>
      </template>

      <FacillityApplication
        :item="application.item"
        @close="closeApplication()"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import {
  getFacilityReservationSeedList,
  delFacilityReservationSeed
} from "@/api/dwelling";
import { daysOfPeriod } from "@/utils/time.js";

import FacillityApplication from "@/components/Dwelling/FacillityApplication.vue";

export default {
  name: "FacilityHistory",
  components: {
    Back,
    Weblogo,
    FacillityApplication
  },
  data() {
    return {
      items: [],
      delId: 0,
      cancelDialog: false,
      application: {
        dialog: false,
        item: {}
      }
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    tenantHome() {
      return this.$store.getters.getTenantHome;
    }
  },
  methods: {
    getFacilityReservationSeedListAPI() {
      getFacilityReservationSeedList("").then(res => {
        this.items = res.data;
      });
    },
    delFacilityReservationSeedAPI() {
      delFacilityReservationSeed(this.delId)
        .then(re => {
          this.$store.commit("SUCCESS", "예약이 취소 되었습니다.");
          this.getFacilityReservationSeedListAPI();
          this.cancelDialog = false;
        })
        .catch(err => {
          console.log("실패...");
          console.log(err);
        });
    },
    delPopup(id) {
      this.delId = id;
      this.cancelDialog = true;
    },
    mainOptions(options) {
      return options.filter(i => {
        return !i.is_sub_option;
      });
    },
    subOptions(options) {
      return options.filter(i => {
        return i.is_sub_option;
      });
    },
    getDays(start, end) {
      if (end) {
        return daysOfPeriod(start, end);
      } else {
        return 1;
      }
    },
    openApplication(item) {
      item.user = this.user;
      item.home = this.tenantHome;
      this.application.item = item;
      this.application.dialog = true;
    },
    closeApplication() {
      this.application.dialog = false;
    }
  },
  created() {
    this.getFacilityReservationSeedListAPI();
  }
};
</script>
