<template>
  <v-container class="pd16">
    <div class="btn-itembox">
      <!-- LYN-659 -->
      <!-- v-if="village.is_rental_waiting_enabled" -->
      <a
        :class="[
          'com-btn',
          {
            disa: !village.is_rental_waiting_enabled,
          },
        ]"
        @click="moveTo('waitRequestForm')"
      >
        <span class="icon"><Iconcheck /></span>
        <span class="text">대기신청</span>
      </a>
      <!-- v-if="village.is_rental_request_enabled" -->
      <a
        :class="[
          'com-btn',
          {
            disa: !village.is_rental_request_enabled,
          },
        ]"
        @click="moveTo('contractRequest')"
      >
        <span class="icon"><Iconpage /></span>
        <span class="text">계약신청</span>
      </a>
    </div>
    <strong class="t-cont-title" :id="`target-${-1}`">계약절차</strong>
    <div class="mb20">
      <div class="openbox-item2 step">
        <v-list-group :value="true">
          <template v-slot:activator>
            <strong class="ob-title">1. 대기신청</strong>
          </template>
          <div sub-group>
            <ul class="text-list">
              <li>선호 단지 및 평형 선택 후 관심고객 등록</li>
              <li>※ 대기자명으로만 계약이 가능합니다.</li>
              <li>※ 1인 1회 가능합니다.</li>
              <li>(타입 및 중복 대기 불가)</li>
            </ul>
          </div>
        </v-list-group>
      </div>

      <div class="openbox-item2 step">
        <v-list-group :value="false">
          <template v-slot:activator>
            <strong class="ob-title">2. 계약신청</strong>
          </template>
          <div sub-group>
            <ul class="text-list">
              <li>동·호수, 계약조건 선택 및 계약 내용 확인</li>
            </ul>
          </div>
        </v-list-group>
      </div>

      <div class="openbox-item2 step">
        <v-list-group :value="false">
          <template v-slot:activator>
            <strong class="ob-title">3. SMS 안내 문자 수신</strong>
          </template>
          <div sub-group>
            <ul class="text-list">
              <li>계약금 납부 계좌 안내 문자 수신</li>
            </ul>
          </div>
        </v-list-group>
      </div>

      <div class="openbox-item2 step">
        <v-list-group :value="false">
          <template v-slot:activator>
            <strong class="ob-title">4. 계약금 납부</strong>
          </template>
          <div sub-group>
            <ul class="text-list">
              <li>세대별 가상 계좌로 계약금 납부</li>
            </ul>
          </div>
        </v-list-group>
      </div>

      <div class="openbox-item2 step">
        <v-list-group :value="false">
          <template v-slot:activator>
            <strong class="ob-title">5. 임대차계약 체결</strong>
          </template>
          <div sub-group>
            <ul class="text-list mb30">
              <li class="fts16">통합관리센터 방문 및 계약 체결</li>
            </ul>
            <strong class="step-title">계약 장소</strong>
            <p class="step-text mb30">커뮤니티센터 내 통합관리센터</p>
            <strong class="step-title">구비서류 및 준비물</strong>
            <strong class="t-cont-title">본인 계약시</strong>
            <ul class="bull-list">
              <li>인감도장</li>
              <li>인감증명서 1부</li>
              <li>주민등록등본 1부</li>
              <li>신분증 사본</li>
            </ul>
            <strong class="t-cont-title">대리인 계약시</strong>
            <ul class="bull-list mb0">
              <li>본인 방문 시 구비서류</li>
              <li>위임장(통합관리센터 및 홈페이지 게시판이용)</li>
              <li>위임용 인감증명서 1부</li>
              <li>대리인 신분증 사본</li>
              <li>가족관계증명서 1부 외(대리인은 가족에 한함)</li>
            </ul>
            <ul class="text-list mt24">
              <li class="under fts16">※ 서류는 3개월 이내 발급분만 유효함.</li>
            </ul>
          </div>
        </v-list-group>
      </div>
    </div>
    <div class="step-contbox">
      <strong class="t-cont-title" :id="`target-${-2}`"
        >임대조건표 <span class="ft-r">(단위: 원,m²)</span></strong
      >
      <div
        class="openbox-item2 step"
        v-for="(contract_type, idx) in village.rental_contract_type"
        :key="'step-contbox-' + idx"
      >
        <v-list-group :value="true">
          <template v-slot:activator>
            <strong class="ob-title">{{ contract_type.text }}</strong>
          </template>
          <div sub-group>
            <table class="step-table">
              <colgroup>
                <col width="33%" />
                <col width="33%" />
                <col width="34%" />
              </colgroup>
              <tr class="title">
                <td>층구분</td>
                <td>보증금</td>
                <td>월 임대료</td>
              </tr>
            </table>

            <table
              class="step-table"
              v-for="(type, type_idx) in contract_type.types"
              :key="'step-contbox-type' + type_idx"
            >
              <colgroup>
                <col width="33%" />
                <col width="33%" />
                <col width="34%" />
              </colgroup>

              <tr class="top">
                <th colspan="3">{{ type.text }}</th>
              </tr>
              <tr
                v-for="(floor_type, floor_idx) in type.floor_types"
                :key="'step-contbox-floor' + floor_idx"
              >
                <td>{{ floor_type.text }}</td>
                <td>{{ floor_type.deposit | comma }}</td>
                <td>{{ floor_type.rental_fee | comma }}</td>
              </tr>
            </table>
          </div>
        </v-list-group>
      </div>
    </div>
  </v-container>
</template>

<script>
import Iconcheck from "@/assets/images/icon/icon-check.svg";
import Iconpage from "@/assets/images/icon/icon-page.svg";

export default {
  components: {
    Iconcheck,
    Iconpage,
  },
  name: "ContractInfo",
  data() {
    return {};
  },
  props: ["village"],
  methods: {
    moveTo(path) {
      if (path == "waitRequestForm") {
        if (!this.village.is_rental_waiting_enabled) {
          this.$store.commit("SUCCESS", "대기신청 기간이 아닙니다.");
          return;
        }
      } else {
        if (!this.village.is_rental_request_enabled) {
          this.$store.commit("SUCCESS", "계약신청 기간이 아닙니다.");
          return;
        }
      }
      this.$router.push({
        name: path,
        params: { selectVillageId: this.village.id },
      });
    },
  },
};
</script>
