<template>
  <v-container class="vh-footer bg-gray ps-re lynn-mobile lynn-web" fluid>
    <v-container class="pd0">
      <div class="tabs-box">
        <ul class="tab-menu">
          <li v-bind:class="{ active: openTab === 0 }">
            <a href="#n" @click="openTab = 0">분양단지</a>
          </li>
          <li v-bind:class="{ active: openTab === 1 }">
            <a href="#n" @click="openTab = 1">공사단지</a>
          </li>
          <li v-bind:class="{ active: openTab === 2 }">
            <a href="#n" @click="openTab = 2">입주단지</a>
          </li>
        </ul>
      </div>
      <div class="tab-contents">
        <div v-if="openTab == 0">
          <VillageInfoList
            :code="code"
            :data="fillterd_villageList"
            :isLoading="isLoading"
          />
        </div>
        <div v-if="openTab == 1">
          <VillageInfoList
            :code="code"
            :data="fillterd_villageList"
            :isLoading="isLoading"
          />
        </div>
        <div v-if="openTab == 2">
          <VillageInfoList
            :code="code"
            :data="fillterd_villageList"
            :isLoading="isLoading"
          />
        </div>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import VillageInfoList from "@/components/VillageInfo/VillageInfoList"; // 분양단지

export default {
  components: {
    VillageInfoList,
  },
  name: "VillageInfoGird",
  data() {
    return {
      openTab: 0,
      isLoading: true,
    };
  },
  watch: {
    navTabIndex() {
      if (this.navTabIndex != null) {
        this.openTab = this.navTabIndex;
      }
    },
  },
  computed: {
    navTabIndex() {
      return this.$store.state.navTabIndex;
    },
    user() {
      return this.$store.getters.getUser;
    },
    code() {
      /*  
            PARCELLING: 분양중
            PARCELLING_EXPECTED: 분양예정
            CONSTRUCTION: 공사단지
            LIVING: 입주단지
        */
      return { 0: "PARCELLING", 1: "CONSTRUCTION", 2: "LIVING" }[this.openTab];
    },
    villageList() {
      return this.$store.state.data.villageList;
    },
    fillterd_villageList() {
      let temp = this.villageList.filter((village) => {
        if (
          this.code == "PARCELLING" &&
          village.status_code_code.includes(this.code)
        ) {
          return village.is_selling;
        } else {
          return village.status_code_code.includes(this.code);
        }
      });

      if (this.openTab == 1) {
        temp = this.sortByKey(temp, "move_in_date", true);
      }

      return temp;
    },
  },
  methods: {
    getVillageList() {
      this.$store
        .dispatch(
          "GET_VILLAGES",
          "is_display=true&&order_by=display_order&is_simple_list=Y"
        )
        .finally(() => {
          this.isLoading = false;
        });
      if (this.user) {
        this.$store.dispatch("GET_INTERESTED_LIST");
      }
    },
    sortByKey(arr, key, reverse = false) {
      return arr.sort(function(a, b) {
        var keyA = a[key],
          keyB = b[key];
        if (reverse) {
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        } else {
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
        }

        return 0;
      });
    },
  },
  created() {
    if (this.$route.params.tabIndex != null) {
      this.openTab = this.$route.params.tabIndex;
    }

    this.getVillageList();
  },
};
</script>
