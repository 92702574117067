<template>
  <v-container class="vh-footer pd20 lynn-web-in overout bg-gray">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="back()" class="back">
        <Back />
      </a>
      <strong class="lo-title">관리비 상세</strong>
    </div>
    <v-card
      max-width="750"
      class="mx-auto nops bg-gray"
      style="border-radius: 0"
    >
      <v-card-text class="pd0">
        <div class="expp-tables">
          <strong>{{ month }}월 관리비 상세내역</strong>
          <table>
            <colgroup>
              <col width="*" />
              <col width="95px" />
              <col width="95px" />
            </colgroup>
            <tr class="titles">
              <th>항목</th>
              <th>금액</th>
              <th>증감</th>
            </tr>
            <tr class="subtitle">
              <td>합계</td>
              <td>{{ fee.amount | comma }}</td>
              <td>
                <span v-if="fee.diff_to_last_month == 0">-</span>
                <span v-if="fee.diff_to_last_month > 0" class="up"
                  >+{{ fee.diff_to_last_month | comma }}</span
                >
                <span v-if="fee.diff_to_last_month < 0" class="down">{{
                  fee.diff_to_last_month | comma
                }}</span>
              </td>
            </tr>

            <!-- items -->
            <tr v-for="item in fee.items" :key="item.name">
              <td>{{ item.name }}</td>
              <td>{{ item.amount | comma }}</td>
              <td>
                <span v-if="item.diff < 0" class="down">{{
                  item.diff | comma
                }}</span>
                <span v-if="item.diff > 0" class="up"
                  >+{{ item.diff | comma }}</span
                >
                <span v-if="item.diff == 0">-</span>
              </td>
            </tr>
            <!-- <tr>
              <td>청소비</td>
              <td>24,020</td>
              <td><span class="up">+1000</span></td>
            </tr>
            <tr>
              <td>경비비</td>
              <td>10,830</td>
              <td><span class="down">-1,000</span></td>
            </tr>
            <tr>
              <td>소독비</td>
              <td>890</td>
              <td><span>-</span></td>
            </tr> -->
          </table>
        </div>
      </v-card-text>
    </v-card>
    <div class="text-center" v-if="loadingBar">
      <v-progress-circular
        fullscreen
        indeterminate
        color="red"
        style="position: fixed; top: calc(50% - 16px); left: calc(50% - 16px)"
      ></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import { getFeeDetail } from "@/api/index";
export default {
  name: "MgmtFeeDetail",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
      fee: {},
      year: "",
      month: "",
      loadingBar: false,
    };
  },
  computed: {
    home() {
      return this.$store.getters.getTenantHome;
    },
  },
  methods: {
    getDetail() {
      this.loadingBar = true;
      getFeeDetail(this.home.id, this.year, this.month).then((res) => {
        console.log(res.data);
        this.fee = res.data;
        this.loadingBar = false;
      });
    },
    back() {
      this.$router.push({
        name: "mgmtFee",
        params: { year: this.year, month: this.month },
      });
    },
  },
  created() {
    this.year = this.$route.params.year;
    this.month = this.$route.params.month;
    this.getDetail();
  },
};
</script>
