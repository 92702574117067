import axios from "axios";
import store from "@/store";
/*
    Title:
        권한별 API 설정
    Auth:
        Token,
    Terms:
        AuthConnection: with Cognito Token(with login).
        Connection: without Cognito Token(without login).
    Usage:
        AuthConnection.post(/users, data)
        AuthConnection.get(/users/1)
        AuthConnection.put(/users/1, data)
        AuthConnection.delete(/users/1)
*/

const baseURL = process.env.VUE_APP_API_ENDPOINT;
const smartLynnURL = process.env.VUE_APP_SMART_LYNN_API_ENDPOINT;

const AuthConnection = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    Authorization: getToken(),
    "Content-Type": "application/json",
  },
});

const Connection = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    Authorization: null,
    "Content-Type": "application/json",
  },
});

const SmartLynnConnection = axios.create({
  baseURL: smartLynnURL,
  timeout: 10000,
  headers: {
    Authorization: null,
    "x-cog-token": getToken(),
    "Content-Type": "application/json",
  },
});

function getToken() {
  // Get Cognito JWT for API calling.
  var Token = null;

  // Token이 setup 되지 않을 시.
  if (!localStorage.vuex) return null;

  Token = store.getters.getIdToken;

  // Token이 잘못 지정되었을 때
  if (!Token) return null;

  return Token;
}

function checkToken(instance) {
  // 최초 로그인 시, AuthConnection의 headers.Authorization에 null이 담김.
  // 이 경우 해당 axios instance headers 에 다시 Token 을 담아줌,
  if (!instance.defaults.headers.Authorization) {
    instance.defaults.headers.Authorization = getToken();
  }
}

export { AuthConnection, Connection, SmartLynnConnection, checkToken };
