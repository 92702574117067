<template>
  <v-container class="vh-footer pd20 lynn-web-in bg-gray">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a
        @click="$router.push({ name: 'facilityInfo', params: { id: id } })"
        class="back"
      >
        <Back />
      </a>
      <strong class="lo-title">{{ facility.name }} 신청</strong>
    </div>
    <v-card
      max-width="750"
      class="mx-auto nops bg-gray"
      style="border-radius: 0"
    >
      <div class="vote-incont">
        <!-- 일반 텍스트 상자 -->
        <!-- <ul class="vtc-box" v-if="reservation.description">
          <li class="vtc-ntext" v-html="reservation.description"></li>
        </ul> -->
        <strong class="vote-formtitle" v-if="reservation.description"
          >신청안내</strong
        >
        <v-expansion-panels accordion v-if="reservation.description">
          <v-expansion-panel>
            <v-expansion-panel-header @click="infoClick = true"
              >사용수칙 및 주의사항</v-expansion-panel-header
            >
            <v-expansion-panel-content
              style="max-height: 240px; overflow: auto"
              class="text-left"
              ><p v-html="reservation.description"></p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-checkbox
          class="mt5"
          label="동의합니다."
          v-if="reservation.description"
          v-model="check"
          :disabled="!infoClick"
        ></v-checkbox>

        <strong class="vote-formtitle">신청정보</strong>
        <v-card-text class="pd0">
          <div class="rdroom-box vote-incont">
            <strong class="vote-formtitle ftw4 mt0">날짜선택</strong>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.start_date"
                  label="시작일"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.start_date"
                :min="min"
                :max="max"
                @change="
                  checkFacilityReservationAPI(form.start_date, form.end_date);
                  menu = false;
                "
                :day-format="(date) => new Date(date).getDate()"
                locale="KR"
                full-width
                no-title
              ></v-date-picker>
            </v-menu>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.end_date"
                  label="종료일"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.end_date"
                :min="form.start_date"
                :max="max"
                @change="
                  checkFacilityReservationAPI(form.start_date, form.end_date);
                  menu2 = false;
                "
                :day-format="(date) => new Date(date).getDate()"
                locale="KR"
                full-width
                no-title
              ></v-date-picker>
            </v-menu>

            <!-- <v-date-picker
              :min="min"
              :max="max"
              locale="KR"
              full-width
              no-title
              v-model="form.start_date"
              @change="checkFacilityReservationAPI(form.start_date, form.end_date)"
            ></v-date-picker> -->
            <strong class="vote-formtitle ftw4">좌석선택</strong>
            <div class="rd-all-box">
              <ul class="rd-set">
                <li>
                  <img
                    :src="require('@/assets/images/common/icons-chair_on.png')"
                  /><span>신청가능({{ facilitySeats.available.length }})</span>
                </li>
                <li>
                  <img
                    :src="require('@/assets/images/common/icons-chair_d.png')"
                  /><span>사용중({{ facilitySeats.used.length }})</span>
                </li>
                <li>
                  <div class="rd-disa"></div>
                  <span>사용불가({{ facilitySeats.disabled.length }})</span>
                </li>
                <li class="rduser-set">
                  <p>사용중 좌석</p>
                  <span
                    >{{ facilitySeats.used.length }} /
                    {{
                      facilitySeats.used.length + facilitySeats.available.length
                    }}</span
                  >
                </li>
              </ul>
              <ReadingRoom
                :item="facilitySeats"
                :village="item.village"
                :type="item.type"
                @click="clickSeat"
              />
            </div>
          </div>
        </v-card-text>

        <!--  <div class="mt20 mb20 after">
          <span class="ds-b fts20 ft-l ftw7 lh29">합계</span>
          <span class="ds-b fts20 ft-r ftw7 lh29">{{ priceSum | comma }}</span>
        </div> -->

        <!-- 유저 정보 -->
        <ul class="vtc-user">
          <li class="title">신청인 {{ user.name }}</li>
          <li>{{ tenantHome.village_name }}</li>
          <li>{{ tenantHome.dong_ho_text }}</li>
          <li>연락처 : {{ user.mobile_form }}</li>
        </ul>

        <v-btn
          v-if="!is_form_ready"
          color="grey lighten-3"
          class="fts18 ftw7 mw-700 pwidth100 mt25 grey--text text--lighten-1"
          min-height="50"
          elevation="0"
          >신청하기</v-btn
        >
        <v-btn
          v-else
          @click="postFacilityReservationSeedAPI()"
          color="primary"
          class="fts18 ftw7 mw-700 pwidth100 mt25"
          min-height="50"
          elevation="0"
          >신청하기</v-btn
        >
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import ReadingRoom from "@/components/Dwelling/SeatReservation/ReadingRoom.vue";

import {
  getFacility,
  checkFacilityReservation,
  postFacilityReservationSeed,
} from "@/api/dwelling";
import {
  get_now_string,
  isFuture,
  add_day,
  add_month,
  set_date,
} from "@/utils/time.js";

export default {
  name: "FacilityReservation",
  components: {
    Back,
    Weblogo,
    ReadingRoom,
  },
  data() {
    return {
      id: 0,
      item: {},
      form: {
        master: null,
        seat: null,
        user: null,
        start_date: "",
        end_date: null,
      },
      today: add_day(get_now_string(), 1),
      end_of_next_month: null,

      // Seats
      facilitySeats: { available: [], used: [], disabled: [] },

      priceSum: 0,

      menu: false,
      menu2: false,
      check: false,
      infoClick: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    tenantHome() {
      return this.$store.getters.getTenantHome;
    },
    facility() {
      if (this.item.facility) {
        return this.item.facility;
      }
      return {};
    },
    reservation() {
      if (this.item.reservation) {
        return this.item.reservation;
      }
      return {};
    },
    kind_code() {
      return this.reservation.kind_code;
    },
    min() {
      let date = this.reservation.start_date;
      if (!isFuture(this.today, date)) {
        date = this.today;
      }
      return date;
    },
    max() {
      let date = this.reservation.end_date;

      if (!date) {
        return this.end_of_next_month;
      } else if (!isFuture(this.today, date)) {
        date = this.today;
      }

      if (isFuture(this.end_of_next_month, date)) {
        date = this.end_of_next_month;
      }
      return date;
    },
    is_form_ready() {
      return this.form.seat;
    },
  },
  methods: {
    getFacilityAPI(id) {
      this.id = id;
      getFacility(id).then((res) => {
        this.item = res.data;
        if (!this.item.is_seat_reservation) {
          // Push to reservation
          this.$router.push({
            name: "facilityReservation",
            params: { id: this.item.id },
          });
        }

        if (this.item.use_yn == "N") {
          this.noExist("존재하지 않는 시설 입니다.");
        } else if (!this.item.reservation) {
          this.noExist("예약 가능한 시간이 존재하지 않습니다.");
        }
        this.form.master = this.item.reservation.id;

        this.form.start_date = this.item.reservation.start_date; // 초기 선택 날짜 지정
        if (!isFuture(this.today, this.form.start_date)) {
          this.form.start_date = this.today;
        }

        this.form.end_date = add_day(add_month(this.form.start_date, 1), -1);

        this.checkFacilityReservationAPI(
          this.form.start_date,
          this.form.end_date
        );
      });
    },
    checkFacilityReservationAPI(start_date, end_date) {
      let query = `start_date=${start_date}&&end_date=${end_date}&&kind_code=FACILITY_CATEGORY_SHARING`;
      checkFacilityReservation(this.reservation.id, query).then((res) => {
        this.facilitySeats = res.data.seats;
      });
    },
    postFacilityReservationSeedAPI() {
      if (!this.check && this.reservation.description) {
        this.$store.commit(
          "ERROR",
          "사용수칙 및 주의사항을 읽고 동의해주세요."
        );
        return;
      }
      this.form.user = this.user.id;
      postFacilityReservationSeed(this.form)
        .then((res) => {
          this.$store.commit("SUCCESS", "예약이 완료되었습니다.");
          this.$router.push({ name: "facilityHistory" });
        })
        .catch((err) => {
          console.log(err.response);
          this.$store.commit("ERROR", err.response.data);
          this.checkFacilityReservationAPI(
            this.form.start_date,
            this.form.end_date
          );
        });
    },
    // Functions
    clickSeat(val) {
      this.form.seat = null;
      if (this.facilitySeats.available.includes(val)) {
        this.form.seat = val;
      } else {
        this.$store.commit("ERROR", "예약 불가한 좌석 입니다.");
      }
    },
    // Utils
    noExist(text) {
      this.$router.push({ name: "dwelling" });
      this.$store.commit("ERROR", text);
    },
  },
  created() {
    if (this.$route.params.id || !this.user || !this.tenantHome) {
      this.end_of_next_month = set_date(add_month(this.today, 2), 1);
      this.end_of_next_month = add_day(this.end_of_next_month, -1);
      this.getFacilityAPI(this.$route.params.id);
    } else {
      this.noExist("존재하지 않는 시설 입니다.");
    }
  },
};
</script>
