<template>
  <v-container class="vh-footer pd20 bg-gray lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">개인정보 취급방침</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text class="pd0">
        <ul class="board-list mb15 private">
          <li>
            <pre>
[우미건설 정책]
개인정보 취급방침

우미건설 주식회사(이하 '회사')는 '정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하'법')' 등 모든 관련법규를 준수하며 회원님의 개인정보가 보호받을 수 있도록 최선을 다하고 있습니다. 회사는 개인정보처리방침의 공개를 통하여 회원 여러분의 개인정보가 어떠한 목적과 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지를 알려드립니다. 본 개인정보처리방침은 관련법령의 개정이나 당사 내부 방침에 의해 변경될 수 있으며 회원가입 시나 사이트 이용 시에 수시로 확인하여 주시기 바랍니다.
1. 총칙
2. 개인정보의 수집방법
3. 개인정보 자동수집장치의 설치∙운영 및 그 거부방법
4. 개인정보의 이용 및 제3자 제공
5. 개인정보의 처리위탁
6. 개인정보의 이용·보관 기간
7. 개인정보의 파기
8. 고객의 권리와 그 행사방법
9. 개인정보 수집·이용·제공에 대한 동의철회
10. 서비스의 링크사이트
11. 서비스의 게시물
12. 의견수렴 및 불만처리
13. 개인정보보호책임자
14. 고지의 의무
15. 개인정보의 국외이전

1. 총칙
(1) 개인정보란 생존하는 개인에 관한 정보로서 성명, 주민등록번호 등에 의하여 특정한 개인을 알아볼 수 있는 부호 · 문자 · 음성 · 음향 및 영상 등의 정보
해당 정보만으로는 특정 개인을 알아볼 수 없어도 다른 정보와 쉽게 결합하여 알아 볼 수 있는 경우에는 그 정보를 포함)를 말합니다.
(2) 회사는 개인정보처리방침을 통하여 고객의 개인정보가 어떤 용도와 방식으로 이용되고 있으며 개인정보 보호를 위하여 어떤 조치가 취해지고 있는지 알려드립니다.
(3) 회사는 개인정보처리방침을 관련 법령 또는 내부 운영규정의 변경에 따라 개정할 수 있으며, 개인정보처리방침을 개정하는 경우 버전번호 등을 부여하여 개정된 사항을 고객이 쉽게 알아볼 수 있도록 하고 있습니다.
(4) 회사는 고객의 개인정보를 수집하는 경우 관련 법령에 따라 가입신청서 또는 이용약관 등을 통하여 그 수집범위 및 수집 · 이용 목적을 사전에 고지합니다.
(5) 회사는 다음 각호와 같이 고유식별 정보를 수집 및 이용하고 있습니다.
- 고유식별정보의 수집/이용 목적 : 휴대폰 본인확인 서비스 제공, 본인확인, 본인인증, 실명인증
- 고유식별정보는 성명, 휴대폰번호, 주소, 이메일 입니다. 

2. 개인정보의 수집방법
(1) 회사는 고객이 가입신청서, 명의변경신청서 등 각종 서면을 작성하는 경우, 회사의 유 · 무선 서비스에서 회원가입을 하는 경우, 특정 서비스 화면상으로 동의한 경우, 전화(SMS, MMS를 포함), 스마트폰 APP등의 온라인을 통하여 회원가입을 하는 경우, 우편, 이메일, 팩스, 기타 매체를 통하여 동의한 경우에 개인정보를 수집합니다.
(2) 그 밖에 회사의 이동전화, 유 · 무선 인터넷상의 생성정보 수집 툴 등을 통하여 개인정보를 수집할 수 있습니다.

3. 개인정보 자동수집장치의 설치·운영 및 그 거부방법
(1) 쿠키(cookie)란? 쿠키는 서비스가 고객의 온라인 서비스로 전송하는 소량의 정보입니다. 고객이 서비스에 접속하면 회사의 컴퓨터는 온라인 서비스에 있는 쿠키의 내용을 읽고, 고객의 추가정보를 고객의 온라인서비스에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다.
또한, 고객은 쿠키에 대한 선택권이 있습니다. 단, 고객에게서 쿠키 설치를 거부하였을 경우 서비스 이용에 불편이 있거나, 서비스 제공에 어려움이 있을 수 있습니다.
(2) 통계데이터란? 온라인 서비스 이용 시 단말기의 특정 영역에 저장되고, 주기적으로 회사의 서버로 전달되는 아래와 같은 정보입니다.
① 서비스 사용 통계(접속, 차단, 실패 시 망환경, 다운로드 실행오류, 무선인터넷 접속실패 및 접속시간 등)
② 사용 패턴 정보(고객의 메뉴 이동경로, 주로 이용하는 서비스, 서비스 이용횟수, 사용량 등
③ 고객은 서비스 제공에 관한 회사의 계약이행을 위하여 필요한 경우와 요금 정산을 위하여 필요한 경우 및 법령에서 정한 경우를 제외하고 단말기의 통계 데이터 차단 옵션을 통하여 회사의 통계 데이터 수집 · 이용을 거부할 수 있습니다.
(3) 회사의 쿠키, 통계 데이터 운영 회사는 이용자의 편의를 위하여 쿠키, 통계데이터를 운영합니다. 회사의 쿠키, 통계 데이터 사용목적은 다음과 같습니다.
① 개인의 관심 분야에 따라 차별화된 정보를 제공
② 회원과 비회원의 접속빈도 또는 머문 시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 타겟(target) 마케팅에 활용
③ 관심있게 둘러본 내용들에 대한 자취를 추적하여 다음 번 접속 때 개인 맞춤 서비스를 제공
④ 유료서비스 이용 시 이용기간 안내
⑤ 회원들의 습관을 분석하여 서비스 개편 시 기준으로 이용
⑥ 게시판의 글 등록
(4) 쿠키는 브라우저의 종료 시나 로그 아웃 시 만료됩니다.

4. 개인정보의 이용 및 제3자 제공
(1) 회사는 고객의 개인정보를 가입신청서, 개인정보처리방침의 '개인정보 수집 · 이용 목적상 고지한 범위 내에서 이용 및 제공하고 있습니다. 특히, 다음의 경우는 주의를 기울여 개인정보를 이용 및 제공하고 있습니다.
① 제휴관계
보다 나은 서비스 제공을 위하여 고객의 개인정보를 제휴사에게 제공하거나 제휴사와 공유할 수 있습니다. 이 경우 사전에 고객에게 제휴사가 누구인지, 제공 또는 공유되는 개인정보의 항목이 무엇인지, 왜 그러한 개인정보가 제공되거나 공유되어야 하는지, 그리고 언제까지 어떻게 보관 · 관리되는지에 관하여 개별적으로 이메일, 앱 공지사항 등 전자적 방법이나, 전화, 서면, 팩스, 우편 등을 통해 고지합니다. 고객이 동의하지 않는 경우에는 서비스 제공에 제약이 있을 수 있습니다. 
② 매각 · 인수합병 등
회사의 서비스 제공자로서의 권리와 의무가 완전 승계 또는 이전되는 경우 사전에 개인정보를 이전하려는 사실, 개인정보의 이전을 받는 자(이하 '영업양수자 등')의 성명(법인인 경우 법인의 명칭) · 주소 · 전화번호 그 밖에 연락처, 고객이 개인정보의 이전을 원하지 아니하는 경우 그 동의를 철회할 수 있는 방법과 절차에 대해 고지할 것이며 고객의 개인정보에 대한 동의 철회의 선택권을 부여합니다.
(2) 고객의 동의가 있거나, 고객의 동의가 없더라도 요금정산을 위하여 필요한 경우, 법, 국세기본법, 지방세법, 통신비밀보호법, 금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용 및 보호에 관한 법률, 전기통신기본법, 전기통신사업법, 소비자기본법, 한국은행법, 형사소송법 등 관련 법령에 특별한 규정이 있는 경우 제(1)항의 규정에도 불구하고 회사는 고객의 개인정보 수집 시에 고객에게 고지한 범위 또는 이용약관에 명시한 범위를 넘어서 이용하거나 제3자에게 제공할 수 있습니다. 다만, 관련 법령에 의한 경우에도 고객의 개인정보를 무조건 제공하는 것은 아니며 법령에 정해진 절차와 방법에 따라 제공합니다.
(3) 회사는 서비스의 제공에 관한 계약을 이행하기 위하여 필요한 개인정보로서 경제적 · 기술적인 사유로 통상적인 동의를 받는 것이 곤란한 경우에는 고객의 동의가 없더라도 개인정보를 수집 · 이용할 수 있습니다.

5. 개인정보의 처리위탁
(1) 회사는 보다 나은 서비스 제공과 고객편의 제공 등 업무 수행을 원활하게 하기 위하여 외부 전문업체에 고객의 개인정보에 대한 수집 · 보관 · 처리 · 이용 · 제공 · 관리 · 파기 등(이하 '처리')을 위탁할 수 있습니다.
(2) 회사가 외부 전문업체에 고객의 개인정보를 처리 위탁하는 경우 그 위탁업무의 내용, 수탁자를 명시하여야 합니다.
(3) 회사는 개인정보 처리위탁의 경우 계약 등을 통하여 법상 개인정보 보호규정의 준수, 개인정보에 관한 비밀유지, 제3자 제공 금지 및 사고 발생 시의 책임부담, 위탁기간, 개인정보의 반환 및 파기 등을 명확히 규정하고 당해 계약 내용을 서면 또는 전자적으로 보관합니다.
(4) 회사는 고객의 동의 없이 서비스 제공 이외의 목적으로 개인정보를 처리 위탁하지 않는 것을 원칙으로 하고있지만 필요한 경우 위탁업무의 내용과 수탁자를 고객에게 고지하고 동의를 받습니다.
(5) 회사는 편리하고 더 나은 서비스를 제공하기 위해 아래와 같은 업무를 위탁하고 있으며, 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.

<table class="step-table">
  <colgroup>
    <col width="33%" />
    <col width="33%" />
    <col width="34%" />
  </colgroup>
  <tr class="title">
    <td>수탁 업체</td>
    <td>위탁 업무 내용</td>
    <td>개인정보의 보유 및 이용기간</td>
  </tr>
  <tr>
    <td>(주)파도시스템</td>
    <td>앱/웹 개발, 운영 서비스</td>
    <td rowspan="5">회원탈퇴 시 혹은 위탁계약 종료시까지, 법령이 정한 시점</td>
  </tr>
  <tr>
    <td>제휴한 건설사</td>
    <td>계약 및 임대 운영 서비스</td>
  </tr>
  <tr>
    <td>제휴한 관리사무소</td>
    <td>주거 생활 관련 입주민 응대 서비스</td>
  </tr>
  <tr>
    <td>제휴한 공용시설 운영 대행 업체</td>
    <td>시설 관련 입주민 응대 서비스</td>
  </tr>
  <tr>
    <td>나이스평가정보(주)</td>
    <td>본인인증 서비스</td>
  </tr>
</table>

6. 개인정보의 이용·보관 기간
(1) 고객의 개인정보는 서비스 가입기간 동안 이용 · 보관함을 원칙으로 합니다.
(2) 다음 각 호의 1에 해당하는 경우에는 그 기간이 도래하거나, 조건이 성취되는 때까지 필요한 범위 내에서 고객의 개인정보를 보관할 수 있습니다.
1) 상법 등 관련 법령의 규정에 의하여 일정기간 보유하여야 할 필요가 있을 경우에는 그 기간 동안 보유합니다. 그 예는 아래와 같습니다.
① 상업장부와 영업에 관한 중요서류에 포함된 개인정보 : 10년(상법)
② 전표 또는 이와 유사한 서류에 포함된 개인정보 : 5년(상법)
③ 고객의 성명, 주민등록번호, 전화번호, 주소, 요금납부내역(청구액, 수납액, 수납일시, 요금납부 방법 등), 기타 거래에 관한 장부: 관련 국세의 법정신고기한이 경과한 날로부터 5년(국세기본법)
④ 계약 또는 청약철회 등에 관한 기록 : 계약 또는 청약철회 시부터 5년(전자상거래 등에서의 소비자보호에 관한 법률)
⑤ 소비자의 불만 또는 분쟁처리에 관한 기록 : 불만 또는 분쟁 처리 시부터 3년(전자상거래 등에서의 소비자보호에 관한 법률)
⑥ 표시광고에 관한 기록 : 6개월(전자상거래 등에서의 소비자보호에 관한 법률)
⑦ 신용정보의 수집 처리 및 이용 등에 관한 기록 : 3년(신용정보의 이용 및 보호에 관한 법률)
⑧ 컴퓨터 통신 또는 인터넷의 사용자가 전기통신역무를 이용한 사실에 관한 컴퓨터 통신 또는 인터넷의 로그기록자료, 컴퓨터 통신 또는 인터넷 사용자가 정보통신망에 접속하기 위하여 사용하는 정보통신기기의 위치를 확인할 수 있는 접속지의 추적자료 : 3개월(통신비밀보호법)
2) 개별적으로 고객의 동의를 받은 경우
그 동의 받은 기간
3) 회사와 고객 간에 민원, 소송 등 분쟁이 발생한 경우에 그 보유기간 내에 분쟁이 해결되지 않을 경우 그 분쟁이 해결될 때까지

7. 개인정보의 파기
(1) 회사는 수집한 개인정보의 수집 · 이용 목적이 달성되거나 그 보유 · 이용기간이 종료되는 경우 고객의 동의, 이용약관, 관련 법령에 따라 보관이 필요한 경우를 제외하고 해당 정보를 파기합니다.
(2) 회사는 서면에 기재된 개인정보의 경우에는 분쇄기로 분쇄하거나 소각하며 전자적 방법으로 저장된 개인정보의 경우에는 그 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
(3) 회사는 ‘개인정보 유효기간제’에 따라 1년간 서비스를 이용하지 않은 이용자는 휴면계정으로 전환되어, 개인정보를 별도로 분리 보관합니다. 회사는 휴면 전환 30일 전까지 휴면 예정 회원에게 별도 분리 보관되는 사실 및 휴면 예정일, 별도 분리 보관하는 개인정보 항목을 이메일, SMS, App push 등 이용자에게 통지 가능한 방법으로 이용자에게 알립니다. 다만, 해당 통지 수단에 대한 정보가 부재 또는 오류인 경우에는 개인정보 처리방침의 내용으로 갈음하며, 로그인 할 때 파기 혹은 별도 분리저장에 관하여 사후 통보할 수 있습니다. 분리 보관된 개인정보는 4년간 보관 후 지체없이 파기합니다. 휴면계정으로 전환을 원하지 않으시는 경우, 휴면계정 전환 전 서비스 내 로그인 하시면 됩니다. 또한, 휴면 계정으로 전환되었더라도 로그인할 경우 이용자의 동의에 따라 휴면계정을 복원하여 정상적인 서비스를 이용할 수 있습니다.

8. 고객의 권리와 그 행사방법
(1) 고객은 언제든지 회사가 보유하는 개인정보, 개인정보의 이용 · 제공내역, 수집 · 이용 · 제공에 대한 동의내역을 열람하거나 정정할 수 있습니다. 해당 개인정보에 오류가 있거나 보존기간이 경과한 것으로 판명되는 등 정정 · 삭제를 할 필요가 있다고 인정되는 경우에는 회사는 이를 수정합니다.
(2) 온라인 가입정보의 열람 및 정정을 하고자 하는 고객의 경우에는 서비스 내의 "회원정보변경"을 클릭하여 직접 열람 및 정정을 하거나, 웹마스터에게 이메일로 연락하는 경우에도 회사는 필요한 조치를 취합니다.
(3) 회사는 대리인이 방문하여 열람 · 증명을 요구하는 경우에는 적법한 위임을 받았는지 확인할 수 있는 위임장 및 본인의 인감증명서와 대리인의 신분증 등을 제출 받아 정확히 대리인 여부를 확인합니다.
(4) 고객이 개인정보의 오류에 대한 정정을 요청한 경우, 정정이 완료되기 전까지 개인정보를 이용 또는 제공하지 않습니다.
(5) 회사는 잘못된 개인정보를 이미 제3자에게 제공한 경우 그 정정 처리결과를 제3자에게 통지하여 정정하도록 합니다.
(6) 고객은 개인정보를 최신의 상태로 정확하게 입력하고 변동 사항이 있는 경우, 이를 회사에 통보하여야 하며, 스스로 부정확한 정보를 입력하거나, 회사에 통보하지 않아서 회사가 알 수 없는 고객정보의 변동으로 인한 책임은 고객 자신에게 귀속됩니다.
(7) 고객이 타인 정보의 도용이나 침해, 허위정보를 입력하는 경우 서비스 해지 및 회원자격이 상실될 수 있으며 법 등 관련 법령에 따라 처벌받을 수 있습니다.
(8) 고객이 개인정보의 열람 · 제공을 반복적으로 요구하는 등 업무에 지장을 줄 우려가 있거나 그 분량이 상당하여 비용이 드는 경우, 회사는 고객의 요구를 연기 또는 거절하거나 업무처리에 따른 실비(복사비 등)를 고객에게 청구할 수 있습니다.

9. 개인정보 수집·이용·제공에 대한 동의철회
(1) 개인정보의 수집 · 이용 · 제공에 대해 고객은 동의한 내용을 언제든지 철회할 수 있습니다. 동의철회는 서비스 내 회원정보 또는 회원정보 변경 화면의 '회원탈퇴'를 클릭하거나 개인정보보호책임자 및 담당자에게 서면, 전화, 이메일 등으로 연락하면 하실 수 있으며 회사는 개인정보의 삭제 등 필요한 조치를 합니다.
o 주소 : 서울시 강남구 언주로 30길 39 린스퀘어(도곡동 467-14)
o 전화 : 우미건설 주식회사 1588-9707
o 이메일 : hhykbk@naver.com
(2) 회사는 고객이 동의철회를 하여 개인정보를 파기하는 등의 조치를 취한 경우 고객의 요청에 따라 그 사실을 고객에게 통지합니다.

10. 서비스의 링크사이트
(1) 회사는 고객에게 다른 서비스 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부 서비스 및 자료에 대한 통제권이 없으므로 그로부터 받는 서비스나 자료의 유용성에 대하여 보증하거나 책임을 지지 않습니다.
(2) 링크를 클릭하여 다른 서비스로 옮겨 갈 경우, 해당 서비스의 개인정보처리방침은 회사와 무관하므로 고객은 새로 방문한 서비스의 정책을 확인하여야 합니다.

11. 서비스의 게시물
(1) 다음의 경우 게시물에 대하여 삭제, 차단, 변경, 삭제 · 변경 요구, 경고, 이용정지, 기타 적절한 조치를 취할 수 있습니다.
1) 스팸성 게시물(예: 행운의 편지, 8억 메일, 특정사이트의 광고 등)
2) 타인을 비방할 목적으로 사실 또는 허위 사실을 유포하여 타인의 명예를 훼손하는 글
3) 동의 없는 신상공개, 초상권 또는 저작권 등 타인의 권리를 침해하는 내용
4) 기타 법령에 반하거나 미풍양속을 저해 또는 게시판의 주제와 다른 내용의 게시물
(2) 회사는 건전한 게시판 문화를 활성화하기 위하여 동의 없는 타인의 신상공개 시 특정부분을 삭제하거나 기호 등으로 수정하여 게시할 수 있습니다.
(3) 다른 주제의 게시판으로 이동 가능한 내용의 경우 해당 게시물에 이동 경로를 밝혀 오해가 없도록 합니다.
(4) 그 외의 경우 경고 후 삭제 조치할 수 있습니다.
(5) 원칙적으로 게시물에 관련된 제반 권리와 책임은 작성자 개인에게 있습니다. 또 게시물을 통해 자발적으로 공개된 정보는 보호받기 어려우므로 정보게시 전에 심사숙고하기 바랍니다.

12. 개인정보 관리책임자
회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보 보호책임자를 지정하고 있습니다.
개인정보보호책임자
o 성 명 : 허화영
o 소 속 : 우미건설 주식회사
o 전 화 : 070-4323-4077
o 메 일 : hhykbk@naver.com

13. 고지의 의무
회사의 개인정보처리방침은 2021년 07월 23일 제정되었으며, 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가ㆍ삭제 및 수정이 있을 시에는 개정 전에 서비스의 '공지'란을 통해 고지합니다.
- 개인정보처리방침

14. 개인정보의 국외이전(1) 회사는 고객의 개인정보를 국외로 이전하고자 하는 때에는 고객에게 다음 사항을 미리 고지하고 동의를 얻습니다.
1) 이전되는 개인정보 항목
2) 개인정보가 이전되는 국가, 일시, 방법
3) 개인정보를 이전 받는 자의 성명(법인의 경우에는 그 명칭 및 정보보호책임자의 연락처)

15. 권익침해 구제방법
정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.(아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.)
o 개인정보 침해신고센터 : (국번없이) 118 (http://privacy.kisa.or.kr)
o 개인정보 분쟁조정위원회 : 02-2100-2499 (http://www.kopico.go.kr)
o 대검찰청 사이버수사과 : (국번없이) 1301 (www.spo.go.kr)
o 경찰청 사이버안전국 : (국번없이) 182 (cyberbureau.police.go.kr)
              </pre>
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import { getBulletin } from "@/api/index";

export default {
  name: "Private",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
      item: {},
    };
  },
  computed: {},
  methods: {},
  created() {},
};
</script>

<style>
.private pre {
  max-width: 100% !important;
  width:100% !important;
  font-family: "Noto Sans KR", sans-serif !important;
  word-break: keep-all;
  text-align: left;
  font-size:14px !important;
  color:#3c3c3c;
  line-height:20px;
  overflow: auto;
  white-space:pre-line;
}
.private table {width:100%;}
.private table td {max-width:0; text-align:center !important; font-weight:400 !important;}
.private table tr.title td {max-width:0; text-align:center !important; font-weight:700 !important; color:#000000;}
</style>