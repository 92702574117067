<template>
  <v-container class="vh-footer pd20 lynn-web-in bg-gray">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.push({ name: 'mypage' })" class="back">
        <Back />
      </a>
      <strong class="lo-title">회원 탈퇴</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops bg-gray">
      <v-card-subtitle class="fts14 lh24"
        >탈퇴 시 삭제/유지되는 정보를 확인해주세요.<br />
        한 번 삭제된 정보는 복구가 불가능합니다.</v-card-subtitle
      >
      <div class="vote-incont">
        <!-- 일반 텍스트 상자 -->
        <ul class="vtc-box">
          <li class="vtc-ntext">
            - 계약 관련 방문 예약 및 시설 신청 내역, 관심단지 정보가
            삭제됩니다.<br />
            - 계약과 관련한 방문 예약을 잡은 경우, 탈퇴와 상관 없이 오프라인에서
            예약 프로세스가 진행됩니다.<br />
            - 휴대폰 번호 및 이메일 등의 개인정보, 인증 정보가 삭제됩니다.<br />
            - 작성한 글은 삭제됩니다.<br />
            - 타인 글의 댓글은 삭제되지 않으니 확인해주세요.<br />
            - 계약 및 임대단지 대기신청 내역은 계정 탈퇴와 상관없이
            우미건설사에서 관리하는 정보입니다.
          </li>
        </ul>
        <v-row class="mt0">
          <v-checkbox
            class="mt5"
            label="안내사항을 모두 확인하였으며 이에 동의합니다."
            v-model="check"
          ></v-checkbox>
        </v-row>

        <!-- <v-card-text>
          <strong class="vote-formtitle ftw4 fts14">사용중인 비밀번호</strong>
          <v-text-field
            type="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            required
          ></v-text-field>
          <p class="ft-l">
            비밀번호를 잊으셨나요?
            <router-link :to="{ path: 'forgetPassword' }" style="color: black"
              ><b><u>비밀번호 재설정</u></b></router-link
            >
          </p>
        </v-card-text> -->

        <a class="vtc-btn" @click="postWithdrawal">탈퇴하기</a>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import { withdrawal } from "@/api/sign.js";

export default {
  name: "Withdrawal",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
      showPassword: false,
      check: false,
    };
  },
  computed: {},
  methods: {
    postWithdrawal() {
      if (!this.check) {
        this.$store.commit("ERROR", "안내사항을 읽고 동의해주세요.");
        return;
      }
      withdrawal().then((result) => {
        this.$store.commit("SUCCESS", "감사합니다.");
        this.$store.dispatch("signOut", {}).then(() => {
          this.$router.push({ name: "intro" });
        });
      });
    },
  },
};
</script>
