<!-- 계약정보 -->
<template>
  <v-container class="vh-footer ps-re lynn-mobile lynn-web-in pd0" fluid>
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="moveTo('/contract')" class="back">
        <Back />
      </a>
      <strong class="lo-title">계약정보</strong>
    </div>

    <!-- 분양, 계약 구분에 따라 메뉴 변경 -->
    <!-- 분양 -->
    <v-container class="pd0 ps-re" v-if="!home.is_rent">
      <div class="tabs-box">
        <v-tabs
          color="black"
          height="37px"
          style="font-size: 14px"
          v-model="tab"
          background-color="white"
        >
          <v-tab
            :class="tab_class"
            :ripple="false"
            value="0"
            @click="moveTo('/contractInfo/status/sell')"
            >계약내용 조회</v-tab
          >
          <v-tab
            :class="tab_class"
            :ripple="false"
            value="1"
            @click="moveTo('/contractInfo/payment/payment')"
            >납부현황 조회</v-tab
          >
          <v-tab
            :class="tab_class"
            :ripple="false"
            value="2"
            @click="moveTo('/contractInfo/loan/loan')"
            >융자현황 조회</v-tab
          >
          <v-tab
            :class="tab_class"
            :ripple="false"
            value="3"
            @click="moveTo('/contractInfo/interest/interest')"
            >대납이자·보증수수료</v-tab
          ><v-tab
            :class="tab_class"
            :ripple="false"
            value="4"
            @click="moveTo('/contractInfo/balance/balance')"
            :disabled="!this.home.is_display_balance_info"
            >잔금납부 안내</v-tab
          >
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div class="tab-contents">
              <div v-if="tab_id == 'status'">
                <div class="tabs-box">
                  <ul class="tab-menu">
                    <li v-bind:class="{ active2: sub_tab_id == 'sell' }">
                      <router-link to="/contractInfo/status/sell"
                        >분양계약</router-link
                      >
                    </li>
                    <li
                      v-bind:class="{
                        active2: sub_tab_id === 'option',
                      }"
                    >
                      <router-link to="/contractInfo/status/option"
                        >별도계약품목</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div v-show="tab_id == 'status' && sub_tab_id == 'sell'">
                <StatusForSell />
                <WarMes />
              </div>
              <div v-show="tab_id == 'status' && sub_tab_id == 'option'">
                <Options />
                <WarMes />
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="tab-contents">
              <div v-if="tab_id == 'payment'">
                <div class="tabs-box">
                  <ul class="tab-menu">
                    <li
                      v-bind:class="{
                        active2: sub_tab_id === 'payment',
                      }"
                    >
                      <router-link to="/contractInfo/payment/payment"
                        >분양대금</router-link
                      >
                    </li>
                    <li
                      v-bind:class="{
                        active2: sub_tab_id === 'option',
                      }"
                    >
                      <router-link to="/contractInfo/payment/option"
                        >별도계약품목</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div v-if="tab_id == 'payment' && sub_tab_id == 'payment'">
                <Payment />
                <WarMes />
              </div>
              <div v-if="tab_id == 'payment' && sub_tab_id == 'option'">
                <OptionPayment />
                <WarMes />
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="tab-contents">
              <div
                class="web-contbox"
                v-if="tab_id == 'loan' && sub_tab_id == 'loan'"
              >
                <Loan />
                <WarMes />
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="tab-contents">
              <div
                class="web-contbox"
                v-if="tab_id == 'interest' && sub_tab_id == 'interest'"
              >
                <LoanInterest />
                <WarMes />
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="tab-contents">
              <div
                class="web-contbox"
                v-if="tab_id == 'balance' && sub_tab_id == 'balance'"
              >
                <BalancePayment />
                <WarMes />
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>

    <!-- 임대 -->
    <v-container class="pd0 ps-re" v-else>
      <div class="tabs-box">
        <v-tabs
          color="black"
          height="37px"
          style="font-size: 14px"
          v-model="tab"
          background-color="white"
        >
          <v-tab :class="tab_class" :ripple="false">계약내용 조회</v-tab>
          <v-tab :class="tab_class" :ripple="false">보증금·임대료 조회</v-tab>
          <v-tab
            :class="tab_class"
            :ripple="false"
            v-if="home.contract_reservation.CHANGE"
            >계약변경 방문예약</v-tab
          >
          <v-tab
            :class="tab_class"
            :ripple="false"
            v-if="home.contract_reservation.RENEW"
            >계약갱신 방문예약</v-tab
          >
          <v-tab
            :class="tab_class"
            :ripple="false"
            v-if="home.contract_reservation.TERMINATE_BEFORE_CONTRACT"
            >계약 해지 방문예약</v-tab
          ><v-tab
            :class="tab_class"
            :ripple="false"
            v-if="home.contract_reservation.TERMINATE_BEFORE_PERIOD"
            >중도 퇴거 신청</v-tab
          >
          <v-tab
            :class="tab_class"
            :ripple="false"
            v-if="home.contract_reservation.TERMINATE_ON_PERIOD"
            >만기 퇴거 신청</v-tab
          >
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div class="tab-contents">
              <div class="web-contbox" v-if="tab_id == 'status'">
                <StatusForRent />
                <WarMes />
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="tab-contents">
              <div v-if="tab_id == 'receipt'">
                <div class="tabs-box">
                  <ul class="tab-menu">
                    <li
                      v-bind:class="{
                        active2: sub_tab_id == 'deposit',
                      }"
                    >
                      <router-link to="/contractInfo/receipt/deposit"
                        >보증금 납부/미납 내역</router-link
                      >
                    </li>
                    <li
                      v-bind:class="{
                        active2: sub_tab_id == 'leasePayment',
                      }"
                    >
                      <router-link to="/contractInfo/receipt/leasePayment"
                        >임대료 납부/미납 내역</router-link
                      >
                    </li>
                  </ul>
                </div>
                <div class="tab-contents">
                  <div v-if="sub_tab_id == 'deposit'">
                    <Deposit />
                    <WarMes />
                  </div>
                  <div v-if="sub_tab_id == 'leasePayment'">
                    <LeasePayment />
                    <WarMes />
                  </div>
                </div>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item v-if="home.contract_reservation.CHANGE">
            <div class="tab-contents">
              <div class="web-contbox" v-if="tab_id == 'request'">
                <ContractChange />
                <WarMes />
              </div>
            </div>
          </v-tab-item>
          <v-tab-item v-if="home.contract_reservation.RENEW">
            <div class="tab-contents">
              <div class="web-contbox" v-if="tab_id == 'renew'">
                <ContractRenewal />
                <WarMes />
              </div>
            </div>
          </v-tab-item>
          <v-tab-item
            v-if="home.contract_reservation.TERMINATE_BEFORE_CONTRACT"
          >
            <div class="tab-contents">
              <div class="web-contbox" v-if="tab_id == 'cancel'">
                <ContractCancel />
                <WarMes />
              </div>
            </div>
          </v-tab-item>
          <v-tab-item v-if="home.contract_reservation.TERMINATE_BEFORE_PERIOD">
            <div class="tab-contents">
              <div class="web-contbox" v-if="tab_id == 'before'">
                <LeaveBefore />
                <WarMes />
              </div>
            </div>
          </v-tab-item>
          <v-tab-item v-if="home.contract_reservation.TERMINATE_ON_PERIOD">
            <div class="tab-contents">
              <div class="web-contbox" v-if="tab_id == 'on'">
                <LeaveOn />
                <WarMes />
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </v-container>
</template>

<style>
.sel-cus .v-text-field__details {
  min-height: auto;
}
.sel-cus .v-input__slot {
  margin: 0;
}
.sel-cus .v-input input {
  max-height: 40px;
}
.sel-cus .v-input .v-label {
  text-align: center;
}
.sel-cus .v-select__selection {
  max-width: 100%;
  width: 100%;
  padding-left: 28px;
  text-align: center;
}
.v-slide-group__prev {
  display: none !important;
}
</style>

<script>
import StatusForSell from "@/components/Contract/StatusForSell";
import Options from "@/components/Contract/Options";
import StatusForRent from "@/components/Contract/StatusForRent";
import LeasePayment from "@/components/Contract/LeasePayment";
import Payment from "@/components/Contract/Payment";
import OptionPayment from "@/components/Contract/OptionPayment";
import Loan from "@/components/Contract/Loan";
import LoanInterest from "@/components/Contract/LoanInterest";
import BalancePayment from "@/components/Contract/BalancePayment";
import Deposit from "@/components/Contract/Deposit";
import ContractChange from "@/components/Contract/ContractChange";
import ContractRenewal from "@/components/Contract/ContractRenewal";
import ContractCancel from "@/components/Contract/ContractCancel";
import LeaveBefore from "@/components/Contract/LeaveBefore";
import LeaveOn from "@/components/Contract/LeaveOn";
import WarMes from "@/components/Contract/WarningMess";

import Weblogo from "@/assets/images/common/main-logo.svg";
import Back from "@/assets/images/icon/icon-back.svg";

export default {
  components: {
    StatusForSell, //계약 현황 - 분양계약정보.
    Options, //계약 현황 - 분양 별도계약품목
    Payment, //수납현황 - 중도금납부조회,
    OptionPayment, //수납현황 - 별도품목납부조회
    Loan,
    LoanInterest,
    BalancePayment,
    Deposit,
    StatusForRent, // 계약 현황 - 임대 계약정보
    LeasePayment, // 보증금/임대료 - 임대료 납부/미납 내역
    ContractChange,
    ContractRenewal,
    ContractCancel,
    LeaveBefore,
    LeaveOn,
    Back,
    WarMes, // 주의사항 메세지
    Weblogo,
  },
  name: "ContractInfo",
  data() {
    return {
      tab: 0,
      tab_class: "tab-sizebox",

      tab_array: ["status", "payment", "loan", "interest", "balance"],
      path: [
        "/contractInfo/status/sell",
        "/contractInfo/payment/payment",
        "/contractInfo/loan/loan",
        "/contractInfo/interest/interest",
        "/contractInfo/balance/balance",
      ],
    };
  },
  watch: {
    $route(to, from) {
      this.tabSync(to);
    },
    tab(val) {
      if (this.home.is_rent) {
        this.moveTo(this.rent_path[val]);
      } else {
        this.moveTo(this.path[val]);
      }
    },
  },
  methods: {
    getContractVillages() {},
    moveTo(path) {
      this.$router.push({ path: path });
    },
    tabSync(to) {
      if (this.home.is_rent) {
        this.tab = this.tab_rent_array.indexOf(to.params.tab_id);
      } else {
        this.tab = this.tab_array.indexOf(to.params.tab_id);
      }
    },
  },
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
    tab_id() {
      return this.$route.params.tab_id;
    },
    sub_tab_id() {
      return this.$route.params.sub_tab_id;
    },
    tab_rent_array() {
      var array = ["status", "receipt"];

      if (this.home.contract_reservation.CHANGE) array.push("request");
      if (this.home.contract_reservation.RENEW) array.push("renew");
      if (this.home.contract_reservation.TERMINATE_BEFORE_CONTRACT)
        array.push("cancel");
      if (this.home.contract_reservation.TERMINATE_BEFORE_PERIOD)
        array.push("before");
      if (this.home.contract_reservation.TERMINATE_ON_PERIOD) array.push("on");

      return array;
    },
    rent_path() {
      var array = [
        "/contractInfo/status/rent",
        "/contractInfo/receipt/deposit",
      ];

      if (this.home.contract_reservation.CHANGE)
        array.push("/contractInfo/request/change");
      if (this.home.contract_reservation.RENEW)
        array.push("/contractInfo/renew/renew");
      if (this.home.contract_reservation.TERMINATE_BEFORE_CONTRACT)
        array.push("/contractInfo/cancel/cancel");
      if (this.home.contract_reservation.TERMINATE_BEFORE_PERIOD)
        array.push("/contractInfo/before/leave");
      if (this.home.contract_reservation.TERMINATE_ON_PERIOD)
        array.push("/contractInfo/on/leave");

      return array;
    },
  },
  created() {
    this.getContractVillages();
    this.tabSync(this.$route);
  },
};
</script>
