<template>
  <v-container class="pd16">
    <div class="sel-inbtn type2">
      <div :class="code == 'PARCELLING' ? 'left' : ''">
        <v-text-field
          v-model="search"
          class="sel-cus"
          label="검색"
          hide-details
          single-line
          name="name"
          id="id"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <div class="right" v-if="code == 'PARCELLING'">
        <a
          href="#n"
          @click="setFilter('PARCELLING')"
          :class="[{ active: activeMenu === 'PARCELLING' }]"
          >분양중</a
        >
        <a
          href="#n"
          @click="setFilter('PARCELLING_EXPECTED')"
          :class="[{ active: activeMenu === 'PARCELLING_EXPECTED' }]"
          >분양예정</a
        >
      </div>
    </div>
    <ul class="outcom-list">
      <li v-for="village in filtered_data" :key="village.id">
        <div class="img-box" @click="moveTo(village)">
          <div class="circle_aria" v-if="code == 'CONSTRUCTION'">
            <v-progress-circular
              :size="80"
              :width="15"
              :value="village.info.progress ? village.info.progress : 0"
              color="primary"
              >{{ village.info.progress ? village.info.progress : "0" }}%
            </v-progress-circular>
          </div>
          <span v-if="village.is_pre_request" class=""> 사전청약중 </span>
          <span
            v-else-if="code != 'CONSTRUCTION'"
            :class="village.status_code_code == 'PARCELLING' ? '' : 'type2'"
          >
            {{ village.info.status }}
          </span>
          <a
            href="#n"
            class="bookmark"
            @click.stop="clickInterested(village.id)"
          >
            <bookmarkActive v-if="checkInterested(village.id)" />
            <bookmark v-else />
          </a>
          <a
            href="#n"
            class="share"
            @click.stop="sharing(village.id, village.display_name, village)"
          >
            <Iconshare />
          </a>
          ><v-img
            class="img-btn"
            :lazy-src="
              require('@/assets/images/lazysrc/Village_Placeholder.png')
            "
            :src="
              village.image_url
                ? village.image_url
                : require('@/assets/images/lazysrc/Village_Placeholder.png')
            "
            contain
            max-width="100%"
          ></v-img>
        </div>
        <div class="text-box">
          <strong>{{ village.display_name }}</strong>
          <ul class="list-type done">
            <li>
              <strong>위치</strong>
              <p>{{ village.address || "-" }}</p>
            </li>
            <li>
              <strong>세대</strong>
              <p>{{ village.info.home_count || "-" }}</p>
            </li>
            <li>
              <strong>주택형</strong>
              <p>{{ village.info.room_type || "-" }}</p>
            </li>

            <!-- 분양단지&공사단지 -->
            <li
              class="text-center"
              style="height: 25px"
              v-if="code != 'LIVING'"
            >
              <v-btn
                elevation="0"
                class="fts16 ftw7 mw-700 pwidth47 mr5"
                min-height="35"
                :href="`tel:${village.contact}`"
                :disabled="!village.contact"
                >{{ village.contact || "-" }}</v-btn
              >

              <!-- 공사단지는 린 상세페이지로 연동 -->
              <v-btn
                v-if="code == 'CONSTRUCTION'"
                elevation="0"
                class="fts16 ftw7 mw-700 pwidth47"
                min-height="35"
                @click="moveToDetail(village)"
                >홈페이지</v-btn
              >
              <v-btn
                v-else
                elevation="0"
                class="fts16 ftw7 mw-700 pwidth47"
                min-height="35"
                @click="moveTo(village)"
                >홈페이지</v-btn
              >
              <!-- :disabled="village.status_code_code != 'PARCELLING'" -->
            </li>
            <li
              class="mt10 mb10 text-center"
              style="height: 25px"
              v-if="code != 'LIVING'"
            >
              <v-btn
                elevation="0"
                class="fts16 ftw7 mw-700 pwidth47 mr5 mt5"
                min-height="35"
                @click.stop="clickInterested(village.id)"
                :disabled="village.id == 4489"
                >관심고객등록</v-btn
              >
              <v-btn
                elevation="0"
                class="fts16 ftw7 mw-700 pwidth47 mt5"
                min-height="35"
                @click="moveToReservation(village)"
                :disabled="!village.visit_reservation_on || village.id == 4489"
                >방문예약</v-btn
              >
            </li>

            <!-- 입주단지 -->
            <li
              class="text-center mt10 mb10"
              style="height: 25px"
              v-if="code == 'LIVING'"
            >
              <v-btn
                elevation="0"
                class="fts16 ftw7 mw-700 pwidth47 mr5"
                min-height="35"
                @click="moveTo(village)"
                >홈페이지</v-btn
              ><v-btn
                elevation="0"
                class="fts16 ftw7 mw-700 pwidth47"
                min-height="35"
                @click="moveToDetail(village)"
                >상세보기</v-btn
              >
              <!-- :disabled="village.status_code_code != 'PARCELLING'" -->
            </li>
          </ul>
        </div>
      </li>
      <li class="no-list" v-if="isLoading">
        <p>Loading..</p>
      </li>
      <li class="no-list" v-else-if="!filtered_data.length">
        <p>현재 {{ no_text }} 없습니다.</p>
      </li>
    </ul>

    <v-dialog v-model="interestDialog" max-width="290">
      <v-card>
        <v-card-title class="justify-center"> </v-card-title>
        <v-card-text class="text-left ftw7">
          로그인 후 사용 가능합니다.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="interestDialog = false">
            취소
          </v-btn>
          <v-btn color="primary darken-1" text @click="goLogin()">
            로그인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Iconshare from "@/assets/images/icon/icon-share.svg";
import bookmark from "@/assets/images/icon/icon-bookmark.svg";
import bookmarkActive from "@/assets/images/icon/icon-bookmark_on.svg";
import { postInterestedCustomer, delInterestedCustomer } from "@/api/index.js";

export default {
  components: {
    Iconshare,
    bookmark,
    bookmarkActive,
  },
  props: ["code", "data", "isLoading"],
  name: "VillageInfoList",
  data() {
    return {
      search: "",
      filter: "",
      interestDialog: false,
      parcelling_active: true,
      activeMenu: "ALL",
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    interestedList() {
      return this.$store.state.data.interestedList;
    },
    searched_data() {
      return this.data.filter((item) => {
        return (
          item.display_name.includes(this.search) ||
          item.address.includes(this.search)
        );
      });
    },
    filtered_data() {
      if (!this.code.includes("PARCELLING")) return this.searched_data;
      if (!this.filter) return this.searched_data;

      return this.searched_data.filter((item) => {
        return item.sale_type == this.filter;
      });
    },
    no_text() {
      return {
        PARCELLING: "분양 및 분양예정중인 단지가",
        // PARCELLING_EXPECTED:'분양예정중인 단지가',
        CONSTRUCTION: "공사중인 단지가",
        LIVING: "입주중인 단지가",
      }[this.code];
    },
  },
  methods: {
    checkInterested(id) {
      let interestedId = 0;
      this.interestedList.forEach((item) => {
        if (item.village == id) {
          interestedId = item.id;
        }
      });
      return interestedId;
    },
    clickInterested(id) {
      let interestedId = this.checkInterested(id);

      if (!this.user) {
        // 비회원
        this.interestDialog = true;
      } else if (interestedId) {
        // 삭제
        delInterestedCustomer(interestedId).then((res) => {
          this.$store.dispatch("GET_INTERESTED_LIST");
          this.$store.commit("SUCCESS", "관심단지가 취소 되었습니다.");
        });
      } else {
        // 추가
        postInterestedCustomer(this.user.id, id).then((res) => {
          this.$store.dispatch("GET_INTERESTED_LIST");
          this.$store.commit("SUCCESS", "관심단지로 등록 되었습니다.");
        });
      }
    },
    goLogin() {
      this.$router.push({ name: "login" });
    },
    moveTo(village) {
      if (village.kind_code_code == "FOR_SELL" && village.homepage) {
        window.open(village.homepage);
      } else {
        this.$router.push({
          name: "villageInfo",
          params: { id: village.id },
        });
      }
    },
    moveToDetail(village) {
      if (village.kind_code_code == "FOR_SELL" && village.homepage_detail) {
        window.open(village.homepage_detail);
      } else if (village.kind_code_code == "FOR_SELL" && village.homepage) {
        window.open(village.homepage);
      } else {
        this.$router.push({
          name: "villageInfo",
          params: { id: village.id },
        });
      }
    },
    moveToReservation(village) {
      this.$router.push({
        name: "visitReservationForm",
        params: {
          id: 0,
          selectVillageId: village.id,
          village_name: village.village_name,
        },
      });
    },
    setFilter(code) {
      if (code === this.activeMenu) {
        this.activeMenu = "ALL";
        this.filter = "";
      } else {
        this.activeMenu = code;
        this.filter = code;
      }
    },
    sharing(id, display_name, village) {
      let val = "";
      if (village.kind_code_code == "FOR_SELL" && village.homepage) {
        val = village.homepage;
      } else {
        val = window.location.href.replace(
          "/villageInfoList#n",
          "/villageInfo/" + id
        );
      }
      const element = document.createElement("textarea");
      element.value = val;
      element.setAttribute("readonly", "");
      element.style.position = "absolute";
      element.style.left = "-9999px";
      document.body.appendChild(element);
      element.select();

      var meta = document.createElement("meta");
      meta.setAttribute("property", "og:title");
      meta.setAttribute("content", "display_name");
      document.head.appendChild(meta);

      if (navigator.share) {
        // 공유박스가 뜰 때
        navigator.share({
          url: val,
          title: display_name,
        });
      } else {
        // 공유박스가 안 뜬다면 flutter sharing 호출
        try {
          window.flutter_inappwebview
            .callHandler("doShare", display_name, val)
            .then(function (result) {
              console.log(
                "@@@ flutterInAppWebViewPlatformReady doShare  result: ",
                result
              );
            });
        } catch (e) {
          // 그래도 안 된다면 공유복사~
          var returnValue = document.execCommand("copy");
          if (!returnValue) {
            throw new Error("copied nothing");
          } else {
            this.$store.commit("SUCCESS", "공유 링크가 복사되었습니다.");
          }
        }
      }

      document.head.removeChild(meta);
      document.body.removeChild(element);
    },
    mapLink(map_link) {
      window.open(map_link);
    },
  },
};
</script>
