<template>
  <v-container class="vh-footer pd20 lynn-web-in overout bg-gray">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.push({ name: 'dwelling' })" class="back">
        <Back />
      </a>
      <strong class="lo-title">관리비</strong>
    </div>
    <v-card
      max-width="750"
      class="mx-auto nops bg-gray"
      style="border-radius: 0"
    >
      <v-card-text class="pd0" v-if="!fee[0]">
        아직 관리사무소에서 등록한 관리비 내역이 없습니다.
      </v-card-text>
      <v-card-text class="pd0" v-if="fee[0]">
        <div class="exp-pointbox">
          <div class="expp-month">
            <a @click="setDate(-1)" class="expp-prev"><Prev /></a>
            <strong>{{ thisYear }}년 {{ thisMonth }}월</strong>
            <a @click="setDate(1)" class="expp-next"><Next /></a>
          </div>
          <div class="expp-cont" v-if="!is_show">
            <div class="expp-top">관리비 데이터가 없습니다.</div>
          </div>
          <div class="expp-cont" v-if="is_show">
            <div class="expp-top">
              <strong>{{ fee[0].amount | comma }}</strong>
              <p>
                {{ thisYear }}.{{ thisMonth + 1 }}.{{
                  fee[0].due_date == 31 ? lastDate : fee[0].due_date
                }}
                까지 납부
              </p>
            </div>
            <div class="expp-bottom">
              <div class="expp-pmonth" v-if="fee[0].diff_to_last_month">
                <strong>전월 비교</strong>
                <p class="up" v-if="fee[0].diff_to_last_month > 0">
                  +{{ Math.abs(fee[0].diff_to_last_month) | comma }}원
                </p>
                <p class="down" v-if="fee[0].diff_to_last_month < 0">
                  -{{ Math.abs(fee[0].diff_to_last_month) | comma }}원
                </p>
              </div>
              <div class="expp-dmonth">
                <strong>동일면적 평균 비교</strong>
                <p class="down">{{ diff_to_same_area_per }}</p>
              </div>
            </div>
            <a @click="goDetail()" class="expp-detail">상세내역 보기</a>
          </div>
        </div>
        <strong class="t-cont-title ftw7 mb10" v-if="is_show"
          >관리비 통계</strong
        >
        <div class="exp-graphbox" v-if="is_show">
          <div class="expg-linebox" v-if="fee[12]">
            <strong class="expg-title">전년동월 비교</strong>
            <div class="expg-top">
              <strong>
                <span class="left">올해 {{ thisMonth }}월</span>
                <span class="price">{{ fee[0].amount | comma }}</span>
              </strong>
              <div
                class="proline"
                :style="`width: ${
                  fee[0].amount > fee[12].amount
                    ? 100
                    : (fee[0].amount / fee[12].amount) * 100
                }%`"
              ></div>
            </div>
            <div class="expg-bottom">
              <div
                class="proline"
                :style="`width: ${
                  fee[12].amount > fee[0].amount
                    ? 100
                    : (fee[12].amount / fee[0].amount) * 100
                }%`"
              ></div>
              <strong>
                <span class="left">작년 {{ thisMonth }}월</span>
                <span class="price" v-if="fee[12]">{{
                  fee[12].amount | comma
                }}</span>
              </strong>
            </div>
          </div>
          <div class="expg-gpbox">
            <strong class="expg-title">최근 3개월</strong>
            <ul class="expg-gpcont">
              <li v-for="(f, index) in feeThree" :key="f.year_month + index">
                <div class="graph">
                  <span
                    :style="`height: ${(f.amount / max_three) * 100}%`"
                    :class="{ this: index == 2 }"
                  ></span>
                </div>
                <strong>{{ Number(f.year_month.substr(4, 2)) }}월</strong>
                <p>{{ f.amount | comma }}</p>
              </li>
            </ul>
          </div>
          <a
            href="#n"
            class="expg-detail"
            v-on:click="onChange = !onChange"
            v-if="onChange == false && fee[1]"
            >최근 12개월 내역 보기</a
          >
          <div class="expg-yaerbox" v-if="onChange == true">
            <strong class="expg-title">최근 12개월</strong>

            <!-- sparkline  -->
            <v-sheet class="v-sheet--offset mx-auto">
              <v-sparkline
                :value="feeReverseAmount"
                color="#F36628"
                line-width="2"
                label-size="10"
                padding="16"
              >
                <template v-slot:label="item" fill="black">
                  {{ feeReverseLabels[item.index].month }}
                </template>
              </v-sparkline>
            </v-sheet>
            <!-- sparkline 끝 -->

            <div class="expg-yeartable">
              <div class="left">
                <table>
                  <colgroup>
                    <col width="50%" />
                    <col width="50%" />
                  </colgroup>
                  <tr v-for="f in feeLeft" :key="f.year_month">
                    <th>
                      {{
                        f.year_month.substr(0, 4) +
                        "." +
                        Number(f.year_month.substr(4, 2))
                      }}
                    </th>
                    <td>{{ f.amount | comma }}</td>
                  </tr>
                </table>
              </div>
              <div class="right">
                <table>
                  <colgroup>
                    <col width="50%" />
                    <col width="50%" />
                  </colgroup>
                  <tr v-for="f in feeRight" :key="f.year_month">
                    <th>
                      {{
                        f.year_month.substr(0, 4) +
                        "." +
                        Number(f.year_month.substr(4, 2))
                      }}
                    </th>
                    <td>{{ f.amount | comma }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <a href="#n" class="expg-detail" v-on:click="onChange = !onChange"
              >접기</a
            >
          </div>
        </div>
      </v-card-text>
    </v-card>

    <div class="text-center" v-if="loadingBar">
      <v-progress-circular
        fullscreen
        indeterminate
        color="red"
        style="position: fixed; top: calc(50% - 16px); left: calc(50% - 16px)"
      ></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import Prev from "@/assets/images/Expenses/prev.svg";
import Next from "@/assets/images/Expenses/next.svg";
import { getFee, getFeeDetail, getMonth } from "@/api/index";
import moment from "moment";

export default {
  name: "MgmtFee",
  components: {
    Back,
    Weblogo,
    Prev,
    Next,
  },
  data() {
    return {
      onChange: false,
      fee: {},
      thisYear: "",
      thisMonth: "",
      maxYear: "",
      maxMonth: "",
      loadingBar: false,
      is_show: true,
    };
  },
  computed: {
    home() {
      return this.$store.getters.getTenantHome;
    },
    diff_to_same_area_per() {
      let avg = this.fee[0].amount - this.fee[0].diff_to_same_area;
      let per = (Math.abs(this.fee[0].diff_to_same_area) / avg) * 100;

      if (this.fee[0].diff_to_same_area > 0) {
        per = per.toFixed(2) + "% 높음";
      } else {
        per = per.toFixed(2) + "% 낮음";
      }
      return per;
    },
    feeReverse() {
      return this.fee.slice(1).reverse();
    },
    feeReverseAmount() {
      return Array.from(this.feeReverse, (item) => item.amount);
    },
    feeReverseLabels() {
      return Array.from(this.feeReverse, (item) => ({
        month: Number(item.year_month.substr(4, 2)),
        year: item.year_month.substr(0, 4),
      }));
    },
    feeLeft() {
      return this.feeReverse.slice(0, 6);
    },
    feeRight() {
      return this.feeReverse.slice(6);
    },
    feeThree() {
      return this.fee.slice(0, 3).reverse();
    },
    max_all() {
      // 최근 12개월 최대 금액
      var max = this.fee.reduce((previous, current) => {
        return previous.amount > current.amount ? previous : current;
      });
      return max.amount;
    },
    max_three() {
      // 최근 3개월 최대 금액
      var max = this.fee.slice(0, 3).reduce((previous, current) => {
        return previous.amount > current.amount ? previous : current;
      });
      return max.amount;
    },
    lastDate() {
      // 말일 계산
      let lastDate = new Date(this.thisYear, this.thisMonth, 0);
      return lastDate.getDate();
    },
  },
  methods: {
    getFeeList() {
      this.loadingBar = true;
      getFee(this.home.id, this.thisYear, this.thisMonth)
        .then((result) => {
          //console.log(result.data);
          this.loadingBar = false;

          this.fee = result.data;

          // 조회한 년,월과 조회된 데이터의 년,월이 안 맞을 경우 안 보여줌 !
          if (
            this.fee[0].year_month.substr(0.4) != this.thisYear &&
            Number(this.fee[0].year_month.substr(4, 2)) != this.thisMonth
          ) {
            this.is_show = false;
          } else {
            this.is_show = true;
          }
        })
        .catch((err) => {
          this.$store.commit("ERROR", "데이터가 없습니다.");
          this.loadingBar = false;
          this.setDate(1);
          return false;
        });
    },
    setDate(add) {
      let setDate = new Date(this.thisYear, this.thisMonth - 1);
      setDate.setMonth(setDate.getMonth() + add);

      let maxDate = new Date(this.maxYear, this.maxMonth - 1);

      if (setDate > maxDate) {
        this.$store.commit("ERROR", "데이터가 없습니다.");
        return false;
      }

      this.thisYear = setDate.getFullYear();
      this.thisMonth = setDate.getMonth() + 1;
      this.getFeeList();
    },
    goDetail() {
      this.$router.push({
        name: "mgmtFeeDetail",
        params: { year: this.thisYear, month: this.thisMonth },
      });
    },
  },
  created() {
    getMonth(this.home.id).then((res) => {
      if (this.$route.params.year) {
        this.thisYear = this.$route.params.year;
        this.thisMonth = this.$route.params.month;
      } else {
        this.thisYear = res.data.year;
        this.thisMonth = res.data.month;
      }
      this.maxYear = res.data.year;
      this.maxMonth = res.data.month;
      this.getFeeList();
    });
  },
};
</script>

<style>
.expg-yaerbox svg g {
  fill: #000000 !important;
}
</style>
