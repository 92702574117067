<template>
  <v-container class="vh-footer bg-gray ps-re lynn-mobile lynn-web" fluid>
    <v-container class="pd0">
      <div class="slide-box">
        <a href="#n" @click="getPacellingCalendarAPI(year - 1)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
          >
            <g
              id="그룹_1299"
              data-name="그룹 1299"
              transform="translate(310 -285) rotate(90)"
            >
              <rect
                id="사각형_647"
                data-name="사각형 647"
                width="36"
                height="36"
                transform="translate(285 274)"
                fill="none"
              />
              <path
                id="패스_242"
                data-name="패스 242"
                d="M-237.25,395.833l-8,8-8-8"
                transform="translate(548.25 -107.833)"
                fill="none"
                stroke="#3c3c3c"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </g>
          </svg>
        </a>
        <a href="#n" class="text">{{ year }} 분양 프로젝트</a>
        <a href="#n" @click="getPacellingCalendarAPI(year + 1)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
          >
            <g
              id="그룹_381"
              data-name="그룹 381"
              transform="translate(-274 321) rotate(-90)"
            >
              <rect
                id="사각형_647"
                data-name="사각형 647"
                width="36"
                height="36"
                transform="translate(285 274)"
                fill="none"
              />
              <path
                id="패스_242"
                data-name="패스 242"
                d="M-237.25,395.833l-8,8-8-8"
                transform="translate(548.25 -107.833)"
                fill="none"
                stroke="#3c3c3c"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </g>
          </svg>
        </a>
      </div>
    </v-container>
    <v-container class="pd16">
      <ul class="midate-list">
        <li
          v-for="month in 12"
          :key="month"
          v-show="filter_by_month(month).length"
        >
          <strong class="month">{{ month }}월</strong>
          <ul class="ml-box">
            <li
              v-for="village in filter_by_month(month)"
              @click="moveTo(village)"
              :key="village.id"
              v-bind:class="{ single: filter_by_month(month).length == 1 }"
            >
              <strong :class="village.class_type">{{ village.text }}</strong>
              <p>{{ village.display_name }}</p>
            </li>
          </ul>
        </li>

        <ul class="ml-box" v-if="!pacellingList.length">
          <li class="no-list">
            <p>{{ year }}년 계획된 일정이 없습니다.</p>
          </li>
        </ul>
      </ul>
    </v-container>
  </v-container>
</template>

<script>
import { getPacellingCalendar } from "@/api/index.js";

export default {
  name: "parcellingCalendar",
  data() {
    return {
      year: new Date().getFullYear(),
      pacellingList: [],
    };
  },
  computed: {},
  methods: {
    // APIs
    getPacellingCalendarAPI(year) {
      if (year < 2019) return;

      this.year = year;
      getPacellingCalendar(`year=${this.year}`).then((res) => {
        this.pacellingList = res.data.data;
      });
    },
    // Functions
    moveTo(village) {
      if (village.kind_code_code == "FOR_SELL" && village.homepage) {
        window.open(village.homepage);
      } else {
        this.$router.push({
          name: "villageInfo",
          params: { id: village.id },
        });
      }
    },

    // Utils
    filter_by_month(month) {
      return this.pacellingList.filter((item) => {
        return item.month == month;
      });
    },
  },
  created() {
    this.getPacellingCalendarAPI(this.year);
  },
};
</script>
