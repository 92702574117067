<template>
  <v-dialog
    v-model="dialog"
    persistent
    :overlay="true"
    max-width="500px"
    height="500px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title>
        {{ verification.title }}
      </v-card-title>
      <v-card-text v-if="!only_verified_name">
        <v-checkbox
          v-if="verification.verified_name_type"
          v-model="form.is_contractor"
          label="계약자(계약/납부 현황 조회)"
          color="#F36628"
        ></v-checkbox>
        <v-checkbox
          v-if="verification.verified_name_type"
          v-model="form.is_tenant"
          label="입주자(스마트홈/린라이프 이용)"
          color="#F36628"
          hide-details
        ></v-checkbox>
        <v-card-text class="fts12 text-left pd0 mt-2">
          • 계약자이며 입주자이신 분은 모두 선택하세요.<br />
          • 세입자 또는 가족은 입주자만 선택하세요.
        </v-card-text>

        <v-autocomplete
          :items="villageList"
          item-text="display_name"
          item-value="id"
          v-model="form.village_id"
          label="단지"
          no-data-text="해당하는 단지가 존재하지 않습니다."
        ></v-autocomplete>
        <v-row>
          <v-col>
            <v-text-field v-model="form.dong_no" label="동"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="form.ho_no" label="호"></v-text-field>
          </v-col>
        </v-row>
        <v-slide-y-transition hide-on-leave>
          <v-row v-if="signupCheckWarn" class="body-1">
            {{ signupCheckWarn }}
          </v-row>
        </v-slide-y-transition>
        <v-list>
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-content>
                <div class="text-left color-5 fts14">
                  ※ 아파트 외 동,호수 입력방법
                </div>
              </v-list-item-content>
            </template>

            <v-list-item>
              <v-list-item-content>
                <div class="fts13 text-left color-6">
                  지하층일 경우 입력방식 : B+층+호실<br />
                  예 : B3층 101호 -> B3101
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-center pb20">
        <v-btn
          class="fts18 ftw7 pwidth48 color-2 bgc-4"
          min-height="50"
          @click="close(forced)"
        >
          뒤로가기
        </v-btn>
        <v-btn
          color="primary"
          class="fts18 ftw7 pwidth48"
          min-height="50"
          @click="checkSignup()"
        >
          본인인증하기
        </v-btn>
      </v-card-actions>
      <div class="text-center" v-if="loadingBar">
        <v-progress-circular
          fullscreen
          indeterminate
          color="red"
          style="position: fixed; top: calc(50% - 16px); left: calc(50% - 16px)"
        ></v-progress-circular>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { getVillageList, updateUserData } from "@/api/index.js";
import { signupcheck } from "@/api/sign";

import { loadNICEMain } from "@/api/NICE_id.js";

export default {
  name: "ContractVerification",
  props: ["forced"],
  data() {
    return {
      dialog: false,
      loadingBar: false,
      nice_form: {
        enc_data: "",
        returnMsg: "",
      },

      villageList: [],
      form: {
        is_tenant: false,
        is_contractor: false,
        update_type: "HOME",
        village_id: "",
        dong_no: "",
        ho_no: "",
      },
    };
  },
  computed: {
    verification() {
      return this.$store.state.data.verification;
    },
    verification_dialog() {
      return this.verification.dialog;
    },
    only_verified_name() {
      return this.verification.only_verified_name;
    },
    user() {
      return this.$store.getters.getUser;
    },
    homeList() {
      return this.$store.getters.getHomeList;
    },
    signupCheckWarn() {
      return this.$store.state.info.signupcheckwarn;
    },
    is_completed() {
      if (this.only_verified_name) {
        return true;
      }

      if (this.verification.verified_name_type) {
        return (
          this.form.village_id &&
          this.form.dong_no &&
          this.form.ho_no &&
          !this.signupCheckWarn &&
          (this.form.is_contractor || this.form.is_tenant)
        );
      } else {
        return (
          this.form.village_id &&
          this.form.dong_no &&
          this.form.ho_no &&
          !this.signupCheckWarn
        );
      }
    },
  },
  watch: {
    verification_dialog(val) {
      if (val && !this.villageList.length) {
        this.getvillageListAPI();
      }
      if (val && this.only_verified_name) {
        this.LoadMain();
      } else {
        this.dialog = val;
      }
    },
  },
  methods: {
    // APIs
    LoadMain() {
      this.loadingBar = true;
      this.form.is_test = this.verification.is_test;

      if (this.verification.is_test) {
        updateUserData(this.user.id, this.form).then((re) => {
          this.loadingBar = false;
          this.$store.dispatch("USER_REFRESH");
          this.form.redirect = "mypage";
          this.$store.commit(
            "SUCCESS",
            "계약자 인증 및 세대등록이 완료되었습니다."
          );
          this.close();
        });
      } else {
        this.form.redirect = this.verification.redirect;
        this.form.only_verified_name = this.verification.only_verified_name;
        loadNICEMain(this.form)
          .then((re) => {
            this.loadingBar = false;
            this.nice_form = re.data;
            this.OpenPage();
            this.close();
          })
          .catch((er) => {
            this.loadingBar = false;
            this.$store.commit("ERROR", "계약자 인증 호출에 실패하였습니다.");
            this.$store.commit("SET_VERIFICATON", {
              dialog: false,
              redirect: "",
              is_test: false,
            });
          });
      }
    },
    getvillageListAPI() {
      // 단지 정보 세팅
      getVillageList("is_available=true").then((result) => {
        this.villageList = result.data;
        this.villageList = this.villageList.sort(function (a, b) {
          if (a.display_name < b.display_name) {
            return -1;
          }
          if (a.display_name > b.display_name) {
            return 1;
          }
          return 0;
        });
      });
    },
    checkSignup() {
      /*
        1. 해당 단지에 존재하는 동, 호 인지 체크.
        2. 해당 세대에 계약자가 존재하는지 체크.
      */
      let is_my_home = false;
      if (this.homeList) {
        this.homeList.some((item) => {
          if (
            item.is_contractor &&
            Number(item.village) == Number(this.form.village_id) &&
            item.dong_no == this.form.dong_no &&
            item.ho_no == this.form.ho_no
          ) {
            is_my_home = true;
          }
        });
      }

      if (is_my_home) {
        this.$store.commit("INFO_UPDATE", {
          type: "signupcheckwarn",
          text: "",
        });
        this.LoadMain();
      } else {
        if (!this.verification.verified_name_type) {
          this.form.is_contractor = true;
          this.form.is_tenant = false;
        }
        this.signupCheckAPI(this.form);
        // this.$store.dispatch("SIGNUP_CHECK", this.form);
      }
    },
    signupCheckAPI(data) {
      data.is_erp_check = true;
      let status_text = "";
      let home_id = 0;

      if (!(data.village_id && data.dong_no && data.ho_no)) {
        // 정보 입력중...
        status_text = "단지 지정 및 동, 호를 입력하세요.";
        this.$store.commit("INFO_UPDATE", {
          type: "signupcheckwarn",
          text: status_text,
        });
        this.$store.commit("INFO_UPDATE", {
          type: "signupcheckHomeId",
          text: home_id,
        });
      } else {
        signupcheck(data).then((res) => {
          if (res.data.status == 200) {
            status_text = null;
            home_id = res.data.home_id;
            this.LoadMain();
          } else {
            status_text = res.data.status_text;
            this.$store.commit("ERROR", res.data.status_text);
          }
          this.$store.commit("INFO_UPDATE", {
            type: "signupcheckwarn",
            text: status_text,
          });

          this.$store.commit("INFO_UPDATE", {
            type: "signupcheckHomeId",
            text: home_id,
          });
        });
      }
    },

    // Utils
    OpenPage() {
      // window.open(
      //   "",
      //   "_self",
      //   "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
      // );

      let form = document.createElement("form");

      var EncodeData = document.createElement("input");
      EncodeData.type = "hidden";
      EncodeData.name = "EncodeData";
      EncodeData.value = this.nice_form.enc_data;

      var m = document.createElement("input");
      m.type = "hidden";
      m.name = "m";
      m.value = "checkplusSerivce";

      form.action =
        "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
      form.target = "_self";
      form.appendChild(EncodeData);
      form.appendChild(m);

      document.body.appendChild(form);
      form.submit();
    },
    close(forced = false) {
      if (forced) {
        this.$router.push({ name: "mypage" });
      }
      this.$store.commit("SET_VERIFICATON", {
        title: "실명인증",
        dialog: false,
        redirect: "",
        is_test: false,
      });
      this.reset();
    },
    reset() {
      this.dialog = false;

      this.nice_form = {
        enc_data: "",
        returnMsg: "",
      };

      this.form = {
        is_contractor: false,
        is_tenant: false,
        update_type: "HOME",
        village_id: "",
        dong_no: "",
        ho_no: "",
      };
    },
  },
  created() {
    if (this.$route.query.is_not_verified) {
      this.$store.commit("ERROR", "계약정보가 없습니다.");
    }
    this.getvillageListAPI();
    this.close();
  },
};
</script>
