<template>
  <v-container class="vh-footer pd20 lynn-web-in overout">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.push({ name: 'dwelling' })" class="back">
        <Back />
      </a>
      <strong class="lo-title">라운지</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops" style="border-radius: 0">
      <v-card-text class="pd0 pt36">
        <div class="tabs-box full">
          <ul class="tab-menu">
            <li v-bind:class="{ active: openTab === 0 }">
              <a
                href="#n"
                v-on:click="
                  openTab = 0;
                  infiniteId = 1;
                "
                >전체 글</a
              >
            </li>
            <li v-bind:class="{ active: openTab === 1 }">
              <a
                href="#n"
                v-on:click="
                  openTab = 1;
                  infiniteId = 2;
                "
                >내 글</a
              >
            </li>
          </ul>
        </div>
        <div class="sel-inbtn type3 max mt10">
          <v-text-field
            class="sel-cus ft-l"
            style="width: 90%"
            label="검색"
            hide-details
            single-line
            name="name"
            id="id"
            v-model="search_param"
            v-on:keyup.enter="search()"
          ></v-text-field>
          <a @click="search()" class="ft-r">
            <v-icon>mdi-magnify</v-icon>
          </a>
        </div>
        <div class="tab-contents">
          <div v-if="openTab == 0">
            <div class="write-btn">
              <router-link to="/loungeWrite/"><Write /></router-link>
            </div>
            <!-- 전체 글 -->
            <ul class="dan-list">
              <li class="dan-text">
                <p>
                  ※ 게시글에는 작성자의 실명이 공개되며 이는 게시판 악용을
                  방지하기 위함입니다. 부적절한 게시글은 관리자에 의해 임의로
                  삭제될 수 있습니다.
                </p>
              </li>
              <li class="no-list" v-if="boardList.length == 0">
                <a> 게시물이 없습니다. </a>
              </li>
              <li
                v-for="board in boardList"
                :key="board.id"
                :class="[{ 'done-list': board.is_reported }]"
              >
                <div class="icon-box" v-if="board.is_reported"><Done /></div>
                <p v-if="board.is_reported">
                  이 게시글은 10명 이상의 신고접수 또는 아파트 관리 정책에
                  위배되어 관리자에 의해 차단되었습니다.
                </p>

                <router-link
                  :to="{
                    name: 'loungeView',
                    params: { id: board.id, search_param: search_param },
                  }"
                  v-if="!board.is_reported"
                >
                  <strong class="kitbox sm">{{ board.title }}</strong>
                  <div class="bottom-cont">
                    <span class="name pwidth100">{{ board.author_name }}</span>
                    <ul class="cont pwidth50">
                      <li>공감 {{ board.like_count }}</li>
                      <!-- <li>댓글 0</li> -->
                    </ul>
                    <span class="date pwidth50">{{
                      date(board.createdAt)
                    }}</span>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>

          <div v-if="openTab == 1">
            <!-- 내 글 -->
            <ul class="dan-list">
              <li class="no-list" v-if="myBoardList.length == 0">
                <a> 게시물이 없습니다. </a>
              </li>
              <li
                v-for="board in myBoardList"
                :key="board.id"
                :class="[{ 'done-list': board.is_reported }]"
              >
                <div class="icon-box" v-if="board.is_reported"><Done /></div>
                <p v-if="board.is_reported">
                  이 게시글은 10명 이상의 신고접수 또는 아파트 관리 정책에
                  위배되어 관리자에 의해 차단되었습니다.
                </p>

                <router-link
                  :to="{
                    name: 'loungeView',
                    params: { id: board.id, search_param: search_param },
                  }"
                  v-if="!board.is_reported"
                >
                  <strong class="kitbox sm">{{ board.title }}</strong>
                  <div class="bottom-cont">
                    <span class="name pwidth100">{{ board.author_name }}</span>
                    <ul class="cont pwidth50">
                      <li>공감 {{ board.like_count }}</li>
                      <!-- <li>댓글 0</li> -->
                    </ul>
                    <span class="date pwidth50">{{
                      date(board.createdAt)
                    }}</span>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <infinite-loading
            :identifier="infiniteId"
            @infinite="infiniteHandler"
            spinner="waveDots"
          >
            <div slot="no-more">마지막 게시물입니다.</div>
            <div slot="no-results">마지막 게시물입니다.</div>
          </infinite-loading>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import Write from "@/assets/images/lounge/write.svg";
import Done from "@/assets/images/lounge/done.svg";
import { getFreeboardList } from "@/api/index";
import moment from "moment";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "LoungeList",
  components: {
    Back,
    Weblogo,
    Write,
    Done,
    InfiniteLoading,
  },
  data() {
    return {
      openTab: 0,
      boardList: [],
      page: 1,
      search_param: "",
      maxCount: 0,
      infiniteId: 1,
    };
  },
  computed: {
    myBoardList() {
      return this.boardList.filter((item) => {
        return this.user.id == item.author;
      });
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    search() {
      // 초기화
      this.page = 1;
      let query = "page=" + this.page;
      query += "&search_param=" + this.search_param;

      getFreeboardList(query).then((res) => {
        console.log(res);
        this.boardList = res.data.results;
        this.page = this.page + 1;
      });
    },
    infiniteHandler2($state) {
      console.log("infiniteHandler2");
      console.log($state);
    },
    infiniteHandler($state) {
      console.log("infiniteHandler");
      console.log($state);
      let query = this.page == 1 ? "" : "page=" + this.page;

      if (this.search_param != "") {
        query += "&search_param=" + this.search_param;
      }

      getFreeboardList(query)
        .then((res) => {
          console.log(res);

          this.maxCount = res.data.count;
          if (res.data.count != this.boardList.length) {
            if (this.boardList.length == 0) this.boardList = res.data.results;
            else {
              for (var i = 0; i < res.data.results.length; i++) {
                this.boardList.push(res.data.results[i]);
              }
            }
            $state.loaded();
            this.page = this.page + 1;

            if (Math.ceil(this.maxCount / 10) < this.page) {
              $state.complete();
            }
          } else {
            $state.complete();
          }
        })
        .catch((err) => {
          $state.complete();
        });
    },
    date(date) {
      var now = new Date();
      var datetime = new Date(date);

      var day = Math.ceil(
        (datetime.getTime() - now.getTime()) / (1000 * 3600 * 24)
      );

      if (day == 0) {
        var time = new Date(0, 0, 0, 0, 0, 0, now - datetime);
        return time.getHours() + "시간 전 ";
      } else if (day == 1) {
        return "어제";
      } else {
        return moment(datetime).format("YYYY-MM-DD HH:mm");
      }
    },
  },
  created() {
    if (this.$route.params.search_param) {
      this.search_param = this.$route.params.search_param;
    }
    //this.getFreeboard();
  },
};
</script>
