<template>
  <v-container class="bg-gray vh-full ps-re" fill-height fluid>
    <v-row>
      <v-col>
        <v-row>
          <v-col><IntroTitle /></v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col class="mw-400 bAl-center">
            <a click="moveTo">
              <IntroIllust />
            </a>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          rounded
          min-width="280"
          min-height="50"
          elevation="0"
          color="#f36628"
          class="white--text text-h6"
          @click="moveTo"
          >시작하기</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IntroTitle from "@/assets/svgs/intro_title.svg";
import IntroIllust from "@/assets/svgs/intro_illust.svg";
export default {
  name: "IntroPage",
  components: { IntroTitle, IntroIllust },
  data() {
    return {
      link: "http://lynn.co.kr/",
    };
  },
  computed: {
    userState() {
      return this.$store.getters.getUserState;
    },
  },
  methods: {
    moveTo() {
      /*
        1. 'NONMEMBER' - 비휘원
        2. 'MEMBER' - 회원
        3. 'CONTRACTOR' - 계약자
        4. 'TENANT' - 입주자
      */
      if (["NONMEMBER", "MEMBER"].includes(this.userState)) {
        this.$store.state.switchMode = false;
        this.$router.push({ name: "villageInfoList" });
      } else if ("CONTRACTOR" == this.userState) {
        this.$store.state.switchMode = true;
        this.$router.push({ name: "contract" });
      } else if ("TENANT" == this.userState) {
        this.$store.state.switchMode = true;
        this.$router.push({ name: "dwelling" });
      }
    },
  },
  created() {
    this.$store.dispatch("USER_REFRESH");

    // 임대허브 url로 유입된 경우 임대허브 사이트로 이동한다.
    var url = location.href;
    var rentUrl = ["stay.lynn.co.kr", "hub.smartlynn.co.kr"];

    var hubCheck = false;
    // url이 rentUrl에 있는 문자열들을 포함하고 있는지 체크
    for (var i = 0; i < rentUrl.length; i++) {
      if (url.indexOf(rentUrl[i]) > 0) {
        hubCheck = true;
      }
    }

    if (hubCheck) {
      // 임대 허브로 이동
      this.$store.state.hub = true;
      this.$router.push({ name: "hub" });
    } else {
      // 일반 스마트린
      this.$store.state.hub = false;

      // 로그인된 경우, intro화면 거치지 않고 바로 메인 화면으로 간다.
      if (this.$store.state.auth.isLoggedIn) {
        console.log(this.userState);
        if ("TENANT" == this.userState) {
          this.$store.state.switchMode = true;
          this.$router.replace({ name: "dwelling" });
        } else if ("CONTRACTOR" == this.userState) {
          this.$store.state.switchMode = true;
          this.$router.replace({ name: "contract" });
        }
      }
    }
  },
};
</script>
