<template>
  <v-container class="vh-footer pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.push({ name: 'facilityHome' })" class="back">
        <Back />
      </a>
      <strong class="lo-title">{{ facility.name }}</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops">
      <v-card-text class="pl0 pr0">
        <div :class="'fac-background ' + bgTypeByFacility(facility.icon)">
          <div class="text">{{ facility.name }}<br />이용안내</div>
          <div class="icons">
            <library v-if="facility.icon == 'Icons01'" />
            <BookCafe v-else-if="facility.icon == 'Icons02'" />
            <GuestHouse v-else-if="facility.icon == 'Icons03'" />
            <BBQ v-else-if="facility.icon == 'Icons04'" />
            <BikeStation v-else-if="facility.icon == 'Icons05'" />
            <CarShare v-else-if="facility.icon == 'Icons06'" />
            <CafeLynn v-else-if="facility.icon == 'Icons07'" />
            <InsideGym v-else-if="facility.icon == 'Icons08'" />
            <FitnessCenter v-else-if="facility.icon == 'Icons09'" />
            <GolfEX v-else-if="facility.icon == 'Icons10'" />
            <OAzone v-else-if="facility.icon == 'Icons11'" />
            <ToolRental v-else-if="facility.icon == 'Icons12'" />
          </div>
        </div>

        <v-img
            v-if="item.main_image_url"
            class="img-btn mt-3"
            :src="item.main_image_url"
            contain
            max-width="100%"
          ></v-img>

        <div class="fac-box" v-if="info.length">
          <div
            class="fac-cont"
            v-for="data in info"
            :key="'fac-cont-' + data.id"
          >
            <strong>{{ data.title }}</strong>
            <p v-html="data.description"></p>
            <v-img
              v-if="data.image_url"
              class="img-btn"
              :src="data.image_url"
              contain
              max-width="500px"
          ></v-img>
          </div>
        </div>
        <div class="fac-box" v-else>
          <div class="fac-cont">
            <p>등록된 시설 이용안내가 없습니다.</p>
          </div>
        </div>
        <router-link
          :to="path.reservation"
          class="fac-apply"
          v-if="item.is_reservable"
          >신청하기</router-link
        >
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import ApplyBtn from "@/assets/images/dwellingIn/apply-btn.svg";

import GuestHouse from "@/assets/images/dwellingIn/icons_GuestHouse.svg"; // 게스트하우스 bgType3
import BBQ from "@/assets/images/dwellingIn/icons_BBQ.svg"; // 바베큐장 bgType3
import BikeStation from "@/assets/images/dwellingIn/icons_BikeStation.svg"; // 바이크스테이션 bgType3
import CarShare from "@/assets/images/dwellingIn/icons_CarShare.svg"; // 카셰어링 bgType3
import CafeLynn from "@/assets/images/dwellingIn/icons_CafeLynn.svg"; // 카페Lynn bgType3
import InsideGym from "@/assets/images/dwellingIn/icons_InsideGym.svg"; // 실내체육관 bgType4
import FitnessCenter from "@/assets/images/dwellingIn/icons_FitnessCenter.svg"; // 피트니스센터 bgType4
import GolfEX from "@/assets/images/dwellingIn/icons_GolfEX.svg"; // 골프연습장 bgType4
import OAzone from "@/assets/images/dwellingIn/icons_OAzone.svg"; // OA존
import ToolRental from "@/assets/images/dwellingIn/icons_ToolRental.svg"; // 공구대여서비스

import BookCafe from "@/assets/images/dwellingIn/icons_BookCafe.svg"; // 북카페 bgType2
import library from "@/assets/images/dwellingIn/icons_library.svg"; // 입주민독서실 bgType2

import { getFacility } from "@/api/dwelling";

export default {
  name: "FacilityInfo",
  components: {
    Back,
    Weblogo,
    // ApplyBtn,
    GuestHouse,
    BBQ,
    BikeStation,
    CarShare,
    CafeLynn,
    InsideGym,
    FitnessCenter,
    GolfEX,
    OAzone,
    ToolRental,
    BookCafe,
    library
  },
  data() {
    return {
      item: {},
    };
  },
  computed: {
    facility() {
      if (this.item.facility) {
        return this.item.facility;
      }
      return {};
    },
    info() {
      if (this.item.info) {
        return this.item.info;
      }
      return {};
    },
    path() {
      if (this.item.path) {
        return this.item.path;
      }
      return {};
    },
  },
  methods: {
    getFacilityAPI(id) {
      getFacility(id).then((res) => {
        this.item = res.data;
        if (this.item.use_yn == "N") {
          this.noExist();
        }
      });
    },
    bgTypeByFacility(icon) {
      if (
        ["Icons03", "Icons04", "Icons05", "Icons06", "Icons07"].indexOf(icon) >
        -1
      ) {
        return "bgType3";
      } else if (["Icons08", "Icons09", "Icons10"].indexOf(icon) > -1) {
        return "bgType4";
      } else {
        return "bgType2";
      }
    },
    noExist() {
      this.$router.push({ name: "dwelling" });
      this.$store.commit("ERROR", "존재하지 않는 시설 입니다.");
    },
  },
  created() {
    if (this.$route.params.id) {
      this.getFacilityAPI(this.$route.params.id);
    } else {
      this.noExist();
    }
  },
};
</script>
