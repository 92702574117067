<template>
  <v-container class="vh-footer ps-re lynn-mobile lynn-web-in bg-gray" fluid>
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">사전점검 예약</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text>
        <PreCheckReservationForm />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Weblogo from "@/assets/images/common/main-logo.svg";
import { getUserData } from "@/api/index";
import PreCheckReservationForm from "@/components/Reservation/PreCheckReservationForm";
import Back from "@/assets/images/icon/icon-back.svg";

export default {
  name: "PreCheckReservationDetail",
  components: {
    PreCheckReservationForm,
    Back,
    Weblogo,
  },
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {},
  created() {
    if (!this.user) {
      this.$router.push({ name: "login" });
    } else {
      getUserData("field=is_verified_name").then((res) => {
        if (!res.data.is_verified_name) {
          this.$store.commit("SET_USERVERIFICATIONINFO", {
            dialog: true,
            text: "계약자 또는 입주자 인증을 진행하시면 관련 메뉴를 열람하실 수 있습니다.",
            redirect: "/waitRequestForm",
            back: "/mypage",
            only_verified_name: false,
          });
        }
      });
    }
  },
};
</script>
