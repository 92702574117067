<template>
  <div>
    <div class="warmes-box">
      <strong>※ 주의 사항</strong>
      <p>
        본 화면은 계약자님의 이해를 돕기 위한 것으로
        <span class="under">실제와 다를 수 있으며</span>, 법적 책임 소재에 대한
        증빙으로 사용될 수 없습니다. 정확한 계약 내용은 해당 계약서를 확인하여
        주시고, 납부 및 미납 내역은 해당 고객센터에 문의하여 주시기 바랍니다.
      </p>
    </div>
    <ul class="warlist-box">
      <li>
        <a :href="`tel:${home.contact}`">문의: {{ home.contact }}</a>
      </li>
      <li><a @click="goFaq">자주 묻는 질문</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
  name: "WarningMess",
  data() {
    return {};
  },
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
  },
  methods: {
    goFaq() {
      this.$router.push({
        path: `/faq`,
        query: { type_sub_code: "BULLETIN_LINNLIFE_QUESTION_CONTRACT" },
      });
    },
  },
};
</script>
