import { AuthConnection, Connection } from "./config.js";
import store from "@/store";

/*
    Title:
        APIs
    Auth:
        Token or None
    Terms:
        village: 단지정보
*/

export function postLog(data) {
  /*  
        로그 서버단 기록.
        comment: App 구동간 발생하는 에러를 확인하기 위하여, 에러 발생지점(Catch) 에
            적용한다, 실행 시 발생한 로그가 서버단에 저장된다.
        auth: None
        input:
            {   
                errors: Object ex)err
                tags: `App.vue|lynnlifeAPI|getUserData(${q})`,
                    (filename|APItype|api_name(parameters)
                etc: Object
            }
        output: None
            
    */
  return Connection.post(`/clientlogs`, data);
}

export function getUserData(query = "") {
  return AuthConnection.get(`/users?${query}`);
}

export function updateUserData(id, data) {
  return AuthConnection.put(`/users/${id}`, data);
}

export function getVillageList(query = "") {
  /*  
        단지 목록을 불러옴.
        output:
            status: 200
            data: 
                [
                    { id:1, name:"우미 00 단지", address:"서울시 ...", ... },
                    ...
                ]
    */
  if (store.state.hub) {
    query += "&&kind_code=FOR_RENT";
  }
  return Connection.get(`/villages?${query}`);
}

export function getVillage(id) {
  /*  
        단지 상세정보를 불러옴.
        input:
            id: 1
        output:
            status: 200
            data: { id:1, name:"우미 00 단지", address:"서울시 ...", ... },
    */
  return Connection.get(`/villages/${id}`);
}

export function getPacellingCalendar(query = "") {
  /*  
        분양일정을 불러옴.
        output:
            status: 200
            data: 
                [
                    { id:1, name:"우미 00 단지", address:"서울시 ...", ... },
                    ...
                ]
    */
  if (store.state.hub) {
    query += "&&kind_code=FOR_RENT";
  }
  return Connection.get(`/pacellingcalendar?${query}`);
}

export function getReservableList(home_id, query = "") {
  return AuthConnection.get(`/reservations/reservable/${home_id}?${query}`);
}

export function getReservationMasterList(query = "") {
  /*  
        예약 마스터 정보를 불러옴
        input:
            user_id: 1
            code: PRE_CHECK_RESERVATION
            date(options): '2021-01-01'
        output:
            status: 200
            data: [
                {id:1, ...}
            ]
        comment:
            해당 village 에 진행중인 PRE_CHECK_RESERVATION 타입의 예약마스터
            정보를 가져온다.
            date 입력시 해당 일자에 available 한 예약만 가져온다.
            
            codelist
                PRE_CHECK_RESERVATION = 사전점검예약

    */
  return AuthConnection.get(`/reservationmasters?${query}`);
}

export function getReservationMaster(id, query = "") {
  /*  
        예약 마스터 디테일 정보를 불러옴
        input:
            id:1
        output:
            status: 200
            data: {id:1, ...}
        comment:
            해당 village 에 진행중인 PRE_CHECK_RESERVATION 타입의 예약마스터
            정보를 가져온다.
            date 입력시 해당 일자에 available 한 예약만 가져온다.
            
            codelist
                PRE_CHECK_RESERVATION = 사전점검예약

    */
  return AuthConnection.get(`/reservationmasters/${id}?${query}`);
}

export function getMoveInReservationMaster(query = "") {
  // query = 'date=2022-01-27&village=30&kind_code=MOVEIN_RESERVATION&no_dong=3001&no_ho=1801'
  return AuthConnection.get(`/open/v1/reservations/available?${query}`);
}

export function postReservation(data) {
  /*  
        예약 생성
        comment: 예약 마스터 정보 및 예약가능 시간대 정보로 예약을 실행
        auth: Token
        input:
            {
                id: 0, // 예약마스터 id
                user_id: 0, // 유저 id
                date: "", // 선택한 일자
                hour: "",
                name: "",
                mobile: ""
            },
        output:
            
    */
  return AuthConnection.post(`/reservations`, data);
}

export function postMoveInReservation(data) {
  // {
  //   "master": 350,
  //   "time": 1795,
  //   "date": "2021-12-23",
  //   "dong_no": "3001",
  //   "ho_no": "1801",

  //   "ty_ipju":"O",

  //   "ty_contractor":"P",
  //   "ds_contractor":"정경구",
  //   "dt_birth":"19820614",

  //   "nm_l":"정경구",
  //   "no_tel_l":"010-6653-1954",
  //   "ds_vendor":"",
  //   "cd_vendor":""
  // }
  return AuthConnection.post(`/open/v1/reservations`, data);
}

export function getReservationList(query = "") {
  /*  
        예약 조회
        comment: 유저, 예약코드로 예약된 내역을 불러온다.
                * 표시만 필수요소.
        auth: Token
        input:
            query = 
                user_id: 단지*
                code: 코드*
                only_latest: 가장 최근 예약 내역만 조회.
        output:
            [
                {..},
                {..}
            ]
            
    */
  return AuthConnection.get(`/reservations?${query}`);
}

export function getReservation(id, data) {
  /*  
        예약 수정
        auth: Token
        input:
            id , {}
        output:
            {...}
            
    */
  return AuthConnection.get(`/reservations/${id}`, data);
}

export function putReservation(id, data) {
  /*  
        예약 수정
        auth: Token
        input:
            id , {}
        output:
            {...}
            
    */
  return AuthConnection.put(`/reservations/${id}`, data);
}

export function putMoveInReservation(id, data) {
  // {
  //   "master": 350,
  //   "time": 1795,
  //   "date": "2022-01-27",
  //   "dong_no": "3001",
  //   "ho_no": "1801",

  //   "ty_ipju":"O",

  //   "ty_contractor":"P",
  //   "ds_contractor":"정경구",
  //   "dt_birth":"19820614",

  //   "nm_l":"정경구",
  //   "no_tel_l":"010-6653-1954",
  //   "ds_vendor":"",
  //   "cd_vendor":""
  // }
  return AuthConnection.put(`/open/v1/reservations/${id}`, data);
}

export function delMoveInReservation(id) {
  /*  
        예약 수정
        auth: Token
        input:
            id , {}
        output:
    */
  return AuthConnection.delete(`/open/v1/reservations/${id}`);
}

export function delReservation(id) {
  /*  
        예약 수정
        auth: Token
        input:
            id , {}
        output:
    */
  return AuthConnection.delete(`/reservations/${id}`);
}

export function getInterestedCustomerList(query) {
  return AuthConnection.get(`/interestedcustomers?${query}`);
}

export function postInterestedCustomer(user, village) {
  /*    
        % 변경사항 미반영!
        관심고객 등록
        auth: Token
        input:
        output: None
            
    */
  return AuthConnection.post(`/interestedcustomers`, {
    user: user,
    village: village,
  });
}

export function delInterestedCustomer(id) {
  return AuthConnection.delete(`/interestedcustomers/${id}`);
}

export function getRoomType(query = "") {
  return Connection.get(`/roomtypes?${query}`);
}

export function getRentalWaitRequestList(query = "") {
  return Connection.get(`/rental/waiting?${query}`);
}

export function getRentalContractRequestList(query = "") {
  return Connection.get(`/rental/contract?${query}`);
}

export function postRentalWaitRequest(data) {
  return Connection.post("/rental/waiting", data);
}

export function postRentalContractRequest(data) {
  return Connection.post("/rental/contract", data);
}

export function getRentalWaitRequest(id) {
  return Connection.get(`/rental/waiting/${id}`);
}

export function getRentalContractRequest(id) {
  return Connection.get(`/rental/contract/${id}`);
}

export function putRentalWaitRequest(id, data) {
  return Connection.put(`/rental/waiting/${id}`, data);
}

export function putRentalContractRequest(id, data) {
  return Connection.put(`/rental/contract/${id}`, data);
}

export function delRentalWaitRequest(id) {
  return Connection.delete(`/rental/waiting/${id}`);
}

export function delRentalContractRequest(id) {
  return Connection.delete(`/rental/contract/${id}`);
}

export function getRentalContractTypeList(query) {
  return Connection.get(`/rental/type?${query}`);
}

export function getRentalContractChangeList(query) {
  return AuthConnection.get(`/rental/contract/change?${query}`);
}

export function postRentalContractChange(data) {
  return AuthConnection.post("/rental/contract/change", data);
}

export function delRentalContractChange(id) {
  return AuthConnection.delete(`/rental/contract/change/${id}`);
}

export function getUserRequestHistory(id) {
  return AuthConnection.get(`/userrequesthistory/${id}`);
}

export function getTenantList(id) {
  return AuthConnection.get(`/tenant/list/${id}`);
}

export function patchTenant(id, data) {
  return AuthConnection.patch(`/tenant/${id}`, data);
}

export function delTenant(id) {
  return AuthConnection.delete(`/tenant/${id}`);
}

export function postBulletin(data) {
  return AuthConnection.post(`/bulletin`, data);
}

export function getBulletinList(query) {
  return AuthConnection.get(`/bulletin?${query}`);
}

export function getBulletin(id) {
  return AuthConnection.get(`/bulletin/${id}`);
}

export function delBulletin(id) {
  return AuthConnection.delete(`/bulletin/${id}`);
}

export function getBulletinUtils(query) {
  return AuthConnection.get(`/bulletin/utils?${query}`);
}

export function getVoteList(query = "") {
  return AuthConnection.get(`/votes?${query}`);
}

export function getVote(id, query) {
  return AuthConnection.get(`/votes/${id}?${query}`);
}

export function postVoteResult(data) {
  return AuthConnection.post("/votes/vote", data);
}

export function getFreeboardList(query) {
  return AuthConnection.get(`/free_board/?${query}`);
}

export function getFreeboard(id) {
  return AuthConnection.get(`/free_board/${id}/`);
}

export function postFreeboard(data) {
  return AuthConnection.post("/free_board/", data);
}

export function putFreeboard(id, data) {
  return AuthConnection.put(`/free_board/${id}/`, data);
}

export function patchFreeboard(id, data) {
  return AuthConnection.patch(`/free_board/${id}/`, data);
}

export function delFreeboard(id) {
  return AuthConnection.delete(`/free_board/${id}/`);
}

export function getReport(id) {
  return AuthConnection.get(`/free_board/${id}/report/`);
}

export function postReport(id, data) {
  return AuthConnection.post(`/free_board/${id}/report/`, data);
}

export function getLike(id) {
  return AuthConnection.get(`/free_board/${id}/like/`);
}

export function postLike(id) {
  return AuthConnection.post(`/free_board/${id}/like/`, {});
}

export function delLike(id, likeId) {
  return AuthConnection.delete(`/free_board/${id}/like/${likeId}/`, {});
}

export function getFee(id, year, month) {
  return AuthConnection.get(`/fee/fees/${id}/${year}/${month}`);
}

export function getFeeDetail(id, year, month) {
  return AuthConnection.get(`/fee/fee/${id}/${year}/${month}`);
}

export function getMonth(id) {
  return AuthConnection.get(`/fee/latest/${id}`);
}

export function getReply(free_board_id) {
  return AuthConnection.get(`/free_board/${free_board_id}/reply/`);
}

export function postReply(free_board_id, data) {
  return AuthConnection.post(`/free_board/${free_board_id}/reply/`, data);
}

export function putReply(free_board_id, id, data) {
  return AuthConnection.put(`/free_board/${free_board_id}/reply/${id}/`, data);
}

export function patchReply(free_board_id, id, data) {
  return AuthConnection.patch(
    `/free_board/${free_board_id}/reply/${id}/`,
    data
  );
}

export function delReply(free_board_id, id) {
  return AuthConnection.delete(`/free_board/${free_board_id}/reply/${id}/`);
}

export function checkPreContractor(data) {
  return AuthConnection.post(`/precontractorcheck`, data);
}
