<template>
  <v-container class="pd16">
    <ul class="under-list">
      <li>고객님이 계약하신 단지의 납부정보를 조회하실 수 있습니다</li>
      <li>계약자인 경우에 한해 계약현장의 분양대금 수납조회가 가능합니다</li>
      <li>
        린라이프에서 제공하는 분양대금조회서비스는 일반적으로 분양대금 입금일
        익일에 조회가 가능합니다
      </li>
    </ul>
    <table class="unline-table">
      <colgroup>
        <col width="95px" />
        <col width="*" />
      </colgroup>
      <tr>
        <td>사업지&nbsp;&nbsp;</td>
        <td>
          <strong>{{ home.village_name }}&nbsp;&nbsp;</strong>
        </td>
      </tr>
      <tr>
        <td>동 / 호&nbsp;&nbsp;</td>
        <td>
          <strong>{{ home.dong_no }}동 {{ home.ho_no }}호&nbsp;&nbsp;</strong>
        </td>
      </tr>
    </table>

    <strong class="t-cont-title pr3"> 분양대금 납부내역 </strong>
    <!-- 납부금 검색필드 -->
    <!-- v-if="
        selectedInvoice != null &&
        (selectedInvoice[0].calculated || selectedInvoice[0].current)
      " -->
    <div class="storage-table mb5">
      <table>
        <colgroup>
          <col width="130px" />
          <col width="*" />
          <col width="20%" />
        </colgroup>
        <tr>
          <th colspan="3" class="fts16">납부금 계산</th>
        </tr>
        <tr class="">
          <th colspan="3" class="lh24 pb15">
            납부일자에 따라 <b><u>할인금액 및 연체금액</u></b
            >이 발생할 수 있습니다. 납부가능한 일자에 맞춰 조회하시길 바랍니다.
          </th>
        </tr>
        <tr>
          <th>납부예정일자</th>
          <td colspan="2">
            {{ payDate }}
            <!-- 연체료 계산 달력 -->
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              max-width="290"
              class="ds-ib"
            >
              <template v-slot:activator="{ on, attrs }">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.409"
                  height="14.409"
                  viewBox="0 0 14.409 14.409"
                  v-bind="attrs"
                  v-on="on"
                >
                  <g
                    id="Icon_feather-search"
                    data-name="Icon feather-search"
                    transform="translate(-3.5 -3.5)"
                  >
                    <path
                      id="패스_588"
                      data-name="패스 588"
                      d="M15.162,9.831A5.331,5.331,0,1,1,9.831,4.5,5.331,5.331,0,0,1,15.162,9.831Z"
                      transform="translate(0 0)"
                      fill="none"
                      stroke="#da581f"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="패스_589"
                      data-name="패스 589"
                      d="M27.874,27.874l-2.9-2.9"
                      transform="translate(-11.379 -11.379)"
                      fill="none"
                      stroke="#da581f"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </template>
              <v-date-picker
                v-model="payDate"
                @change="menu = false"
                :day-format="(date) => new Date(date).getDate()"
                :min="today"
                locale="KR"
                full-width
                no-title
              ></v-date-picker>
            </v-menu>
          </td>
        </tr>
        <tr>
          <th>예정입금액</th>
          <td colspan="2">
            <v-text-field
              v-model="userPayment"
              @keyup="inputPayment"
              placeholder="미 입력시 잔액기준 조회됨"
              class="twidth180 ds-ib mg0 pd0"
            ></v-text-field>
          </td>
        </tr>
        <tr>
          <th class="pl5 pb5 pl5 pt15" colspan="3">
            <a href="#n" @click="calculator()" class="sub-btn">조회</a>
          </th>
        </tr>
      </table>
    </div>
    <!-- 구분 -->
    <div class="list-gg">
      <v-slide-group v-model="activeItem" mandatory :center-active="true">
        <!-- invoice.current 필드 이용해서 센터 잡기 -->
        <v-slide-item
          class="mr10 nh-50"
          :id="'TOTAL'"
          v-slot="{ active, toggle }"
        >
          <v-btn
            active-class="v-active"
            :input-value="active"
            class="pd10 color-2 bgc-8 nw-100 text-left"
            depressed
            v-on:click="toggle"
            @click="
              selectedInvoice = [];
              selectedInvoice[0] = invoices.total;
              selectedIdx = 'TOTAL';
            "
          >
            합계<br />
            &nbsp;&nbsp;
          </v-btn>
        </v-slide-item>
        <v-slide-item
          class="mr10 nh-50"
          v-for="(invoice, i) in invoices.downs"
          v-bind:key="'DOWN-' + i"
          :id="'DOWN-' + i"
          v-slot="{ active, toggle }"
        >
          <v-btn
            active-class="v-active"
            :input-value="active"
            :class="[
              { btn_none: invoice.NO_BUNNAP != '99' },
              'pd10 color-2 bgc-8 nw-100 text-left',
            ]"
            depressed
            v-on:click="toggle"
            @click="clickInvoice('DOWN', i, invoice)"
          >
            계약금<br />
            {{
              invoice.count == 0
                ? ""
                : (invoice.count > 10 ? invoice.count - 10 : invoice.count) +
                  "회차"
            }}
            {{
              invoice.paidAmount > 0 &&
              (!invoice.calculated || invoice.CHA == "A")
                ? invoice.NO_BUNNAP == "99"
                  ? "-완납"
                  : "-" + invoice.NO_BUNNAP
                : ""
            }}
          </v-btn>
        </v-slide-item>
        <v-slide-item
          class="mr10 nh-50"
          v-for="(invoice, i) in invoices.seconds"
          v-bind:key="'SECOND-' + i"
          :id="'SECOND-' + i"
          v-slot="{ active, toggle }"
        >
          <v-btn
            active-class="v-active"
            :input-value="active"
            :class="[
              { btn_none: invoice.NO_BUNNAP != '99' },
              'pd10 color-2 bgc-8 nw-100 text-left',
            ]"
            depressed
            v-on:click="toggle"
            @click="clickInvoice('SECOND', i, invoice)"
          >
            중도금<br />
            {{ invoice.count == 0 ? "" : invoice.count + "회차" }}
            {{
              invoice.paidAmount > 0 &&
              (!invoice.calculated || invoice.CHA == "A")
                ? invoice.NO_BUNNAP == "99"
                  ? "-완납"
                  : "-" + invoice.NO_BUNNAP
                : ""
            }}
          </v-btn>
        </v-slide-item>
        <v-slide-item
          class="mr10 nh-50"
          v-for="(invoice, i) in invoices.balance"
          v-bind:key="'BALANCE-' + i"
          :id="'BALANCE-' + i"
          v-slot="{ active, toggle }"
        >
          <v-btn
            active-class="v-active"
            :input-value="active"
            :class="[
              { btn_none: invoice.NO_BUNNAP != '99' },
              'pd10 color-2 bgc-8 nw-100 text-left',
            ]"
            depressed
            v-on:click="toggle"
            @click="clickInvoice('BALANCE', i, invoice)"
          >
            잔금<br />
            {{ invoice.count == 0 ? "" : invoice.count + "회차" }}
            {{
              invoice.paidAmount > 0 &&
              (!invoice.calculated || invoice.CHA == "A")
                ? invoice.NO_BUNNAP == "99"
                  ? "-완납"
                  : "-" + invoice.NO_BUNNAP
                : ""
            }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </div>

    <div class="storage-table" v-if="selectedInvoice.length == 1">
      <table>
        <colgroup>
          <col width="130px" />
          <col width="*" />
          <col width="10%" />
        </colgroup>
        <tr>
          <th colspan="3">
            <b
              v-if="
                selectedInvoice[0].CHA == 'C' || selectedInvoice[0].CHA == 'E'
              "
            >
              예상납부
              {{ "-" + selectedInvoice[0].payDate }}
              <span
                v-if="
                  userPayment != 0 &&
                  userPayment != null &&
                  selectedInvoice[0].CHA == 'C'
                "
                >({{ userPayment }}원)</span
              >
              <!-- {{
                  invoice.paidAmount > 0 && !invoice.calculated
                    ? invoice.NO_BUNNAP == "99"
                      ? "-완납"
                      : "-" + invoice.NO_BUNNAP
                    : ""
                }} -->
            </b>
          </th>
        </tr>
        <tr v-if="selectedInvoice[0].paymentDate">
          <th
            v-if="
              selectedInvoice[0].paymentDate !=
              selectedInvoice[0].paymentStartDate
            "
          >
            약정기간
          </th>
          <th v-else>약정일자</th>
          <td
            v-if="
              selectedInvoice[0].paymentDate !=
              selectedInvoice[0].paymentStartDate
            "
          >
            {{ selectedInvoice[0].paymentStartDate }}~{{
              selectedInvoice[0].paymentDate
            }}
          </td>
          <td v-else>{{ selectedInvoice[0].paymentDate }}</td>
          <td></td>
        </tr>
        <tr>
          <th>약정금액</th>
          <td>{{ selectedInvoice[0].balance | comma }}</td>
          <td></td>
        </tr>
        <tr v-if="selectedInvoice[0].payDate">
          <th
            v-if="
              selectedInvoice[0].calculated &&
              (selectedInvoice[0].CHA == 'C' || selectedInvoice[0].CHA == 'E')
            "
          >
            납부예정일자
          </th>
          <th v-else>납부일자</th>
          <td>
            {{
              selectedInvoice[0].payDate == null
                ? "-"
                : selectedInvoice[0].payDate
            }}
          </td>
          <td></td>
        </tr>
        <tr
          v-if="
            selectedInvoice[0].calculated &&
            (selectedInvoice[0].CHA == 'C' || selectedInvoice[0].CHA == 'E')
          "
        >
          <th>적용일수</th>
          <td>{{ selectedInvoice[0].DD_DAY }}</td>
          <td></td>
        </tr>
        <tr>
          <th>할인금액</th>
          <td>{{ selectedInvoice[0].discount | comma }}</td>
          <td></td>
        </tr>
        <tr>
          <th>연체금액</th>
          <td>
            {{ selectedInvoice[0].delayedFee | comma }}
          </td>
          <td></td>
        </tr>
        <tr>
          <th
            v-if="
              selectedInvoice[0].calculated &&
              (selectedInvoice[0].CHA == 'C' || selectedInvoice[0].CHA == 'E')
            "
          >
            납부예정금액
          </th>
          <th v-else>납부금액</th>
          <td>{{ selectedInvoice[0].paidAmount | comma }}</td>
          <td></td>
        </tr>
        <!-- 계산시 합계에서 보여주는 납부예정금액, 납부잔액 -->
        <tr
          v-if="selectedInvoice[0].calculated && selectedInvoice[0].CHA == 'F'"
        >
          <th>납부예정금액</th>
          <td>{{ Number(userPayment.replace(/,/g, "")) | comma }}</td>
          <td></td>
        </tr>
        <tr
          v-if="selectedInvoice[0].calculated && selectedInvoice[0].CHA == 'F'"
        >
          <th>납부잔액</th>
          <td>
            {{
              (Number(selectedInvoice[0].balance) -
                Number(selectedInvoice[0].discount) +
                Number(selectedInvoice[0].delayedFee) -
                Number(selectedInvoice[0].paidAmount) -
                Number(userPayment.replace(/,/g, "")))
                | comma
            }}
          </td>
          <td></td>
        </tr>
        <!-- <tr
          v-if="
            selectedInvoice[0].calculated &&
            selectedInvoice[0].CHA == 'C' &&
            selectedInvoice[0].janaeck > 0
          "
        >
          <th>잔액</th>
          <td>{{ selectedInvoice[0].janaeck | comma }}</td>
          <td></td>
        </tr> -->
      </table>
    </div>

    <!-- 같은 차수 분납 -->
    <div v-if="selectedInvoice.length > 1" style="margin-bottom: 35px">
      <div
        class="storage-table"
        v-for="(invoice, i) in selectedInvoice"
        :key="i"
        style="margin-bottom: 10px"
      >
        <table>
          <colgroup>
            <col width="130px" />
            <col width="*" />
            <col width="10%" />
          </colgroup>
          <tr>
            <th colspan="3">
              <b v-if="invoice.CHA == 'C' || invoice.CHA == 'E'">
                예상납부
                {{ "-" + invoice.payDate }}

                <span
                  v-if="
                    userPayment != 0 &&
                    userPayment != null &&
                    invoice.CHA == 'C'
                  "
                  >({{ userPayment }}원)</span
                >
                <!-- {{
                  invoice.paidAmount > 0 && !invoice.calculated
                    ? invoice.NO_BUNNAP == "99"
                      ? "-완납"
                      : "-" + invoice.NO_BUNNAP
                    : ""
                }} -->
              </b>
              <b v-else>
                {{ invoice.count == 0 ? "" : invoice.count + "-" + "회차-" }}
                {{ invoice.payDate == null ? "미납" : invoice.payDate }}
                <!-- {{
                  invoice.paidAmount > 0 && !invoice.calculated
                    ? invoice.NO_BUNNAP == "99"
                      ? "-완납"
                      : "-" + invoice.NO_BUNNAP
                    : ""
                }} -->
              </b>
            </th>
            <!-- <td></td>
            <td></td> -->
          </tr>
          <tr v-if="invoice.paymentDate">
            <th v-if="invoice.paymentDate != invoice.paymentStartDate">
              약정기간
            </th>
            <th v-else>약정일자</th>
            <td v-if="invoice.paymentDate != invoice.paymentStartDate">
              {{ invoice.paymentStartDate }}~{{ invoice.paymentDate }}
            </td>
            <td v-else>{{ invoice.paymentDate }}</td>
            <td></td>
          </tr>
          <tr>
            <th>약정금액</th>
            <td>{{ invoice.balance | comma }}</td>
            <td></td>
          </tr>
          <tr v-if="invoice.payDate">
            <th v-if="invoice.calculated && invoice.CHA == 'C'">
              납부예정일자
            </th>
            <th v-else>납부일자</th>
            <td>
              {{ invoice.payDate == null ? "-" : invoice.payDate }}
            </td>
            <td></td>
          </tr>
          <tr
            v-if="
              invoice.calculated && (invoice.CHA == 'C' || invoice.CHA == 'E')
            "
          >
            <th>적용일수</th>
            <td>{{ invoice.DD_DAY }}</td>
            <td></td>
          </tr>
          <tr>
            <th>할인금액</th>
            <td>{{ invoice.discount | comma }}</td>
            <td></td>
          </tr>
          <tr>
            <th>연체금액</th>
            <td>
              {{ invoice.delayedFee | comma }}
            </td>
            <td></td>
          </tr>
          <tr>
            <th
              v-if="
                invoice.calculated && (invoice.CHA == 'C' || invoice.CHA == 'E')
              "
            >
              납부예정금액
            </th>
            <th v-else>납부금액</th>
            <td>{{ invoice.paidAmount | comma }}</td>
            <td></td>
          </tr>
          <!-- <tr
            v-if="
              invoice.calculated && invoice.CHA == 'C' && invoice.janaeck > 0
            "
          >
            <th>잔액</th>
            <td>{{ invoice.janaeck | comma }}</td>
            <td></td>
          </tr> -->
        </table>
      </div>
    </div>

    <strong class="t-cont-title">납부처 및 납부방법</strong>
    <table class="t-table table5">
      <colgroup>
        <col width="80px" />
        <col width="*" />
      </colgroup>
      <tr>
        <th>납부기관</th>
        <td>{{ invoices.bankName }}</td>
      </tr>
      <tr>
        <th>납부계좌</th>
        <td>{{ invoices.bankAccount }}</td>
      </tr>
      <tr>
        <th>예금주</th>
        <td>{{ invoices.accountHolder }}</td>
      </tr>
    </table>
  </v-container>
</template>

<style>
.list-gg .v-btn__content {
  align-items: flex-start;
  text-align: left;
  flex-flow: column;
}
</style>

<script>
import { getSellInvoice, getChargesCalculator } from "@/api/contract";
import { copy } from "@/utils/utils";

import { get_now_string } from "@/utils/time.js";
export default {
  components: {},
  name: "Payment",
  data() {
    return {
      activeItem: 0,
      translateX: 0,
      userPayment: "",
      payDate: get_now_string(),
      today: get_now_string(),
      menu: false,
      items: ["2022.10.15", "2022.10.17"],
      e1: "2022.10.15",
      selectedIdx: "",
      selectedInvoice: [{ calculated: false }],
      invoices: {},
      balance: 0,
    };
  },
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
  },
  methods: {
    calculator() {
      var selectType = this.selectedIdx.split("-")[0];
      var selectIndex = this.selectedIdx.split("-")[1];

      let query = `want_date=${this.payDate}&&paymentDate=${
        this.selectedInvoice[0].paymentDate
      }&&want_fee=${
        this.userPayment != 0 ? this.userPayment.replace(/,/g, "") : 0
      }`;

      getChargesCalculator(this.home.id, query)
        .then(({ data }) => {
          console.log("getChargesCalculator", data);

          this.invoices = data;

          //  set default selected by current flag
          var focusId = "";
          var count = 1; // 합계 추가로 count 1부터 시작
          this.invoices.downs.forEach((item, i) => {
            console.log("downs");
            this.invoices.downs[i].janaeck =
              Number(item.balance) -
              Number(item.discount) +
              Number(item.delayedFee) -
              Number(item.paidAmount);
            if (item.current) {
              this.selectedInvoice[0] = item;
              focusId = "DOWN-" + i;
              this.selectedIdx = focusId;
              this.activeItem = count;
              this.clickInvoice("DOWN", i, item);
            }
            count++;
          });
          this.invoices.seconds.forEach((item, i) => {
            console.log("seconds");
            this.invoices.seconds[i].janaeck =
              Number(item.balance) -
              Number(item.discount) +
              Number(item.delayedFee) -
              Number(item.paidAmount);
            if (item.current) {
              this.selectedInvoice[0] = item;
              focusId = "SECOND-" + i;
              this.selectedIdx = focusId;
              this.activeItem = count;
              this.clickInvoice("SECOND", i, item);
            }
            count++;
          });
          this.invoices.balance.forEach((item, i) => {
            console.log("balance");
            this.invoices.balance[i].janaeck =
              Number(item.balance) -
              Number(item.discount) +
              Number(item.delayedFee) -
              Number(item.paidAmount);
            if (item.current) {
              this.selectedInvoice[0] = item;
              focusId = "BALANCE-" + i;
              this.selectedIdx = focusId;
              this.activeItem = count;
              this.clickInvoice("BALANCE", i, item);
            }
            count++;
          });
        })
        .catch((e) => {
          this.$store.commit("ERROR", "시스템 오류입니다.");
        });
    },
    getInvoices() {
      getSellInvoice(this.home.id).then(({ data }) => {
        console.log("getSellInvoice", data);
        this.invoices = data;

        //  set default selected by current flag
        var focusId = "";
        var count = 1; // 합계 추가로 count 1부터 시작
        this.invoices.downs.forEach((item, i) => {
          if (item.current) {
            this.selectedInvoice[0] = item;
            focusId = "DOWN-" + i;
            this.selectedIdx = focusId;
            this.activeItem = count;
            this.clickInvoice("DOWN", i, item);
          }
          count++;
        });
        this.invoices.seconds.forEach((item, i) => {
          if (item.current) {
            this.selectedInvoice[0] = item;
            focusId = "SECOND-" + i;
            this.selectedIdx = focusId;
            this.activeItem = count;
            this.clickInvoice("SECOND", i, item);
          }

          count++;
        });
        this.invoices.balance.forEach((item, i) => {
          if (item.current) {
            this.selectedInvoice[0] = item;
            focusId = "BALANCE-" + i;
            this.selectedIdx = focusId;
            this.activeItem = count;
            this.clickInvoice("BALANCE", i, item);
          }

          count++;
        });
        //console.log(this.selectedInvoice.length);
      });
    },
    clickInvoice(flag, idx, invoice) {
      this.selectedInvoice = []; //초기화
      this.selectedInvoice[0] = copy(invoice);
      this.selectedIdx = flag + "-" + idx;

      // 선택된 invoice와 paymentData,count가 같은 것들을 찾는다.
      if (flag == "DOWN") {
        this.invoices.downs.forEach((item) => {
          if (
            item.NO_BUNNAP != invoice.NO_BUNNAP &&
            item.paymentDate == invoice.paymentDate &&
            item.count == invoice.count
          ) {
            this.selectedInvoice.push(copy(item));
          }
        });
      } else if (flag == "SECOND") {
        this.invoices.seconds.forEach((item) => {
          if (
            item.NO_BUNNAP != invoice.NO_BUNNAP &&
            item.paymentDate == invoice.paymentDate &&
            item.count == invoice.count
          ) {
            this.selectedInvoice.push(copy(item));
          }
        });
      } else if (flag == "BALANCE") {
        this.invoices.balance.forEach((item) => {
          if (
            item.NO_BUNNAP != invoice.NO_BUNNAP &&
            item.paymentDate == invoice.paymentDate &&
            item.count == invoice.count
          ) {
            this.selectedInvoice.push(copy(item));
          }
        });
      }

      // 정렬
      this.selectedInvoice.sort(function (a, b) {
        return b.NO_BUNNAP - a.NO_BUNNAP;
      });

      //이제.. 납부일자가 같으면 합쳐서 보여주는..
      if (this.selectedInvoice.length > 1) {
        var tempList = [];
        this.selectedInvoice.forEach((item, i) => {
          if (i == 0) {
            tempList.push(item);
          } else {
            if (
              item.payDate == tempList[tempList.length - 1].payDate &&
              item.CHA != "C" &&
              item.CHA != "E"
            ) {
              // 납부금액
              tempList[tempList.length - 1].paidAmount =
                Number(tempList[tempList.length - 1].paidAmount) +
                Number(item.paidAmount);
              // 연체금액
              tempList[tempList.length - 1].delayedFee =
                Number(tempList[tempList.length - 1].delayedFee) +
                Number(item.delayedFee);
              // 할인금액
              tempList[tempList.length - 1].discount =
                Number(tempList[tempList.length - 1].discount) +
                Number(item.discount);
            } else {
              tempList.push(copy(item));
            }
          }
        });
        this.selectedInvoice = tempList;
      }
    },
    inputPayment() {
      if (isNaN(this.userPayment.replace(/,/g, ""))) {
        this.userPayment = "";
        return;
      }
      this.userPayment = this.filter(this.userPayment.replace(/,/g, ""));
    },
    filter(val) {
      if (val == 0) return 0;
      if (val != "" && val != null && val != undefined) {
        val = Number(val);
        return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  created() {
    this.getInvoices();
  },
  mounted() {},
};
</script>
<style scoped>
.btn_none {
  display: none !important;
}
</style>
