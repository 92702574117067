<template>
  <v-container class="vh-footer ps-re lynn-mobile lynn-web-in bg-gray" fluid>
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">계약신청</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-subtitle class="color-1 fts16 lh24"
        >계약신청을 하시면 전문 상담원이<br />안내해 드립니다.</v-card-subtitle
      >
      <div>
        <v-form>
          <v-subheader class="pd0 fts16 ftw5 mt15"
            >계약 대상 목적물의 표시</v-subheader
          >
          <v-select
            solo
            v-model="form.village_id"
            :items="villageList"
            item-text="village_name"
            item-value="id"
            label="계약단지 선택"
            ref="village"
            @change="checkSignup()"
            :readonly="is_modify"
          >
          </v-select>
          <v-row>
            <v-col cols="4" class="mr-p2">
              <v-text-field
                v-model="form.dong_no"
                ref="dong_ho"
                label="동"
                @input="checkSignup()"
                :readonly="is_modify"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="form.ho_no"
                ref="ho_no"
                label="호"
                @input="checkSignup()"
                :readonly="is_modify"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-slide-y-transition>
            <v-row v-show="signupCheckWarn">
              {{ signupCheckWarn }}
            </v-row>
          </v-slide-y-transition>
          <v-subheader class="pd0 fts16 ftw5 mt15">구분</v-subheader>
          <v-radio-group
            row
            v-model="form.rental_type"
            class="pd0 mg0"
            @change="changeType()"
            ref="rental_type"
            :disabled="Boolean(signupCheckWarn)"
            :readonly="is_modify"
          >
            <v-row>
              <v-col cols="6" sm="6">
                <v-radio
                  class="mb-1"
                  :value="item.DS_OPTION"
                  :key="item.DS_OPTION"
                  :label="item.DS_OPTION"
                  v-for="item in rentalContractTypeList.slice(
                    0,
                    parseInt(rentalContractTypeList.length / 2) + 1
                  )"
                ></v-radio>
              </v-col>
              <v-col cols="6" sm="6">
                <v-radio
                  class="mb-1"
                  :value="item.DS_OPTION"
                  :key="item.DS_OPTION"
                  :label="item.DS_OPTION"
                  v-for="item in rentalContractTypeList.slice(
                    parseInt(rentalContractTypeList.length / 2) + 1
                  )"
                ></v-radio>
              </v-col>
            </v-row>
          </v-radio-group>

          <div class="complete-box">
            <div class="comin-box">
              <div class="comin-cont">
                <strong class="text-left ds-b fts12 ftw7 lh24 color-6 mb5"
                  >주택형</strong
                >
                <span
                  class="text-left ds-b fts14 lh24 color-5"
                  v-show="form.rental_type"
                  >{{ parseInt(rentalContractType.SZ_EXCLUSIVE)
                  }}{{ rentalContractType.TY_TYPE }}m²</span
                >
              </div>
              <div class="comin-cont">
                <strong class="text-left ds-b fts12 ftw7 lh24 color-6 mb5"
                  >임대보증금</strong
                >
                <span
                  class="text-left ds-b fts14 lh24 color-5"
                  v-show="form.rental_type"
                  >{{ rentalContractType.AM_SELLING | comma }}</span
                >
              </div>
              <div class="comin-cont">
                <strong class="text-left ds-b fts12 ftw7 lh24 color-6 mb5"
                  >월 임대료</strong
                >
                <span
                  class="text-left ds-b fts14 lh24 color-5"
                  v-show="form.rental_type"
                  >{{ rentalContractType.AM_MLEASE | comma }}
                </span>
              </div>
            </div>
          </div>
          <v-subheader class="pd0 fts16 ftw5 mt15"
            >1. 지정 동·호수 지정 신청 시 납부금액</v-subheader
          >
          <div class="complete-box">
            <div class="comin-box">
              <div class="comin-cont">
                <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
                  >동·호수 지정 신청금</strong
                >
                <span class="text-left ds-b fts14 lh24 color-5"
                  >일백만원정 (￦ 1,000,000) <br />※ 승인완료시 추후 가상계좌를
                  SMS로 발송예정</span
                >
              </div>
              <div class="comin-cont">
                <span class="text-left ds-b fts12 ftw4 lh20 color-6"
                  >※계약은 1인 1건을 원칙, 중복계약 시 모든 계약이 취소됨을
                  양지하시기 바랍니다.</span
                >
                <span class="text-left ds-b fts12 ftw4 lh20 color-6"
                  >※해당기한내에 계약금(1,000,000원)을 입금하셔야 계약이
                  성립됩니다. 기한(익일 24시)경과시 가상계좌는
                  자동소멸됩니다.</span
                >
                <span class="text-left ds-b fts12 ftw4 lh20 color-6"
                  >※계약자명으로 입금바랍니다.</span
                >
              </div>
            </div>
          </div>
          <v-subheader class="pd0 fts16 ftw5 mt15"
            >2. 지정 동·호수 계약 기한 및 추가 납부금액</v-subheader
          >
          <div class="complete-box">
            <div class="comin-box">
              <div class="comin-cont">
                <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
                  >계약기한</strong
                >
                <span class="text-left ds-b fts14 ftw5 lh24 color-1"
                  >{{ contract_date.substr(0, 4) }}년
                  {{ contract_date.substr(5, 2) }}월
                  {{ contract_date.substr(8, 2) }}일</span
                >
              </div>
              <div class="comin-cont">
                <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
                  >임대 계약금</strong
                >
                <span class="text-left ds-b fts12 ftw4 lh20 color-5 mb5"
                  >· 계약금 일금 일백오십만원정 (￦ 1,500,000 원)</span
                >
                <span class="text-left ds-b fts12 ftw4 lh20 color-5 mb5"
                  >· 납부계좌 : 추후 SMS 발송예정</span
                >
                <span class="text-left ds-b fts12 ftw4 lh20 color-5 mb5"
                  >· 예금주 :
                  (주)우미케이비뉴스테이제1호위탁관리부동산투자회사</span
                >
                <span class="text-left ds-b fts12 ftw4 lh20 color-5"
                  >※단순 심경 변화에 의한 동호지정계약 해지 요청은 인정되지
                  않고, 귀속처리 됩니다. 또한, 지정된 계약일 (<span
                    class="color-1"
                    >{{ contract_date.substr(0, 4) }}년
                    {{ contract_date.substr(5, 2) }}월
                    {{ contract_date.substr(8, 2) }}일 오후 17시까지</span
                  >) 이내에 특별한 사유를 통보치 아니하고 정계약을 전환하지 않을
                  경우에는 기 납입한 동호지정신청금(총 ￦1,000,000원)은
                  위약금으로 처리되어 귀사에 귀속되며, 이에 대하여 당사에 어떠한
                  이의도 제기하지 않을 것임을 확인합니다.</span
                >
              </div>
              <!-- <div class="comin-cont">
                <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
                  >명의변경</strong
                >
                <span class="text-left ds-b fts14 ftw5 lh24 color-1"
                  >직계존·비속에 한해 가족관계증명서 제출/확인자는 1회만
                  가능함.</span
                >
              </div> -->
            </div>
          </div>
          <v-subheader class="pd0 fts16 ftw5 mt15">동·호수 계약자</v-subheader>
          <v-text-field
            v-model="form.name"
            maxlength="10"
            label="이름"
            readonly
            ref="name"
          ></v-text-field>
          <v-row>
            <v-col cols="5">
              <v-text-field
                label="주민등록번호"
                required
                v-model="register_number1"
                maxlength="6"
                ref="register_number"
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-card-text class="fts20">-</v-card-text>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="2" class="mr-p2">
                  <v-text-field
                    v-model="register_number2"
                    maxlength="1"
                  ></v-text-field>
                </v-col>
                <v-col cols="9" class="text-left">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="120"
                    height="45"
                    viewBox="0 0 120 45"
                  >
                    <g
                      id="그룹_1247"
                      data-name="그룹 1247"
                      transform="translate(-220 -1493)"
                    >
                      <g
                        id="그룹_1239"
                        data-name="그룹 1239"
                        transform="translate(34 18)"
                      >
                        <rect
                          id="사각형_1755"
                          data-name="사각형 1755"
                          width="20"
                          height="45"
                          transform="translate(186 1475)"
                          fill="none"
                        />
                        <circle
                          id="타원_167"
                          data-name="타원 167"
                          cx="3"
                          cy="3"
                          r="3"
                          transform="translate(193 1502)"
                        />
                      </g>
                      <g
                        id="그룹_1242"
                        data-name="그룹 1242"
                        transform="translate(74 18)"
                      >
                        <rect
                          id="사각형_1755-2"
                          data-name="사각형 1755"
                          width="20"
                          height="45"
                          transform="translate(186 1475)"
                          fill="none"
                        />
                        <circle
                          id="타원_167-2"
                          data-name="타원 167"
                          cx="3"
                          cy="3"
                          r="3"
                          transform="translate(193 1502)"
                        />
                      </g>
                      <g
                        id="그룹_1244"
                        data-name="그룹 1244"
                        transform="translate(114 18)"
                      >
                        <rect
                          id="사각형_1755-3"
                          data-name="사각형 1755"
                          width="20"
                          height="45"
                          transform="translate(186 1475)"
                          fill="none"
                        />
                        <circle
                          id="타원_167-3"
                          data-name="타원 167"
                          cx="3"
                          cy="3"
                          r="3"
                          transform="translate(193 1502)"
                        />
                      </g>
                      <g
                        id="그룹_1240"
                        data-name="그룹 1240"
                        transform="translate(54 18)"
                      >
                        <rect
                          id="사각형_1755-4"
                          data-name="사각형 1755"
                          width="20"
                          height="45"
                          transform="translate(186 1475)"
                          fill="none"
                        />
                        <circle
                          id="타원_167-4"
                          data-name="타원 167"
                          cx="3"
                          cy="3"
                          r="3"
                          transform="translate(193 1502)"
                        />
                      </g>
                      <g
                        id="그룹_1241"
                        data-name="그룹 1241"
                        transform="translate(94 18)"
                      >
                        <rect
                          id="사각형_1755-5"
                          data-name="사각형 1755"
                          width="20"
                          height="45"
                          transform="translate(186 1475)"
                          fill="none"
                        />
                        <circle
                          id="타원_167-5"
                          data-name="타원 167"
                          cx="3"
                          cy="3"
                          r="3"
                          transform="translate(193 1502)"
                        />
                      </g>
                      <g
                        id="그룹_1243"
                        data-name="그룹 1243"
                        transform="translate(134 18)"
                      >
                        <rect
                          id="사각형_1755-6"
                          data-name="사각형 1755"
                          width="20"
                          height="45"
                          transform="translate(186 1475)"
                          fill="none"
                        />
                        <circle
                          id="타원_167-6"
                          data-name="타원 167"
                          cx="3"
                          cy="3"
                          r="3"
                          transform="translate(193 1502)"
                        />
                      </g>
                    </g>
                  </svg>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-text-field
            v-model="form.email"
            label="이메일"
            required
            ref="email"
          ></v-text-field>
          <v-text-field
            v-model="form.address"
            label="주소"
            required
            ref="address"
          ></v-text-field>
          <v-text-field
            v-model="form.phone_number"
            label="연락처"
            required
            ref="phone_number"
          ></v-text-field>
          <v-text-field
            v-model="form.mobile"
            label="휴대폰"
            readonly
            ref="mobile"
          ></v-text-field>
          <div class="pwidth100">
            <span class="text-left ds-b fts14 ftw4 lh24 color-6 mb5"
              >※ 본 계약시 준비서류[서류는 계약일로부터 3개월 이내 발급분]</span
            >
            <span class="text-left ds-b fts14 ftw4 lh24 color-6 mb5"
              >[본인 방문시] 인감증명서1부, 주민등록등본1부, 인감도장, 신분증,
              계약금입금증</span
            >
            <span class="text-left ds-b fts14 ftw4 lh24 color-6 mb5"
              >[대리인 추가서류] 계약위임용 인감증명서1부, 위임장(당사양식),
              대리인 신분증 및 도장</span
            >
          </div>
          <div class="complete-box">
            <div class="comin-box">
              <div class="comin-cont">
                <v-checkbox
                  color="primary"
                  v-model="agreeCheck"
                  value="true"
                  label="상기 모든 내용을 확인하였으며, 이에 동의합니다."
                ></v-checkbox>
              </div>
              <div class="comin-cont">
                <strong class="text-left ds-b fts12 ftw5 lh24 color-6 mb5"
                  >동호지정 신청 당일</strong
                >
                <span class="text-left ds-b fts14 ftw5 lh24 color-5"
                  >{{ today_date.substr(0, 4) }}년
                  {{ today_date.substr(5, 2) }}월
                  {{ today_date.substr(8, 2) }}일</span
                >
              </div>
            </div>
          </div>
        </v-form>

        <v-card-actions class="justify-center pd0 mt20">
          <v-btn
            class="pd10 color-2 bgc-4 pwidth48 mr-p4 fts18 ftw7"
            min-height="50"
            @click="back()"
            >뒤로가기</v-btn
          >
          <v-btn
            color="primary"
            class="fts18 ftw7 mw-400 pwidth48 mg0 fts18 ftw7"
            min-height="50"
            @click="regist()"
            v-if="!is_modify"
            >계약신청</v-btn
          >
          <v-btn
            color="primary"
            class="fts18 ftw7 mw-400 pwidth48 mg0 fts18 ftw7"
            min-height="50"
            @click="update()"
            v-if="is_modify"
            >수정</v-btn
          >
        </v-card-actions>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Weblogo from "@/assets/images/common/main-logo.svg";
import {
  getVillageList,
  getRentalContractTypeList,
  postRentalContractRequest,
  getRentalContractRequest,
  delRentalContractRequest,
  putRentalContractRequest,
  getUserData,
} from "@/api/index";
import { getLeaseNewCondition } from "@/api/contract";
import { get_now_string, get_time_string } from "@/utils/time.js";
import Back from "@/assets/images/icon/icon-back.svg";
export default {
  name: "ContractRequestPage",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
      // Post form
      form: {
        user: 0,
        home: 0,
        rental_type: 0, // 구분형, 전환형..
        name: "",
        register_number: "",
        address: "",
        mobile: "",
        phone_number: "",
        email: "",
        village_id: 0,
        dong_no: "",
        ho_no: "",
      },

      // items
      rentalContractTypeList: [],
      villageList: [],

      // Utils
      today_date: get_now_string(),
      contract_date: get_time_string(3),
      register_number1: "",
      register_number2: "",
      agreeCheck: false,
      is_modify: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    signupCheckWarn() {
      return this.$store.state.info.signupcheckwarn;
    },
    signupCheckHomeId() {
      return this.$store.state.info.signupcheckHomeId;
    },
    rentalContractType() {
      if (this.form.rental_type) {
        let item = this.rentalContractTypeList.find(
          (type) => type.DS_OPTION == this.form.rental_type
        );

        if (item) return item;
      }
      return { rental_type: "" };
    },
  },
  watch: {
    signupCheckHomeId() {
      this.form.home = this.signupCheckHomeId;
      this.getLeaseNewConditionAPI(this.signupCheckHomeId);
    },
    register_number1() {
      this.form.register_number = this.register_number1 + this.register_number2;
    },
    register_number2() {
      this.form.register_number = this.register_number1 + this.register_number2;
    },
  },
  methods: {
    // APIs
    getVillageListAPI() {
      getVillageList("kind_code=FOR_RENT&&is_rental_request_enabled=true").then(
        (result) => {
          this.villageList = result.data;
        }
      );
    },
    getRentalContractRequestAPI(id) {
      getRentalContractRequest(id).then((result) => {
        this.form = result.data;
        this.village = result.data;

        this.getLeaseNewConditionAPI(this.form.home);

        this.register_number1 = this.form.register_number.slice(0, 6);
        this.register_number2 = this.form.register_number.slice(6, 7);
      });
    },
    getLeaseNewConditionAPI(home) {
      this.rentalContractTypeList = [];

      if (home) {
        getLeaseNewCondition(home).then((res) => {
          this.rentalContractTypeList = res.data;
          if (this.rentalContractTypeList.length && !this.form.rental_type) {
            this.form.rental_type = this.rentalContractTypeList[0].DS_OPTION;
          }
        });
      }
    },
    init() {
      this.getVillageListAPI();

      if (this.$route.params.request_id) {
        // Edit loaded
        this.is_modify = true;
        this.privacyAgree = true;
        this.getRentalContractRequestAPI(this.$route.params.request_id);
      } else {
        // Create new
        this.form.user = this.user.id;
        this.form.name = this.user.name;
        this.form.mobile = this.user.mobile;
        this.form.email = this.user.email;
      }
    },
    checkSignup() {
      /*
        1. 해당 단지에 존재하는 동, 호 인지 체크.
        2. 해당 세대에 계약자가 존재하는지 체크.
      */
      this.$store.dispatch("SIGNUP_CHECK", this.form);
    },
    // loadTypeList(rental_type) {
    //   this.rentalContractTypeList = [];
    //   this.form.rental_type = rental_type;

    //   if (this.form.home) {
    //     getRentalContractTypeList(`home_id=${this.form.home}`).then(
    //       (result) => {
    //         this.rentalContractTypeList = result.data;
    //         if (result.data.length && !rental_type) {
    //           this.form.rental_type = result.data[0].id;
    //         }
    //       }
    //     );
    //   }
    // },
    changeType() {
      /**
       구분(표준형, 전환형1, 전환형2)이 변경됐을때 event
       */
    },
    regist() {
      if (this.validate()) {
        postRentalContractRequest(this.form)
          .then((result) => {
            this.$store.commit("SUCCESS", "신청되었습니다.");
            this.$router.push({ name: "contractRequestView" });
          })
          .catch((error) => {
            console.log(error.response.data);
          });
      }
    },
    update() {
      if (this.validate()) {
        putRentalContractRequest(this.form.id, this.form)
          .then((result) => {
            this.$store.commit("SUCCESS", "수정되었습니다.");
            this.$router.push({ name: "contractRequestView" });
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
    validate() {
      if (this.signupCheckWarn) {
        this.checkSignup();
        this.$store.commit("ERROR", "정확한 단지, 동, 호수를 입력해주세요.");
        return false;
      } else if (this.form.name == "") {
        this.$store.commit("ERROR", "이름을 입력해주세요.");
        return false;
      } else if (this.form.register_number == "") {
        this.$store.commit("ERROR", "주민등록번호를 입력해주세요.");
        return false;
      } else if (this.form.mobile == "") {
        this.$store.commit("ERROR", "휴대폰 번호를 입력해주세요.");
        return false;
      } else if (!this.agreeCheck) {
        this.$store.commit("ERROR", "약관에 동의해주세요.");
        return false;
      }
      return true;
    },
    back() {
      this.$router.go(-1);
    },
  },
  created() {
    this.init();

    getUserData("field=is_verified_name").then((res) => {
      if (!res.data.is_verified_name) {
        let back = `/villageInfoList`;
        if (this.$route.params.selectVillageId) {
          back = `/villageInfo/${this.$route.params.selectVillageId}`;
        }

        this.$store.commit("SET_USERVERIFICATIONINFO", {
          dialog: true,
          text: "계약신청 메뉴 이용을 위해선 실명인증이 필요합니다.",
          redirect: "/contractRequest",
          back: back,
          only_verified_name: true,
        });
      } else if (this.$route.params.selectVillageId != null) {
        this.form.village_id = this.$route.params.selectVillageId;
      }
    });
  },
  filters: {
    comma(text) {
      return String(text).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style>
.v-input__slot {
  margin-bottom: 5px;
}
.v-input--selection-controls {
  margin: 0;
  padding: 0;
}
.v-messages {
  min-height: 0;
}
</style>
