<template>
  <v-container class="bg-gray vh-footer pd20">
    <div class="location-box np">
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">{{ reservationMaster.title }}</strong>
    </div>

    <div>
      <ul
        class="resinfo-list"
        v-if="reservationMaster.description"
        style="max-width: 700px;margin: auto;margin-top:50px"
      >
        <li class="ril-text" v-html="reservationMaster.description"></li>
      </ul>
    </div>
    <!-- 
    <v-card-subtitle
      class="fts14 lh24"
      v-html="reservationMaster.description"
    ></v-card-subtitle> -->
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text>
        <div style="padding-bottom: 100px">
          <!-- TODO LYN-895: 예약에 관한 설명을 나타내는 필드. 완료 by bong
          <ul class="resinfo-list" v-if="reservationMaster.description">
            <li class="ril-text" v-html="reservationMaster.description">
            </li>
          </ul>
          -->
          <v-form>
            <v-text-field
              type="text"
              :value="hometext"
              label="방문 단지"
              readonly
            ></v-text-field>
            <v-subheader class="pd0">방문일자</v-subheader>
            <v-date-picker
              v-model="form.date"
              :allowed-dates="allowedDates"
              @change="sync"
              :picker-date.sync="pickerDate"
              :day-format="(date) => new Date(date).getDate()"
              locale="KR"
              full-width
              no-title
            ></v-date-picker>
            <v-subheader class="pd0">방문시간</v-subheader>
            <v-slide-group :value="allowed_times.indexOf(form.timeinfo)">
              <v-slide-item
                class="mr10 nh-50"
                v-for="timeinfo in allowed_times"
                :key="timeinfo.id"
                v-slot="{ active, toggle }"
              >
                <v-btn
                  :value="timeinfo"
                  :disabled="timeinfo.disabled"
                  :input-value="active"
                  active-class="v-active"
                  v-bind:class="[
                    {
                      'v-active':
                        timeinfo.id === form.timeinfo.id &&
                        form.date === sel_date &&
                        is_modify,
                    },
                    'pd10 color-2 bgc-3',
                  ]"
                  depressed
                  @click="
                    toggle;
                    form.timeinfo = timeinfo;
                    sel_date = '';
                  "
                >
                  {{ timeinfo.text }} <br />
                  {{ timeinfo.tooltip }}
                </v-btn>
              </v-slide-item>
            </v-slide-group>

            <v-text-field
              v-model="form.name"
              label="이름"
              required
              readonly
            ></v-text-field>
            <v-text-field
              type="number"
              v-model="form.contact"
              label="방문자 전화번호"
              :counter="12"
              required
            ></v-text-field>
          </v-form>
          <v-btn
            color="primary"
            class="fts18 ftw7 mw-400 pwidth100 mt15"
            min-height="50"
            v-if="!is_modify"
            block
            :disabled="!is_form_ready"
            @click="postReservationAPI"
          >
            방문 예약하기
          </v-btn>
          <v-btn
            color="primary"
            class="fts18 ftw7 mw-400 pwidth100 mt15"
            min-height="50"
            v-if="is_modify"
            block
            :disabled="!is_form_ready"
            @click="putReservationAPI"
          >
            수정하기
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import { get_now_string } from "@/utils/time.js";
import {
  getReservationMasterList,
  getReservationMaster,
  postReservation,
  getReservation,
  putReservation,
  delReservation,
} from "@/api/index.js";
export default {
  name: "CommonReservationForm",
  components: {
    Back,
  },
  data() {
    return {
      reservation_code: "", // 계약자 방문예약 타입
      form: {
        master: 0, // 예약마스터 id
        user: 0, // 유저 id
        time: 0, // 선택한 차수 id
        home: 0,
        date: get_now_string(), // 선택한 일자
        timeinfo: { text: "" }, // 선택한 차수 정보
        name: "",
        contact: "",
      },
      menu: false,
      pickerDate: null,
      village: {},
      allowed_dates: [],
      allowed_times: [],
      is_modify: false,
      sel_date: "", // (수정의 경우) 저장된 날짜
      hometext: "",

      reservationID: 0,
      masterId: 0,
      selectedHome: 0,

      reservationMaster: {},
    };
  },
  watch: {
    pickerDate(newval, oldval) {
      this.next_prev_month(newval);
    },
  },
  computed: {
    formed_time() {
      return `${this.form.date} ${this.form.timeinfo.text}`;
    },
    is_form_ready() {
      return (
        this.form.master &&
        this.form.date &&
        this.form.timeinfo.id &&
        this.form.name &&
        this.form.contact &&
        this.allowed_dates.includes(this.form.date)
      );
    },
    user() {
      return this.$store.getters.getUser;
    },
    home() {
      return this.$store.getters.getHome;
    },
  },

  methods: {
    // APIs
    init() {
      if (this.masterId) {
        this.getMasterAPI(this.masterId);
        this.form.user = this.user.id;
        this.form.name = this.user.name;
        this.form.contact = this.user.mobile;
      } else {
        // 자동완성 및 사용자의 단지정볼르 가져오기 위한 API
        if (this.reservationID) {
          this.getReservationAPI(this.reservationID);
          // this.getReservationListAPI(this.user.id);
        } else {
          // 유저 정보 세팅
          this.form.user = this.user.id;
          this.form.name = this.user.name;
          this.form.contact = this.user.mobile;
          this.village = this.home.village;
        }

        // Master id 를 지정한 예약 -> 당첨자, 예비당첨자
        this.getMasterListAPI(this.user.id);
      }
    },
    getReservationAPI(id) {
      getReservation(id)
        .then((re) => {
          this.is_modify = true;
          this.form.contact = re.data.contact;
          this.form.name = re.data.name;
          this.form.master = re.data.master;
          this.form.timeinfo = re.data.timeinfo;
          this.form.date = re.data.date;
          this.form.time = re.data.time;
          this.sel_date = re.data.date;
          this.form.id = re.data.id;
          this.form.home = re.data.home;
          this.form.user = re.data.user;
          this.hometext = re.data.village_name;

          this.getReservationDetailAPI();
        })
        .catch((er) => {});
    },
    delReservationAPI() {
      /*
                delete
                TODO: 예약 자체를 삭제가 아닌, 삭제 필드를 만들어 patch 해야할듯.
                      현재는 테스트용으로 레코드 삭제.
            */
      delReservation(this.form.id);
    },
    getMasterAPI(master_id) {
      this.allowed_dates = [];
      getReservationMaster(master_id).then((re) => {
        if (re.data.is_reservable && !re.data.is_reserved) {
          this.reservationMaster = re.data;
          this.form.master = re.data.id;
          this.hometext = re.data.village_name;
          this.village = re.data.village;
          this.getReservationDetailAPI();
        } else {
          this.hometext = "예약가능한 단지 정보가 없습니다.";
          this.$router.push({
            name: "commonReservationView",
            params: { reservation_code: re.data.kind_code },
          });
        }
      });
    },
    getMasterListAPI(user_id) {
      // 해당 단지에서 진행중인 사전점검 예약의 최신본을 가져온다.

      this.allowed_dates = [];

      let query = `user_id=${user_id}&&code=${
        this.reservation_code
      }&&date=${get_now_string()}`;

      if (
        this.home &&
        ![
          "WIN_RESERVATION",
          "PRE_WIN_RESERVATION",
          "MOVEIN_WIN_RESERVATION",
        ].includes(this.reservation_code)
      ) {
        query += `&&home=${this.home.id}`;
      }

      getReservationMasterList(query).then((re) => {
        if (re.data.length) {
          this.reservationMaster = re.data[0];
          this.form.master = re.data[0].id;
          this.getReservationDetailAPI();
          this.hometext = this.home.village_name;
        } else {
          this.$store.commit("ERROR", "예약가능한 단지 정보가 없습니다.");
          this.hometext = "예약가능한 단지 정보가 없습니다.";
        }
      });
    },
    getReservationDetailAPI(custom_date) {
      // 특정 요청한 날짜의 예약마스터의 상세정보(예약가능 시간 등)을 불러온다.
      if (!this.form.master) return;

      let query;
      if (custom_date) {
        query = `date=${custom_date}`;
      } else {
        if (!this.form.date) return "";
        query = `date=${this.form.date}`;
        this.pickerDate = this.form.date.slice(0, 7);
      }

      return getReservationMaster(this.form.master, query).then((re) => {
        this.allowed_dates = re.data.allowed_dates;
        if (!custom_date) {
          this.allowed_times = re.data.allowed_times;

          // 가능한 첫 시간을 지정한다.
          for (let idx in this.allowed_times) {
            if (this.form.time) {
              if (this.form.time == this.allowed_times[idx].id) {
                // 수정 시, 선택 된 시간으로 스크롤.
                this.form.timeinfo = this.allowed_times[idx];
                break;
              }
            } else if (!this.allowed_times[idx].disabled) {
              this.form.timeinfo = this.allowed_times[idx];
              break;
            }
          }

          let sync_done = false;
        }
      });
    },
    postReservationAPI() {
      if (this.is_form_ready) {
        this.form.time = this.form.timeinfo.id;
        if (this.masterId) {
          if (this.selectedHome) {
            this.form.home = this.selectedHome;
          } else {
            this.form.home = null;
          }
        } else {
          this.form.home = this.home.id;
        }
        postReservation(this.form)
          .then((re) => {
            this.$store.commit("SUCCESS", "예약 되었습니다.");
            this.$router.push({
              name: "commonReservationView",
              params: { id: re.data.id, reservation_code: re.data.kind_code },
            });
          })
          .catch((er) => {
            let errors = er.response.data.non_field_errors;
            if (errors) {
              if (errors.length) {
                this.$store.commit("ERROR", errors[0]);
                this.getReservationDetailAPI();
              }
            }
          });
      }
    },
    putReservationAPI() {
      if (this.is_form_ready) {
        this.form.time = this.form.timeinfo.id;
        putReservation(this.form.id, this.form)
          .then((re) => {
            this.$store.commit("SUCCESS", "수정 되었습니다.");
            this.$router.push({
              name: "commonReservationView",
              params: { reservation_code: this.reservation_code },
            });
          })
          .catch((er) => {
            let errors = er.response.data.non_field_errors;
            if (errors) {
              if (errors.length) {
                this.$store.commit("ERROR", errors[0]);
                this.getReservationDetailAPI();
              }
            }
          });
      }
    },
    // Utils
    allowedDates(val) {
      return this.allowed_dates.indexOf(val) !== -1;
    },
    sync() {
      this.getReservationDetailAPI(null);
    },
    next_prev_month(newval) {
      this.getReservationDetailAPI(`${newval}-01`);
    },
  },
  created() {
    if (this.$route.params.master_id) {
      if (
        this.$route.params.is_verified === "Y" &&
        this.$route.params.verified_date === get_now_string(true)
      ) {
        this.masterId = Number(this.$route.params.master_id);
        this.selectedHome = Number(this.$route.params.home);
        this.init();
      } else {
        this.$router.push({
          name: "reservationList",
        });
      }
    } else {
      this.reservation_code = this.$route.params.reservation_code;
      this.reservationID = Number(this.$route.params.id);
      this.init();
    }
  },
};
</script>
