<template>
  <v-container class="vh-footer pd20 lynn-web-in overout bg-gray">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.push({ name: 'dwelling' })" class="back">
        <Back />
      </a>
      <strong class="lo-title">관리비</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops bg-gray" style="border-radius:0;">
      <v-card-text class="pd0 pt24">
				<div class="exp-pointbox">
					<div class="expp-month">
						<a href="#n" class="expp-prev"><Prev/></a>
						<strong>2021년 4월</strong>
						<a href="#n" class="expp-next"><Next/></a>
					</div>
					<div class="expp-cont">
						<div class="expp-top">
							<strong>312,000</strong>
							<p>2021/04/31 까지 납부</p>
						</div>
						<div class="expp-bottom">
							<div class="expp-pmonth">
								<strong>전월 비교</strong>
								<p class="up">+12,000</p>
							</div>
							<div class="expp-dmonth">
								<strong>동일면적 평균 비교</strong>
								<p class="down">25.12% 낮음</p>
							</div>
						</div>
						<router-link to="/ExpensesDetail" class="expp-detail">상세내역 보기</router-link>
					</div>
				</div>
				<strong class="t-cont-title ftw7 mb10">관리비 통계</strong>
				<div class="exp-graphbox">
					<div class="expg-linebox">
						<strong class="expg-title">전년동월 비교</strong>
						<div class="expg-top">
							<strong>
								<span class="left">올해 4월</span>
								<span class="price">320,000</span>
							</strong>
							<div class="proline" style="width:90%"></div>
						</div>
						<div class="expg-bottom">
							<div class="proline" style="width:100%"></div>
							<strong>
								<span class="left">작년 4월</span>
								<span class="price">300,000</span>
							</strong>
						</div>
					</div>
					<div class="expg-gpbox">
						<strong class="expg-title">최근 3개월</strong>
						<ul class="expg-gpcont">
							<li>
								<div class="graph">
									<span style="height:100%;"></span>
								</div>
								<strong>2월</strong>
								<p>2,320,000</p>
							</li>
							<li>
								<div class="graph">
									<span style="height:80%;"></span>
								</div>
								<strong>3월</strong>
								<p>1,820,000</p>
							</li>
							<li>
								<div class="graph">
									<span style="height:90%;" class="this"></span>
								</div>
								<strong>4월</strong>
								<p>2,000,000</p>
							</li>
						</ul>
					</div>
					<a href="#n" class="expg-detail" v-on:click="onChange=!onChange" v-if="onChange == false">최근 12개월 내역 보기</a>
					<div class="expg-yaerbox" v-if="onChange == true">
						<strong class="expg-title">최근 12개월</strong>
						<ul class="expg-yearline">
							<li>
								<div class="graph">
									<span style="height:79%;"></span>
								</div>
								<strong>5</strong>
								<span class="yearstart">2020</span>
							</li>
							<li>
								<div class="graph">
									<span style="height:85%;"></span>
								</div>
								<strong>6</strong>
							</li>
							<li>
								<div class="graph">
									<span style="height:85%;"></span>
								</div>
								<strong>7</strong>
							</li>
							<li>
								<div class="graph">
									<span style="height:84%;"></span>
								</div>
								<strong>8</strong>
							</li>
							<li>
								<div class="graph">
									<span style="height:79%;"></span>
								</div>
								<strong>9</strong>
							</li>
							<li>
								<div class="graph">
									<span style="height:83%;"></span>
								</div>
								<strong>10</strong>
							</li>
							<li>
								<div class="graph">
									<span style="height:82%;"></span>
								</div>
								<strong>11</strong>
							</li>
							<li>
								<div class="graph">
									<span style="height:89%;"></span>
								</div>
								<strong>12</strong>
							</li>
							<li>
								<div class="graph">
									<span style="height:91%;"></span>
								</div>
								<strong>1</strong>
								<span class="yearstart">2021</span>
							</li>
							<li>
								<div class="graph">
									<span style="height:74%;"></span>
								</div>
								<strong>2</strong>
							</li>
							<li>
								<div class="graph">
									<span style="height:81%;"></span>
								</div>
								<strong>3</strong>
							</li>
							<li>
								<div class="graph">
									<span style="height:69%;" class="this"></span>
								</div>
								<strong>4</strong>
							</li>
						</ul>
						<div class="expg-yeartable">
							<div class="left">
								<table>
									<colgroup>
										<col width="50%"/>
										<col width="50%"/>
									</colgroup>
									<tr>
										<th>2020/5</th>
										<td>300,000</td>
									</tr>
									<tr>
										<th>2020/6</th>
										<td>300,000</td>
									</tr>
									<tr>
										<th>2020/7</th>
										<td>300,000</td>
									</tr>
									<tr>
										<th>2020/8</th>
										<td>300,000</td>
									</tr>
									<tr>
										<th>2020/9</th>
										<td>300,000</td>
									</tr>
									<tr>
										<th>2020/10</th>
										<td>300,000</td>
									</tr>
								</table>
							</div>
							<div class="right">
								<table>
									<colgroup>
										<col width="50%"/>
										<col width="50%"/>
									</colgroup>
									<tr>
										<th>2020/11</th>
										<td>300,000</td>
									</tr>
									<tr>
										<th>2020/12</th>
										<td>300,000</td>
									</tr>
									<tr>
										<th>1</th>
										<td>300,000</td>
									</tr>
									<tr>
										<th>2</th>
										<td>300,000</td>
									</tr>
									<tr>
										<th>3</th>
										<td>300,000</td>
									</tr>
									<tr>
										<th>4</th>
										<td>300,000</td>
									</tr>
								</table>
							</div>
						</div>
						<a href="#n" class="expg-detail" v-on:click="onChange=!onChange">접기</a>
					</div>
				</div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import Prev from "@/assets/images/Expenses/prev.svg";
import Next from "@/assets/images/Expenses/next.svg";

export default {
  name: "LoungeList",
  components: {
    Back,
    Weblogo,
		Prev,
		Next,
  },
  data() {
    return {
			onChange:false,
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>
