<template>
  <v-container class="bg-gray vh-footer pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.push({ name: 'dwelling' })" class="back">
        <Back />
      </a>
      <strong class="lo-title">편의시설</strong>
    </div>
    <FacilityGrid />
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import Vote from "@/assets/images/btn/btn-vote.svg";
import FacilityGrid from "@/components/Dwelling/FacilityGrid.vue";
import { getFacilityList } from "@/api/dwelling";

export default {
  name: "FacilityHistory",
  components: {
    Back,
    Weblogo,
    FacilityGrid,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    getFacilityListAPI() {
      getFacilityList("").then((res) => {
        console.log(res.data);
        this.facilityList = res.data;
      });
    },
  },
  created() {
    this.getFacilityListAPI();
  },
};
</script>
