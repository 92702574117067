import Vue from "vue";
import VueRouter from "vue-router";

import { userRoutes } from "./user.js";
import { csCenterRoutes } from "./cscenter.js";
import { publishRoutes } from "./publish.js";
import { contractRoutes } from "./contract.js";
import { dwellingRoutes } from "./dwelling.js";
import { villageRoutes } from "./village.js";

import intro from "@/views/Intro.vue";
import hub from "@/views/HubIntro.vue";
import store from "@/store"; // Should be imported at lastline.

/*
    meta.requiresAuth = true (로그인이 필요한 페이지 지정)
    meta.bottomNav = true (bottomNav 가 필요한 페이지 지정)
    activeMenu: "메뉴명" (하단 bottom nav 활성화 메뉴 지정)
    contract : 계약, homein : 입주, dwelling : 주거, mypage : 마이페이지
    info : 단지정보, schedule : 분양일정, introduce : 스마트린 소개 
*/

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "intro",
    component: intro,
  },{
    path: "/hub",
    name: "hub",
    component: hub,
    meta: {
      bottomNav: true,
    },
  },
]
  .concat(publishRoutes)
  .concat(userRoutes)
  .concat(csCenterRoutes)
  .concat(contractRoutes)
  .concat(dwellingRoutes)
  .concat(villageRoutes);

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
  mode: "history", // 해시(#) 제거
});

router.beforeEach((to, from, next) => {
  /*  
    CASE_1: 로그인이 필요한 페이지 체크
        TODO    : meta.requiresAuth = true 
        comment : 로그인이 필요한 페이지에서 로그인이 되어있나(...isLoggedIn == true) 체크
                  및 미로그인 되어 있을 시 Login 페이지로 이동시킴.
 */
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.auth.isLoggedIn) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
  }

  // 뒤로가기 등으로 화면 이동한 경우, Bottom nav가 바뀌지 않는 현상이 있어 화면 전환 시 activeMenu에 따라 switchMode를 고정
  var contract_menu = ["contract", "homein", "dwelling"];
  var info_menu = ["info", "schedule", "introduce"];
  if (contract_menu.includes(to.meta.activeMenu)) {
    if(to.name != 'moveInReservationForm' && to.name != 'moveInReservationView'){
      store.state.switchMode = true;
    }
  } else if (info_menu.includes(to.meta.activeMenu)) {
    store.state.switchMode = false;
  }

  next();
});

export default router;
