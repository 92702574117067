export function copy(val) {
  return JSON.parse(JSON.stringify(val))
}

// export function downloadByURLwithFetch(url, filename) {
//   fetch(url).then(function(t) {
//     return t.blob().then(b => {
//       var a = document.createElement('a')
//       a.href = URL.createObjectURL(b)
//       a.setAttribute('download', filename)
//       a.click()
//     })
//   })
// }

export function downloadByURL(url, filename) {
  var a = document.createElement('a')
  a.setAttribute(
    'href',
    url
  )
  a.setAttribute('download', filename)
  a.click()
}
