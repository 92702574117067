<template>
  <v-container class="pd16" v-if="noMsg">
    <strong class="t-cont-title text-center mt-10 mb-10"
      >조회 내용이 없습니다.</strong
    >
  </v-container>
  <v-container
    class="pd16"
    v-touch="{
      left: () => swipe('Left'),
      right: () => swipe('Right'),
    }"
    v-else
  >
    <strong class="t-cont-title">보증금 납부 현황</strong>
    <div
      class="openbox-item2"
      v-for="(depositInfo, idx) in depositInfoData.downs"
      :key="'down_' + idx"
    >
      <v-list-group :value="false">
        <template v-slot:activator>
          <strong class="ob-title"
            >계약금 {{ depositInfo.count == 0 ? "" : depositInfo.count + "차" }}
            {{
              depositInfo.paidAmount > 0 && !depositInfo.calculated
                ? depositInfo.NO_BUNNAP == "99"
                  ? "(완납)"
                  : "(" + depositInfo.NO_BUNNAP + ")"
                : ""
            }}
          </strong>
        </template>
        <div sub-group>
          <!-- <table class="open-table table1">
            <colgroup>
              <col width="33%" />
              <col width="67%" />
            </colgroup>

            <tr>
              <th>금액</th>
              <td>{{ depositInfo.balance | comma }}</td>
            </tr>
            <tr>
              <th>납기일</th>
              <td>{{ depositInfo.paymentDate }}</td>
            </tr>
          </table> -->

          <table class="open-table table1">
            <colgroup>
              <col width="33%" />
              <col width="67%" />
            </colgroup>
            <tr>
              <th>약정일자</th>
              <td>{{ depositInfo.paymentDate }}</td>
            </tr>
            <tr>
              <th>약정금액</th>
              <td>{{ depositInfo.balance | comma }}</td>
            </tr>
          </table>

          <table class="open-table table1">
            <colgroup>
              <col width="50%" />
              <col width="50%" />
            </colgroup>
            <tr v-if="depositInfo.discount != 0 || depositInfo.delayedFee != 0">
              <th>적용일수</th>
              <td>{{ depositInfo.DD_DAY }}</td>
            </tr>
            <tr>
              <th>연체금액</th>
              <td>{{ depositInfo.delayedFee | comma }}</td>
            </tr>
            <tr>
              <th>할인금액</th>
              <td>
                {{ depositInfo.discount | comma }}
              </td>
            </tr>
            <tr>
              <th v-if="!depositInfo.calculated">납부일자</th>
              <th v-if="depositInfo.calculated">납부예정일자</th>
              <td>
                {{ depositInfo.paidAmount > 0 ? depositInfo.payDate : "-" }}
              </td>
            </tr>
            <tr>
              <th v-if="!depositInfo.calculated">납부금액</th>
              <th v-if="depositInfo.calculated">납부예정금액</th>
              <td>
                {{ depositInfo.paidAmount | comma }}
              </td>
            </tr>
            <tr v-if="depositInfo.calculated && depositInfo.janaeck != 0">
              <th>잔액</th>
              <td>{{ depositInfo.janaeck | comma }}</td>
            </tr>
          </table>

          <table
            class="open-table table1"
            v-if="depositInfo.calculated || depositInfo.payDate == null"
          >
            <colgroup>
              <col width="33%" />
              <col width="67%" />
            </colgroup>
            <tr>
              <th colspan="2" class="fts16">
                <span class="lh20 ds-ib v_m">납부금 계산</span>
                <QueIcon @click="show = !show" class="ml3 mt2" />
                <v-tooltip top v-model="show">
                  <template v-slot:activator="{ on, attrs }">
                    <span class="lh20" v-bind="attrs" v-on="on">&nbsp;</span>
                  </template>
                  <span class="text-left ds-b">
                    납부일자에 따라 연체금액이 발생할 수 있습니다. 납부가능한
                    일자에 맞춰 조회하시기 바랍니다.
                  </span>
                </v-tooltip>
              </th>
            </tr>
            <tr>
              <th>납부예정일자</th>
              <td>
                {{ payDate[idx] }}
                <!-- 연체료 계산 달력 -->
                <v-menu
                  v-model="menu[idx]"
                  :close-on-content-click="false"
                  max-width="290"
                  class="ds-ib"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.409"
                      height="14.409"
                      viewBox="0 0 14.409 14.409"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <g
                        id="Icon_feather-search"
                        data-name="Icon feather-search"
                        transform="translate(-3.5 -3.5)"
                      >
                        <path
                          id="패스_588"
                          data-name="패스 588"
                          d="M15.162,9.831A5.331,5.331,0,1,1,9.831,4.5,5.331,5.331,0,0,1,15.162,9.831Z"
                          transform="translate(0 0)"
                          fill="none"
                          stroke="#da581f"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <path
                          id="패스_589"
                          data-name="패스 589"
                          d="M27.874,27.874l-2.9-2.9"
                          transform="translate(-11.379 -11.379)"
                          fill="none"
                          stroke="#da581f"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                  </template>
                  <v-date-picker
                    v-model="payDate[idx]"
                    @change="menu[idx] = false"
                    :day-format="(date) => new Date(date).getDate()"
                    :min="today"
                    locale="KR"
                    full-width
                    no-title
                  ></v-date-picker>
                </v-menu>
              </td>
            </tr>
            <tr>
              <th>예정입금액</th>
              <td>
                <v-text-field
                  v-model="userPayment[idx]"
                  class="twidth180"
                  placeholder="미 입력시 잔액기준 조회됨"
                  @keyup="inputPayment('down', idx)"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <th class="pl5 pb10 pl5" colspan="2">
                <a href="#n" @click="calculater('down', idx)" class="sub-btn"
                  >조회</a
                >
              </th>
            </tr>
          </table>
        </div>
      </v-list-group>
    </div>
    <div
      class="openbox-item2"
      v-for="(depositInfo, idx) in depositInfoData.seconds"
      :key="'second_' + idx"
    >
      <v-list-group :value="false">
        <template v-slot:activator>
          <strong class="ob-title"
            >중도금 {{ depositInfo.count == 0 ? "" : depositInfo.count + "차" }}
            {{
              depositInfo.paidAmount > 0 && !depositInfo.calculated
                ? depositInfo.NO_BUNNAP == "99"
                  ? "(완납)"
                  : "(" + depositInfo.NO_BUNNAP + ")"
                : ""
            }}
          </strong>
          <!-- TODO: 중도금이랑 잔금도 계약금하고 같은 정보가 보여야 하는 것 아닌가? -->
        </template>
        <div sub-group>
          <table class="open-table table1">
            <colgroup>
              <col width="33%" />
              <col width="67%" />
            </colgroup>
            <tr>
              <th>약정일자</th>
              <td>{{ depositInfo.paymentDate }}</td>
            </tr>
            <tr>
              <th>약정금액</th>
              <td>{{ depositInfo.balance | comma }}</td>
            </tr>
          </table>

          <table class="open-table table1">
            <colgroup>
              <col width="50%" />
              <col width="50%" />
            </colgroup>
            <tr v-if="depositInfo.discount != 0 || depositInfo.delayedFee != 0">
              <th>적용일수</th>
              <td>{{ depositInfo.DD_DAY }}</td>
            </tr>
            <tr>
              <th>연체금액</th>
              <td>{{ depositInfo.delayedFee | comma }}</td>
            </tr>
            <tr>
              <th>할인금액</th>
              <td>
                {{ depositInfo.discount | comma }}
              </td>
            </tr>
            <tr>
              <th v-if="!depositInfo.calculated">납부일자</th>
              <th v-if="depositInfo.calculated">납부예정일자</th>
              <td>
                {{ depositInfo.paidAmount > 0 ? depositInfo.payDate : "-" }}
              </td>
            </tr>
            <tr>
              <th v-if="!depositInfo.calculated">납부금액</th>
              <th v-if="depositInfo.calculated">납부예정금액</th>
              <td>
                {{ depositInfo.paidAmount | comma }}
              </td>
            </tr>
            <tr v-if="depositInfo.calculated && depositInfo.janaeck != 0">
              <th>잔액</th>
              <td>{{ depositInfo.janaeck | comma }}</td>
            </tr>
          </table>

          <table
            class="open-table table1"
            v-if="depositInfo.calculated || depositInfo.payDate == null"
          >
            <colgroup>
              <col width="33%" />
              <col width="67%" />
            </colgroup>
            <tr>
              <th colspan="2" class="fts16">
                <span class="lh20 ds-ib v_m">납부금 계산</span>
                <QueIcon @click="show = !show" class="ml3 mt2" />
                <v-tooltip top v-model="show">
                  <template v-slot:activator="{ on, attrs }">
                    <span class="lh20" v-bind="attrs" v-on="on">&nbsp;</span>
                  </template>
                  <span class="text-left ds-b">
                    납부일자에 따라 연체금액이 발생할 수 있습니다. 납부가능한
                    일자에 맞춰 조회하시기 바랍니다.
                  </span>
                </v-tooltip>
              </th>
            </tr>
            <tr>
              <th>납부예정일자</th>
              <td>
                {{ payDate_s[idx] }}
                <!-- 연체료 계산 달력 -->
                <v-menu
                  v-model="menu_s[idx]"
                  :close-on-content-click="false"
                  max-width="290"
                  class="ds-ib"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.409"
                      height="14.409"
                      viewBox="0 0 14.409 14.409"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <g
                        id="Icon_feather-search"
                        data-name="Icon feather-search"
                        transform="translate(-3.5 -3.5)"
                      >
                        <path
                          id="패스_588"
                          data-name="패스 588"
                          d="M15.162,9.831A5.331,5.331,0,1,1,9.831,4.5,5.331,5.331,0,0,1,15.162,9.831Z"
                          transform="translate(0 0)"
                          fill="none"
                          stroke="#da581f"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <path
                          id="패스_589"
                          data-name="패스 589"
                          d="M27.874,27.874l-2.9-2.9"
                          transform="translate(-11.379 -11.379)"
                          fill="none"
                          stroke="#da581f"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                  </template>
                  <v-date-picker
                    v-model="payDate_s[idx]"
                    @change="menu_s[idx] = false"
                    :day-format="(date) => new Date(date).getDate()"
                    :min="today"
                    locale="KR"
                    full-width
                    no-title
                  ></v-date-picker>
                </v-menu>
              </td>
            </tr>
            <tr>
              <th>예정입금액</th>
              <td>
                <v-text-field
                  v-model="userPayment_s[idx]"
                  @keyup="inputPayment('second', idx)"
                  class="twidth180"
                  placeholder="미 입력시 잔액기준 조회됨"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <th class="pl5 pb10 pl5" colspan="2">
                <a href="#n" @click="calculater('second', idx)" class="sub-btn"
                  >조회</a
                >
              </th>
            </tr>
          </table>
        </div>
      </v-list-group>
    </div>
    <div
      class="openbox-item2"
      v-for="(depositInfo, idx) in depositInfoData.balance"
      :key="'balance_' + idx"
    >
      <v-list-group :value="false">
        <template v-slot:activator>
          <strong class="ob-title"
            >잔금 {{ depositInfo.count == 0 ? "" : depositInfo.count + "차" }}
            {{
              depositInfo.paidAmount > 0 && !depositInfo.calculated
                ? depositInfo.NO_BUNNAP == "99"
                  ? "(완납)"
                  : "(" + depositInfo.NO_BUNNAP + ")"
                : ""
            }}
          </strong>
        </template>
        <div sub-group>
          <table class="open-table table1">
            <colgroup>
              <col width="33%" />
              <col width="67%" />
            </colgroup>
            <tr>
              <th>약정일자</th>
              <td>{{ depositInfo.paymentDate }}</td>
            </tr>
            <tr>
              <th>약정금액</th>
              <td>{{ depositInfo.balance | comma }}</td>
            </tr>
          </table>

          <table class="open-table table1">
            <colgroup>
              <col width="50%" />
              <col width="50%" />
            </colgroup>
            <tr v-if="depositInfo.discount != 0 || depositInfo.delayedFee != 0">
              <th>적용일수</th>
              <td>{{ depositInfo.DD_DAY }}</td>
            </tr>
            <tr>
              <th>연체금액</th>
              <td>{{ depositInfo.delayedFee | comma }}</td>
            </tr>
            <tr>
              <th>할인금액</th>
              <td>
                {{ depositInfo.discount | comma }}
              </td>
            </tr>
            <tr>
              <th v-if="!depositInfo.calculated">납부일자</th>
              <th v-if="depositInfo.calculated">납부예정일자</th>
              <td>
                {{ depositInfo.paidAmount > 0 ? depositInfo.payDate : "-" }}
              </td>
            </tr>
            <tr>
              <th v-if="!depositInfo.calculated">납부금액</th>
              <th v-if="depositInfo.calculated">납부예정금액</th>
              <td>
                {{ depositInfo.paidAmount | comma }}
              </td>
            </tr>
            <tr v-if="depositInfo.calculated && depositInfo.janaeck != 0">
              <th>잔액</th>
              <td>{{ depositInfo.janaeck | comma }}</td>
            </tr>
          </table>

          <table
            class="open-table table1"
            v-if="(depositInfo.payDate == null) | depositInfo.calculated"
          >
            <colgroup>
              <col width="33%" />
              <col width="67%" />
            </colgroup>
            <tr>
              <th colspan="2" class="fts16">
                <span class="lh20 ds-ib v_m mb5">납부금 계산</span>
                <span class="fts12 lh18 text-left ds-b">
                  납부일자에 따라 연체금액이 발생할 수 있습니다. 납부가능한
                  일자에 맞춰 조회하시기 바랍니다.
                </span>
                <!-- <QueIcon @click="show = !show" class="ml3 mt2" />
                <v-tooltip top v-model="show">
                  <template v-slot:activator="{ on, attrs }">
                    <span class="lh20" v-bind="attrs" v-on="on">&nbsp;</span>
                  </template>
                  <span class="text-left ds-b">
                    납부일자에 따라 할인금액 및 연체금액이 발생할 수 있습니다.
                    납부가능한 일자에 맞춰 조회하시기 바랍니다.
                  </span>
                </v-tooltip> -->
              </th>
            </tr>
            <tr>
              <th>납부예정일자</th>
              <td>
                {{ payDate_b[idx] }}
                <!-- 연체료 계산 달력 -->
                <v-menu
                  v-model="menu_b[idx]"
                  :close-on-content-click="false"
                  max-width="290"
                  class="ds-ib"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.409"
                      height="14.409"
                      viewBox="0 0 14.409 14.409"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <g
                        id="Icon_feather-search"
                        data-name="Icon feather-search"
                        transform="translate(-3.5 -3.5)"
                      >
                        <path
                          id="패스_588"
                          data-name="패스 588"
                          d="M15.162,9.831A5.331,5.331,0,1,1,9.831,4.5,5.331,5.331,0,0,1,15.162,9.831Z"
                          transform="translate(0 0)"
                          fill="none"
                          stroke="#da581f"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <path
                          id="패스_589"
                          data-name="패스 589"
                          d="M27.874,27.874l-2.9-2.9"
                          transform="translate(-11.379 -11.379)"
                          fill="none"
                          stroke="#da581f"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                  </template>
                  <v-date-picker
                    v-model="payDate_b[idx]"
                    @change="menu_b[idx] = false"
                    :day-format="(date) => new Date(date).getDate()"
                    :min="today"
                    locale="KR"
                    full-width
                    no-title
                  ></v-date-picker>
                </v-menu>
              </td>
            </tr>
            <tr>
              <th>예정입금액</th>
              <td>
                <v-text-field
                  v-model="userPayment_b[idx]"
                  @keyup="inputPayment('balance', idx)"
                  placeholder="미 입력시 잔액기준 조회됨"
                  class="twidth180"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <th class="pl5 pb10 pl5" colspan="2">
                <a href="#n" @click="calculater('balance', idx)" class="sub-btn"
                  >조회</a
                >
              </th>
            </tr>
          </table>
        </div>
      </v-list-group>
    </div>
    <strong class="t-cont-title">납부처 및 납부방법</strong>
    <table class="t-table table1 mb15">
      <colgroup>
        <col width="95px" />
        <col width="*" />
      </colgroup>
      <tr>
        <th>납부기관</th>
        <td>{{ depositInfoData.bankName }}</td>
      </tr>
      <tr>
        <th>납부계좌</th>
        <td>
          {{
            depositInfoData.bankAccount == null ||
            depositInfoData.bankAccount == ""
              ? "-"
              : depositInfoData.bankAccount
          }}
        </td>
      </tr>
      <tr>
        <th>예금주</th>
        <td>{{ depositInfoData.accountHolder }}</td>
      </tr>
    </table>
  </v-container>
</template>

<style>
.v-tooltip__content {
  width: calc(100% - 24px);
}
</style>

<script>
import QueIcon from "@/assets/images/btn/btn-deposit.svg"; //계약현황
import {
  getLeaseDepositInfo,
  getChargesCalculator,
  getContract,
} from "@/api/contract.js";
import { get_now_string } from "@/utils/time.js";

export default {
  components: {
    QueIcon,
  },
  name: "SetContracting",
  data() {
    return {
      show: false,
      noMsg: false,
      today: get_now_string(),
      depositInfoData: {},
      // 계약금 검색 관련
      userPayment: ["", "", ""],
      payDate: [get_now_string(), get_now_string(), get_now_string()],
      menu: [false, false, false],
      // 중도금 검색 관련
      userPayment_s: ["", "", ""],
      payDate_s: [get_now_string(), get_now_string(), get_now_string()],
      menu_s: [false, false, false],
      // 잔금 검색 관련
      userPayment_b: ["", "", ""],
      payDate_b: [get_now_string(), get_now_string(), get_now_string()],
      menu_b: [false, false, false],
    };
  },
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
  },
  methods: {
    getDepositInfo() {
      let query = `contract_id=${this.home.id}`;

      // 계약정보 체크

      getContract(this.home.id)
        .then((result) => {
          console.log(result);
          this.noMsg = false;
          if (result.data.contractorName == "") {
            this.noMsg = true;
          }
        })
        .catch((e) => {
          console.log(e);
          this.noMsg = true;
        });

      getLeaseDepositInfo(this.home.id).then((result) => {
        this.depositInfoData = result.data;
        console.log("DEPOSIT::DATA::", result.data);
      });
    },
    calculater(type, idx) {
      var want_date = "";
      var want_fee = "";
      var paymentDate = "";
      if (type == "down") {
        want_date = this.payDate[idx];
        want_fee = this.userPayment[idx];
        paymentDate = this.depositInfoData.downs[idx].paymentDate;
      } else if (type == "second") {
        want_date = this.payDate_s[idx];
        want_fee = this.userPayment_s[idx];
        paymentDate = this.depositInfoData.seconds[idx].paymentDate;
      } else {
        want_date = this.payDate_b[idx];
        want_fee = this.userPayment_b[idx];
        paymentDate = this.depositInfoData.balance[idx].paymentDate;
      }
      let query = `want_date=${want_date}&&paymentDate=${paymentDate}&&want_fee=${
        want_fee != 0 ? want_fee.replace(/,/g, "") : 0
      }`;

      getChargesCalculator(this.home.id, query)
        .then(({ data }) => {
          console.log("getChargesCalculator", data);
          if (type == "down") {
            this.depositInfoData.downs[idx].paymentDate =
              data.downs[idx].paymentDate;
            this.depositInfoData.downs[idx].balance = data.downs[idx].balance;
            this.depositInfoData.downs[idx].payDate = data.downs[idx].payDate;
            this.depositInfoData.downs[idx].paidAmount =
              data.downs[idx].paidAmount;
            this.depositInfoData.downs[idx].discount = data.downs[idx].discount;
            this.depositInfoData.downs[idx].delayedFee =
              data.downs[idx].delayedFee;
            this.depositInfoData.downs[idx].calculated =
              data.downs[idx].calculated;
            this.depositInfoData.downs[idx].DD_DAY = data.downs[idx].DD_DAY;

            // 잔액계산(약정금액-할인금액+연체금액-납부금액)
            this.depositInfoData.downs[idx].janaeck =
              Number(data.downs[idx].balance) -
              Number(data.downs[idx].balance2);
            if (
              data.downs[idx].discount != 0 &&
              data.downs[idx].discount != null &&
              data.downs[idx].discount != ""
            ) {
              this.depositInfoData.downs[idx].janaeck =
                Number(this.depositInfoData.downs[idx].janaeck) -
                Number(data.downs[idx].discount);
            }
            if (
              data.downs[idx].delayedFee != 0 &&
              data.downs[idx].delayedFee != null &&
              data.downs[idx].delayedFee != ""
            ) {
              this.depositInfoData.downs[idx].janaeck =
                Number(this.depositInfoData.downs[idx].janaeck) +
                Number(data.downs[idx].delayedFee);
            }
            this.depositInfoData.downs[idx].janaeck =
              Number(this.depositInfoData.downs[idx].janaeck) -
              Number(data.downs[idx].paidAmount);
          } else if (type == "second") {
            this.depositInfoData.seconds[idx].paymentDate =
              data.downs[idx].paymentDate;
            this.depositInfoData.seconds[idx].balance = data.downs[idx].balance;
            this.depositInfoData.seconds[idx].payDate = data.downs[idx].payDate;
            this.depositInfoData.seconds[idx].paidAmount =
              data.downs[idx].paidAmount;
            this.depositInfoData.seconds[idx].discount =
              data.downs[idx].discount;
            this.depositInfoData.seconds[idx].delayedFee =
              data.downs[idx].delayedFee;
            this.depositInfoData.seconds[idx].calculated =
              data.downs[idx].calculated;
            this.depositInfoData.seconds[idx].DD_DAY = data.downs[idx].DD_DAY;

            // 잔액계산(약정금액-할인금액+연체금액-납부금액)
            this.depositInfoData.seconds[idx].janaeck =
              Number(data.seconds[idx].balance) -
              Number(data.seconds[idx].balance2);
            if (
              data.seconds[idx].discount != 0 &&
              data.seconds[idx].discount != null &&
              data.seconds[idx].discount != ""
            ) {
              this.depositInfoData.seconds[idx].janaeck =
                Number(this.depositInfoData.seconds[idx].janaeck) -
                Number(data.seconds[idx].discount);
            }
            if (
              data.seconds[idx].delayedFee != 0 &&
              data.seconds[idx].delayedFee != null &&
              data.seconds[idx].delayedFee != ""
            ) {
              this.depositInfoData.seconds[idx].janaeck =
                Number(this.depositInfoData.seconds[idx].janaeck) +
                Number(data.seconds[idx].delayedFee);
            }
            this.depositInfoData.seconds[idx].janaeck =
              Number(this.depositInfoData.seconds[idx].janaeck) -
              Number(data.seconds[idx].paidAmount);
          } else {
            this.depositInfoData.balance[idx].paymentDate =
              data.balance[idx].paymentDate;
            this.depositInfoData.balance[idx].balance =
              data.balance[idx].balance;
            this.depositInfoData.balance[idx].payDate =
              data.balance[idx].payDate;
            this.depositInfoData.balance[idx].paidAmount =
              data.balance[idx].paidAmount;
            this.depositInfoData.balance[idx].discount =
              data.balance[idx].discount;
            this.depositInfoData.balance[idx].delayedFee =
              data.balance[idx].delayedFee;
            this.depositInfoData.balance[idx].calculated =
              data.balance[idx].calculated;
            this.depositInfoData.balance[idx].DD_DAY = data.balance[idx].DD_DAY;

            // 잔액계산(약정금액-할인금액+연체금액-납부금액)
            this.depositInfoData.balance[idx].janaeck =
              Number(data.balance[idx].balance) -
              Number(data.balance[idx].balance2);
            if (
              data.balance[idx].discount != 0 &&
              data.balance[idx].discount != null &&
              data.balance[idx].discount != ""
            ) {
              this.depositInfoData.balance[idx].janaeck =
                Number(this.depositInfoData.balance[idx].janaeck) -
                Number(data.balance[idx].discount);
            }
            if (
              data.balance[idx].delayedFee != 0 &&
              data.balance[idx].delayedFee != null &&
              data.balance[idx].delayedFee != ""
            ) {
              this.depositInfoData.balance[idx].janaeck =
                Number(this.depositInfoData.balance[idx].janaeck) +
                Number(data.balance[idx].delayedFee);
            }
            this.depositInfoData.balance[idx].janaeck =
              Number(this.depositInfoData.balance[idx].janaeck) -
              Number(data.balance[idx].paidAmount);
          }
        })
        .catch((e) => {
          this.$store.commit("ERROR", "시스템 오류입니다.");
          console.log(e);
        });
    },
    inputPayment(type, idx) {
      if (type == "down") {
        if (isNaN(this.userPayment[idx].replace(/,/g, ""))) {
          this.userPayment[idx] = "";
          return;
        }
        this.userPayment[idx] = this.filter(this.userPayment[idx]);
      } else if (type == "second") {
        if (isNaN(this.userPayment_s[idx].replace(/,/g, ""))) {
          this.userPayment_s[idx] = "";
          return;
        }
        this.userPayment_s[idx] = this.filter(this.userPayment_s[idx]);
      } else {
        if (isNaN(this.userPayment_b[idx].replace(/,/g, ""))) {
          this.userPayment_b[idx] = "";
          return;
        }
        this.userPayment_b[idx] = this.filter(this.userPayment_b[idx]);
      }
    },
    filter(val) {
      if (val == 0) return 0;
      if (val != "" && val != null && val != undefined) {
        val = Number(val);
        return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  mounted() {
    this.getDepositInfo();
  },
};
</script>
