<template>
  <v-container class="vh-footer pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">입주민 투표</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops">
      <div class="vote-box">
        <strong class="vote-title"
          >※ 투표는 세대관리자만 가능하며 세대관리자가 2명 이상인 경우, 1표만
          행사할 수 있습니다.</strong
        >
      <VoteListComponent/>
      </div>
    </v-card>


  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import VoteListComponent from "@/components/Vote/VoteListComponent";

export default {
  name: "VoteList",
  components: {
    Back,
    Weblogo,
    VoteListComponent,
  },
  data() {
    return {

    };
  },
  computed: {

  },
  methods: {

  },
  created() {

  },
};
</script>
