<template>
  <v-container class="vh-footer ps-re lynn-mobile lynn-web-in bg-gray" fluid>
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">회원정보수정</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text>
        <v-form v-model="valid">
          <v-text-field
            v-model.trim="form.name"
            label="이름"
            required
            :rules="nameRules"
            :disabled="user.is_verified_name"
          ></v-text-field>
          <v-text-field
            v-model.trim="form.email"
            label="아이디(이메일)"
            :rules="emailRules"
            disabled
            required
          ></v-text-field>

          <v-btn
            v-if="!user.is_verified_name"
            color="primary"
            class="ft-r ml20 mt15"
            elevation="0"
            small
            @click="openPhoneNumberDialog()"
            >변경</v-btn
          >
          <v-text-field
            type="number"
            v-model.trim="form.mobile"
            label="휴대폰번호"
            :rules="phoneRules"
            required
            :disabled="user.is_verified_name"
            readonly
          ></v-text-field>

          <v-btn
            color="primary"
            class="ft-r ml20 mt15"
            elevation="0"
            small
            @click="pwDialog = true"
            >변경</v-btn
          >
          <v-text-field label="비밀번호" disabled required></v-text-field>
        </v-form>
      </v-card-text>

      <v-btn
        color="primary"
        class="fts18 ftw7 mw-400 pwidth100"
        min-height="50"
        :disabled="!is_form_ready"
        @click="updateInfo()"
        >확인</v-btn
      >

      <p class="mt20 mb20" style="color: #8c8c8c">
        더 이상 서비스를 이용하지 않으신다면
        <router-link :to="{ path: 'withdrawal' }" style="color: #8c8c8c"
          ><b><u>회원 탈퇴</u></b></router-link
        >가 가능합니다.
      </p>
    </v-card>
    <v-dialog v-model="pwDialog" max-width="300px">
      <v-card>
        <v-card-title class="justify-center color-1 fts18">
          비밀번호 변경
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model.trim="oldPassword"
            label="현재 비밀번호"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :rules="passwordRules"
            required
          ></v-text-field>
          <v-text-field
            v-model.trim="newPassword"
            label="새 비밀번호"
            :type="showPassword2 ? 'text' : 'password'"
            :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword2 = !showPassword2"
            :rules="passwordRules"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="pwDialog = false"> 닫기 </v-btn>
          <v-btn color="primary" text @click="changePassword()"> 저장 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="phoneNumberDialog" max-width="300px" persistent>
      <v-card>
        <v-card-title class="justify-center color-1 fts18">
          전화번호 변경
        </v-card-title>
        <v-card-text>
          <v-text-field
            type="number"
            v-model.trim="rawForm.mobile"
            label="전화번호"
            :rules="phoneRules"
            disabled
            required
          ></v-text-field>

          <v-text-field
            type="number"
            v-model.trim="newMobile"
            label="새 전화번호"
            :rules="phoneRules"
            :disabled="newMobileSend"
            required
          ></v-text-field>

          <v-btn
            width="180px"
            @click="changePhoneNumber()"
            elevation="0"
            :disabled="newMobile.length < 11 || rawForm.mobile == newMobile"
            block
          >
            인증코드 보내기
          </v-btn>
          <!-- :disabled="!newMobileSend" -->
          <v-text-field
            v-if="newMobileSend"
            name="name"
            label="인증코드 입력"
            id="newMobileCode"
            v-model="newMobileCode"
          ></v-text-field>
          <v-btn
            v-if="newMobileSend"
            color="primary"
            width="180px"
            @click="confirmCode()"
            elevation="0"
            :disabled="!newMobileCode"
            block
            class="mb-3"
          >
            전화번호 변경
          </v-btn>
          <v-btn
            v-if="newMobileSend"
            width="180px"
            @click="sendAttributeVerificationCode()"
            elevation="0"
            block
          >
            재발송
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="phoneNumberDialog = false">
            닫기
          </v-btn>
          <!--  <v-btn color="primary" text @click="confirmCode()">
            전화번호 변경
          </v-btn> -->
          <!-- :disabled="newMobile.length < 11 || !newMobileSend || newMobileCode" -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getVillageList, updateUserData } from "@/api/index";
import { copy } from "@/utils/utils";
import Back from "@/assets/images/icon/icon-back.svg";

import Weblogo from "@/assets/images/common/main-logo.svg";

export default {
  components: {
    Back,
    Weblogo,
  },
  name: "MemberInfoUpdate",
  data() {
    return {
      // data
      rawForm: {
        id: "",
        name: "",
        email: "",
        mobile: "",
      },
      form: {
        id: "",
        name: "",
        email: "",
        mobile: "",
      },

      villageList: [],

      oldPassword: "",
      newPassword: "",

      // Conditions
      pwDialog: false,
      showPassword: false,
      showPassword2: false,

      phoneNumberDialog: false,
      newMobile: "",
      newMobileSend: false,
      newMobileCode: "",

      // Rules
      valid: false,
      nameRules: [
        (v) => !!v || "이름을 입력하세요.",
        (v) => v.length < 20 || "이름은 20자까지만 가능합니다.",
      ],
      passwordRules: [
        (v) => !!v || "비밀번호를 입력하세요",
        (v) => v.length > 7 || "8자 이상으로 입력해주세요.",
      ],
      emailRules: [
        (v) => !!v || "아이디를 입력하세요.",
        (v) => /.+@.+/.test(v) || "아이디는 이메일 형식이어야합니다.",
      ],
      phoneRules: [
        (v) => !!v || "전화번호를 입력하세요",
        (v) => v.length < 13 || "전화번호가 너무 깁니다.",
      ],
    };
  },
  computed: {
    is_form_ready() {
      return this.form.name && this.form.email && this.form.mobile;
    },
    mobile_with_code() {
      return "+82" + this.form.mobile;
    },
    user() {
      return this.$store.getters.getUser;
    },
    home() {
      return this.$store.getters.getHome;
    },
    homeList() {
      return this.$store.getters.getHomeList;
    },
    contractHomes() {
      return this.$store.getters.getContractList;
    },
    tenantHomes() {
      return this.$store.getters.getTenantList;
    },
  },
  methods: {
    // APIs
    init() {
      this.$store.dispatch("USER_REFRESH");
      let temp = {
        id: this.user.id,
        name: this.user.name,
        email: this.user.email,
        mobile: this.user.mobile,
      };

      this.form = copy(temp);
      this.rawForm = copy(temp);
    },
    get_villageList() {
      // 단지 정보 세팅
      getVillageList().then((result) => {
        this.villageList = result.data;
      });
    },
    updateInfo(onlyPhoneNumber = false) {
      // 회원 정보 수정 - Cognito

      // 변경된 요소만 처리
      let attribute = {};

      if (onlyPhoneNumber) {
        attribute = {
          phone_number: `+82${this.newMobile}`,
        };
        this.form.mobile = this.newMobile;
      } else {
        if (this.rawForm.mobile != this.form.mobile) {
          attribute.phone_number = this.mobile_with_code;
        }
        if (this.rawForm.name != this.form.name) {
          attribute.name = this.form.name;
        }
        // 변경사항 없을 시,
        if (!Object.keys(attribute).length) {
          this.$router.push({ name: "mypage" });
          return;
        }
      }

      this.$store
        .dispatch("updateAttributes", attribute)
        .then((res) => {
          updateUserData(this.form.id, this.form).then((res) => {
            this.$store.dispatch("USER_REFRESH");
          });
          if (onlyPhoneNumber) {
            this.newMobileSend = true;
            this.$store.commit("SUCCESS", "인증코드가 발송되었습니다.");
          } else {
            this.$router.push({ name: "mypage" });
            // 유저정보 업데이트
            this.$store.commit("SUCCESS", "회원정보가 수정되었습니다.");
          }
        })
        .catch((err) => {
          this.$store.dispatch("LOGGING", {
            error: err,
            tags: `MemberInfoUpdate|cognitoAPI|signUp(username=${this.email},phone_number=${this.mobile_with_code},name=${this.form.name})`,
            etc: {}, // Put anythings you want to log as object
          });
          if (err.code == "NotAuthorizedException") {
            this.$store.commit(
              "ERROR",
              "접속정보가 만료되었습니다. 다시 로그인 해주세요."
            );
            this.$router.push({ name: "login" });
          } else {
            this.$store.commit(
              "ERROR",
              "가입할 수 없습니다. 관리자에게 문의하여 주십시오."
            );
          }
        });
    },
    changePassword() {
      if (this.newPassword.length < 8) {
        this.$store.commit("ERROR", "비밀번호는 8자 이상 지정가능합니다.");
        return;
      }

      // 변경 전 토큰 갱신
      this.$store.dispatch("refreshToken").then((result) => {
        this.$store
          .dispatch("changePassword", {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          })
          .then((data) => {
            let passwordData = {
              update_type: "PASSWORD",
              password: this.newPassword,
            };
            updateUserData(this.form.id, passwordData);
            this.$store.commit("SUCCESS", "비밀번호가 수정되었습니다.");
            this.pwDialog = false;
            this.oldPassword = "";
            this.newPassword = "";
          })
          .catch((err) => {
            this.$store.dispatch("LOGGING", {
              error: err,
              tags: `MemberInfoUpdate|cognitoAPI|changePassword)`,
              etc: {}, // Put anythings you want to log as object
            });
            if (err.code == "InvalidParameterException") {
              this.$store.commit("ERROR", "현재 비밀번호가 일치하지 않습니다.");
            } else if (err.code == "LimitExceededException") {
              this.$store.commit(
                "ERROR",
                "요청 가능 횟수가 초과하였습니다, 잠시 후 다시 시도하세요."
              );
            } else {
              this.$store.commit("ERROR", "관리자에게 문의하여 주십시오.");
            }
          });
      });
    },
    openPhoneNumberDialog() {
      this.init();
      this.phoneNumberDialog = true;
      this.newMobile = "";
      this.newMobileSend = false;
      this.newMobileCode = "";
    },
    changePhoneNumber() {
      this.newMobileSend = true;
      this.updateInfo(true);
    },
    confirmCode() {
      this.$store
        .dispatch("verifyAttribute", {
          attributeName: "phone_number",
          verificationCode: this.newMobileCode,
        })
        .then((res) => {
          // res == 'SUCCESS'
          this.$store.commit(
            "SUCCESS",
            "전화번호가 정상적으로 변경되었습니다."
          );
          this.phoneNumberDialog = false;
        })
        .catch((err) => {
          this.$store.commit("ERROR", "인증코드를 확인해주세요.");

          // Logging err
          this.$store.dispatch("LOGGING", {
            error: err,
            tags: `ConfirmPage|cognitoAPI|confirmRegistration(username=${this.form.email},code=${this.newMobileCode})`,
            etc: {}, // Put anythings you want to log as object
          });
        });
    },
    sendAttributeVerificationCode() {
      this.$store.dispatch("getAttributeVerificationCode", {
        attributeName: "phone_number",
      });
    },
  },
  created() {
    this.init();
    this.get_villageList();
  },
};
</script>
