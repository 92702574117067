import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import auth from "./modules/auth";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: auth,
  },
  plugins: [
    createPersistedState({
      paths: ["auth"],
    }),
  ],
  state: {
    snackbar: {},
    show_confirm: false,
    dialog: { show: false },
    info: {
      signupcheckwarn: "",
      signupcheckHomeId: 0,
      onetimeLoad: false,
    },

    data: {
      villageList: [],
      interestedList: [],
      verification: {},
      userVerificationInfo: {
        text:
          "계약자 또는 입주자 인증을 진행하시면 관련 메뉴를 열람하실 수 있습니다.",
      },
      winVerification: {},
    },
    switchMode: true,
    navTabIndex: null,
    hub: false,
  },
  getters: {
    getIdToken(state) {
      if (state.auth) {
        if (state.auth.isLoggedIn) {
          if (state.auth.cognitoUser) {
            return state.auth.cognitoUser.tokens.IdToken;
          }
        }
      }
      return null;
    },
    getSnackBar(state) {
      return state.snackbar;
    },
    getDialog(state) {
      return state.dialog;
    },
  },
  mutations,
  actions,
});
