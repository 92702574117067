<template>
  <div style="padding-bottom: 100px">
    <!-- TODO LYN-895: 예약에 관한 설명을 나타내는 필드. 완료 by bong
    -->
    <ul class="resinfo-list" v-if="reservationMaster.description">
      <li class="ril-text" v-html="reservationMaster.description"></li>
    </ul>
    <Cm />
    <v-form>
      <v-select
        class="mt-10"
        v-model="form.master"
        :items="villageList"
        item-text="village_name"
        item-value="id"
        label="단지 선택"
        @change="getReservationDetailAPI()"
      ></v-select>
      <v-subheader class="pd0">방문일자</v-subheader>
      <v-date-picker
        v-model="form.date"
        :allowed-dates="allowedDates"
        @change="sync"
        :picker-date.sync="pickerDate"
        :day-format="(date) => new Date(date).getDate()"
        locale="KR"
        full-width
        no-title
      ></v-date-picker>
      <v-subheader class="pd0">방문시간</v-subheader>
      <v-slide-group :value="allowed_times.indexOf(form.timeinfo)">
        <v-slide-item
          class="mr10 nh-50"
          v-for="timeinfo in allowed_times"
          :key="timeinfo.id"
          v-slot="{ active, toggle }"
        >
          <v-btn
            :value="timeinfo"
            :disabled="timeinfo.disabled"
            :input-value="active"
            active-class="v-active"
            v-bind:class="[
              {
                'v-active':
                  timeinfo.id === form.timeinfo.id &&
                  form.date === sel_date &&
                  is_modify,
              },
              'pd10 color-2 bgc-3',
            ]"
            depressed
            @click="
              toggle;
              form.timeinfo = timeinfo;
              sel_date = '';
            "
          >
            {{ timeinfo.text }} <br />
            {{ timeinfo.tooltip }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
      <v-text-field
        v-model="form.name"
        label="이름"
        required
        readonly
      ></v-text-field>
      <v-text-field
        type="number"
        v-model="form.contact"
        label="방문자 전화번호"
        :counter="12"
        required
      ></v-text-field>
      <v-checkbox
        v-if="!hasParkingLot"
        class="mt5"
        v-model="parkingInfo"
        label="견본주택 내 주차 불가한 점을 인지하고 예약합니다."
      ></v-checkbox>
    </v-form>
    <v-btn
      color="primary"
      class="fts18 ftw7 mw-400 pwidth100 mt15"
      min-height="50"
      block
      :disabled="!is_form_ready"
      v-if="!is_modify"
      @click="postReservationAPI"
    >
      예약하기
    </v-btn>
    <v-btn
      color="primary"
      class="fts18 ftw7 mw-400 pwidth100 mt15"
      min-height="50"
      v-if="is_modify"
      block
      :disabled="!is_form_ready"
      @click="putReservationAPI"
    >
      수정하기
    </v-btn>
  </div>
</template>

<script>
import {
  getReservation,
  getReservationMasterList,
  getReservationMaster,
  postReservation,
  putReservation,
} from "@/api/index.js";
import { get_now_string } from "@/utils/time.js";
import Cm from "@/components/CoranaMessage";

export default {
  name: "VisitReservationForm",
  components: {
    Cm,
  },
  data() {
    return {
      reservation_code: "VISIT_RESERVATION", // 예약 타입

      menu: false,
      form: {
        master: 0, // 예약마스터 id
        user: 0, // 유저 id
        time: 0, // 선택한 차수 id
        date: get_now_string(), // 선택한 일자
        timeinfo: { text: "" }, // 선택한 차수 정보
        name: "",
        contact: "",
      },
      hasParkingLot: true,
      parkingInfo: false,
      pickerDate: null,
      reservationMaster: {},

      villageList: [],
      allowed_dates: [],
      allowed_times: [],

      sel_date: "", // (수정의 경우) 저장된 날짜
      selectVillageId: 0,
      reservationID: 0,
    };
  },
  watch: {
    pickerDate(val) {
      this.next_prev_month(val);
    },
  },
  computed: {
    formed_time() {
      return `${this.form.date} ${this.form.timeinfo.text}`;
    },
    is_form_ready() {
      return (
        this.form.master &&
        this.form.date &&
        this.form.timeinfo.id &&
        this.form.name &&
        this.form.contact
      );
    },
    user() {
      return this.$store.getters.getUser;
    },
    is_modify() {
      return this.reservationID > 0;
    },
  },
  methods: {
    init() {
      // TODO : 수정의 경우, params에 id가 담겨온다. 해당 id로 조회하는 API 필요
      if (this.reservationID) {
        this.getReservationAPI(this.reservationID);
      } else {
        // 유저 정보 세팅
        this.form.user = this.user.id;
        this.form.name = this.user.name;
        this.form.contact = this.user.mobile;
      }

      // 방문예약 가능한 단지 정보 조회 (API)
      this.getMasterListAPI();
    },
    getMasterListAPI() {
      // 해당 단지에서 진행중인 사전점검 예약의 최신본을 가져온다.
      this.allowed_dates = [];

      let query = `code=${this.reservation_code}&&date=${get_now_string()}`;

      getReservationMasterList(query).then((re) => {
        if (re.data.length) {
          if (this.selectVillageId > 0) {
            re.data.forEach((item) => {
              if (item.village === this.selectVillageId) {
                this.reservationMaster = item;
                this.form.master = item.id;
                this.villageList = [item];
              }
            });
          } else {
            this.villageList = re.data;
          }
          this.getReservationDetailAPI();
        } else {
          this.$store.commit("ERROR", "예약가능한 단지 정보가 없습니다.");
        }
      });
    },
    getReservationDetailAPI(custom_date) {
      // 특정 요청한 날짜의 예약마스터의 상세정보(예약가능 시간 등)을 불러온다.
      if (!this.form.master) return;

      let query;
      if (custom_date) {
        query = `date=${custom_date}`;
      } else {
        if (!this.form.date) return "";
        query = `date=${this.form.date}`;
        this.pickerDate = this.form.date.slice(0, 7);
      }

      return getReservationMaster(this.form.master, query).then((re) => {
        //console.log(re);
        this.hasParkingLot = re.data.has_parking_lot;
        this.allowed_dates = re.data.allowed_dates;
        if (!custom_date) {
          this.allowed_times = re.data.allowed_times;
          // 가능한 첫 시간을 지정한다.
          for (let idx in this.allowed_times) {
            if (this.form.time) {
              if (this.form.time == this.allowed_times[idx].id) {
                // 수정 시, 선택 된 시간으로 스크롤.
                this.form.timeinfo = this.allowed_times[idx];
                break;
              }
            } else if (!this.allowed_times[idx].disabled) {
              this.form.timeinfo = this.allowed_times[idx];
              break;
            }
          }
        }
      });
    },
    getReservationAPI(id) {
      getReservation(id)
        .then((re) => {
          this.form.contact = re.data.contact;
          this.form.name = re.data.name;
          this.form.master = re.data.master;
          this.form.timeinfo = re.data.timeinfo;
          this.form.date = re.data.date;
          this.form.time = re.data.time;
          this.sel_date = re.data.date;
          this.form.id = re.data.id;
          this.form.user = re.data.user;

          this.sync();
        })
        .catch((er) => {
          //console.log(er);
        });
    },
    postReservationAPI() {
      if (!this.hasParkingLot && !this.parkingInfo) {
        this.$store.commit("ERROR", "주의사항을 읽고 체크해주세요.");
        return false;
      }
      if (this.is_form_ready) {
        this.form.time = this.form.timeinfo.id;
        postReservation(this.form)
          .then((re) => {
            this.$store.commit("SUCCESS", "예약 되었습니다.");
            this.$router.push({ name: "visitReservation" });
          })
          .catch((er) => {
            let errors = er.response.data.non_field_errors;
            if (errors) {
              if (errors.length) {
                this.$store.commit("ERROR", errors[0]);
                this.getReservationDetailAPI();
              }
            }
          });
      }
    },
    putReservationAPI() {
      if (this.is_form_ready) {
        this.form.time = this.form.timeinfo.id;
        putReservation(this.form.id, this.form)
          .then((re) => {
            this.$store.commit("SUCCESS", "수정 되었습니다.");
            this.$router.push({ name: "visitReservation" });
          })
          .catch((er) => {
            let errors = er.response.data.non_field_errors;
            if (errors) {
              if (errors.length) {
                this.$store.commit("ERROR", errors[0]);
                this.getReservationDetailAPI();
              }
            }
          });
      }
    },
    // Utills
    sync() {
      this.getReservationDetailAPI(null);
    },
    allowedDates(val) {
      return this.allowed_dates.indexOf(val) !== -1;
    },
    next_prev_month(newval) {
      this.getReservationDetailAPI(`${newval}-01`);
    },
    mapLink(map_link) {
      window.open(map_link);
    },
  },
  created() {
    this.selectVillageId = this.$route.params.selectVillageId;
    this.reservationID = Number(this.$route.params.id);

    this.init();
  },
};
</script>
