import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./registerServiceWorker";
import * as filters from "./utils/filter.js";
import panZoom from 'vue-panzoom';
import Meta from 'vue-meta';

Vue.use(Meta, {
  keyName: 'metaInfo',
  //attribute: 'data-vue-meta',
  // ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',  // 변화시키는 구분 값
});

Object.keys(filters).forEach(function(key) {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;
Vue.use(panZoom);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
