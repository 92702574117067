<template>
  <v-container class="vh-footer pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">신고하기</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops">
      <v-card-text class="pl0 pr0">
        <strong class="t-cont-title ftw5 mb15 fts18 lh32"
          >신고 사유를 선택해주세요.</strong
        >
        <div class="mb30">
          <v-radio-group class="pd0 mg0" column mandatory v-model="report_type">
            <v-radio label="부적절한 홍보 게시글" value="A"></v-radio>
            <v-radio
              label="음란성 또는 청소년에게 부적합한 내용"
              value="B"
            ></v-radio>
            <v-radio
              label="명예훼손/사생활 침해 및 저작권 침해 "
              value="C"
            ></v-radio>
            <v-radio label="기타" value="D"></v-radio>
          </v-radio-group>
        </div>
        <v-btn
          color="primary"
          class="fts18 ftw7 mw-700 pwidth100"
          min-height="50"
          @click="postReport()"
          >신고 접수하기</v-btn
        >
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import { postReport } from "@/api/index";

export default {
  name: "LoungeDec",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
      free_board_id: 0,
      report_type: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    postReport() {
      let data = {
        report_type: this.report_type,
        free_board: this.free_board_id,
      };
      postReport(this.free_board_id, data).then((res) => {
        this.$store.commit("SUCCESS", "신고가 접수되었습니다.");
        this.$router.push({ name: "loungeList" });
      });
    },
  },
  created() {
    this.free_board_id = this.$route.params.id;
  },
};
</script>
