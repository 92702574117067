<template>
  <v-container class="vh-footer pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="moveToFAQ" class="back">
        <Back />
      </a>
      <strong class="lo-title">문의하기</strong>
    </div>
    <v-card max-width="750" class="mx-auto nops">
      <v-card-text class="pd0">
        <div class="tabs-box">
          <ul class="tab-menu c-mode">
            <li v-bind:class="{ active: openTab === 0 }">
              <a href="#n" v-on:click="openTab = 0">문의하기</a>
            </li>
            <li v-bind:class="{ active: openTab === 1 }">
              <a href="#n" v-on:click="openTab = 1">문의내역확인</a>
            </li>
          </ul>
        </div>
        <div v-if="openTab == 0">
          <div class="cus-sel type2">
            <v-select
              class="sel-cus"
              label="문의분류*"
              :items="subItems"
              item-value="code"
              item-text="code_name"
              v-model="item.bulletin_type_sub_code"
              menu-props="auto"
              hide-details
              single-line
            ></v-select>
          </div>
          <div class="c-input">
            <input
              type="text"
              placeholder="제목을 입력해주세요*"
              v-model="item.title"
            />
          </div>
          <div class="c-textarea">
            <v-textarea v-model="item.contents"> </v-textarea>
          </div>
          <v-text-field
            v-model="item.writer"
            label="작성자"
            readonly
          ></v-text-field>
          <v-text-field
            type="number"
            label="전화번호"
            v-model="mobile"
            readonly
          ></v-text-field>
          <v-text-field
            type="email"
            label="이메일"
            v-model="email"
            readonly
          ></v-text-field>
          <v-card-actions class="justify-center pd0 pb15 mt20 fts0">
            <v-btn
              class="fts18 ftw7 pwidth48 color-2 bgc-4 br15 box-ns"
              min-height="50"
              @click="moveToFAQ"
            >
              취소
            </v-btn>
            <v-btn
              color="primary"
              class="fts18 ftw7 pwidth48 ml-p4 br15 box-ns"
              min-height="50"
              @click="postBulletinAPI(item)"
              :disabled="!is_form_ready"
            >
              문의접수
            </v-btn>
          </v-card-actions>
        </div>
        <div v-else>
          <ul class="dan-list">
            <li v-for="item in qnaItems" :key="'board-' + item.id">
              <router-link to="">
                <strong class="kitbox"> {{ item.title }}</strong>
                <div class="bottom-cont">
                  <span class="name">{{ item.status_text }}</span>
                  <span class="date">{{ item.createdAtDate }}</span>
                </div>
              </router-link>
            </li>
            <li v-if="!qnaItems.length" class="no-list">
              <router-link to="/notice">
                문의내역이 없습니다.
              </router-link>
            </li>
          </ul>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";
import { getBulletinUtils, postBulletin, getBulletinList } from "@/api/index";
import { copy } from "@/utils/utils";
// import Link from "@/assets/images/cs/link.svg";
// import Del from "@/assets/images/cs/close.svg";

export default {
  name: "Faq",
  components: {
    Back,
    Weblogo,
    // Link,
    // Del,
  },
  data: () => ({
    item_template: {
      user: null,
      village: null,

      bulletin_type_code: "BULLETIN_LINNLIFE_QUESTION",
      bulletin_type_sub_code: "",

      title: "",
      contents: "",
      writer: "",
    },
    item: {},
    mobile: "",
    email: "",
    subItems: [],

    qnaItems: [],
    openTab: 0,
    // files: [],
  }),
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    home() {
      return this.$store.getters.getHome;
    },
    is_form_ready() {
      return this.item.bulletin_type_sub_code && this.item.title;
    },
  },
  methods: {
    getBulletinUtilsAPI() {
      getBulletinUtils("parent_type=BULLETIN_LINNLIFE_QUESTION").then((res) => {
        this.subItems = res.data.subItems.filter((item) => {
          return item.code != "ALL";
        });
      });
    },
    getBulletinListAPI(toTab = false) {
      getBulletinList("type=BULLETIN_LINNLIFE_QUESTION&&only_self=true").then(
        (res) => {
          this.qnaItems = res.data.data;
          // TODO
          // this.lastPage = res.data.total_page
          if (toTab) {
            this.openTab = 1;
          }
          this.init();
        }
      );
    },
    postBulletinAPI(item) {
      postBulletin(item)
        .then((res) => {
          console.log(res.data);
          this.getBulletinListAPI(true);
          this.$store.commit("SUCCESS", "문의내역이 생성되었습니다.");
        })
        .catch((err) => {
          this.$store.commit("ERROR", "문의내역이 생성에 실패하였습니다.");
        });
    },
    init() {
      this.item = copy(this.item_template);
      this.item.writer = this.user.name;
      this.item.user = this.user.id;

      this.mobile = this.user.mobile;
      this.email = this.user.email;
      if (this.home) {
        this.item.village = this.home.village;
        this.item.contents = `[자주찾는 질문]을 참고하시면 빠른 답변 확인이 가능합니다.\n정확한 답변을 위해 아래 작성예시를 참고해주시기 바랍니다.\n\n-작성자(계약자) : ${this.user.name} \n-현장명(단지명) : ${this.home.village_name}\n-동/호수 : ${this.home.dong_ho_text}\n-문의내용 : `;
      } else {
        this.item.contents = `[자주찾는 질문]을 참고하시면 빠른 답변 확인이 가능합니다.\n정확한 답변을 위해 아래 작성예시를 참고해주시기 바랍니다.\n\n-작성자(계약자) : ${this.user.name} \n-현장명(단지명) :\n-동/호수 : \n-문의내용 : `;
      }
    },
    moveToFAQ() {
      this.$router.push({ name: "faq" });
    },
  },
  created() {
    this.getBulletinUtilsAPI();
    this.getBulletinListAPI();
    
    // this should be after above API calling
    this.init();
  },
};
</script>
