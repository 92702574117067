<template>
  <v-container class="pd16" v-if="noMsg">
    <strong class="t-cont-title">계약정보</strong>
    <table class="t-table table1">
      <colgroup>
        <col width="95px" />
        <col width="*" />
      </colgroup>
      <tr>
        <th>계약자명</th>
        <td>{{ contractInfo.contractorName }}</td>
      </tr>
      <tr>
        <th>생년월일/성별</th>
        <!-- TODO: 개인식별번호와 성별 따로 받아오게 하기 -->
        <td>
          {{ identityNumber(contractInfo.contractorIdentityNumber) }}
        </td>
      </tr>
      <tr>
        <th>계약일자</th>
        <td>
          {{ contractInfo.contractDate }}
        </td>
      </tr>
      <tr>
        <th>전화번호</th>
        <td>{{ contractInfo.contractorMobile | hyphen }}</td>
      </tr>
      <tr>
        <th>주택의 표시</th>
        <td>
          {{ contractInfo.assetAddress }}
        </td>
      </tr>
      <tr>
        <th>주택의 유형/종류</th>
        <td>{{ contractInfo.assetCategory }} / {{ contractInfo.assetType }}</td>
      </tr>
      <tr>
        <th>면적타입(㎡)</th>
        <td>{{ areaType }}</td>
      </tr>
    </table>

    <strong class="t-cont-title">
      계약면적
      <span>[면적단위:㎡]</span>
      <p>{{ contractInfo.totalArea }}</p>
    </strong>
    <table class="t-table table2">
      <colgroup>
        <col width="112px" />
        <col width="*" />
      </colgroup>
      <tr>
        <th>공급면적<br />{{ contractArea }}</th>
        <td>
          <div
            class="line-box"
            v-for="supplyArea in contractInfo.supplyArea"
            :key="supplyArea.name"
          >
            <div class="left">{{ supplyArea.name }}</div>
            <div class="right">{{ supplyArea.area }}</div>
          </div>
        </td>
      </tr>
      <tr>
        <th>기타공용면적<br /></th>
        <td>
          <div
            class="line-box"
            v-for="sharedArea in contractInfo.sharedArea"
            :key="sharedArea.name"
          >
            <div class="left">{{ sharedArea.name }}</div>
            <div class="right">{{ sharedArea.area }}</div>
          </div>
        </td>
      </tr>
      <!-- <tr>
        <th>면적 합계</th>
        <td>
          <div class="line-box">
            <div class="right">
              {{ contractInfo.totalArea }}
            </div>
          </div>
        </td>
      </tr> -->
    </table>

    <strong class="t-cont-title">계약조건</strong>
    <table class="t-table table1">
      <colgroup>
        <col width="95px" />
        <col width="*" />
      </colgroup>
      <tr>
        <th>구분</th>
        <td>{{ contractInfo.contractOption }}</td>
      </tr>
      <tr>
        <th>임대보증금</th>
        <td>{{ contractInfo.leaseInfo.rentDeposit | comma }} 원</td>
      </tr>
      <tr>
        <th>월 임대료</th>
        <td>{{ contractInfo.leaseInfo.rent | comma }} 원</td>
      </tr>
      <tr>
        <th>특약조건</th>
        <td>
          {{
            contractInfo.leaseInfo.specialAgreements == ""
              ? "-"
              : contractInfo.leaseInfo.specialAgreements
          }}
        </td>
      </tr>
      <tr>
        <th>임대의무기간</th>
        <!-- <td v-if="home.erp_village_id == '2256AA'">10년</td> -->
        <td>{{ contractInfo.leaseInfo.mandatoryRentalPeriod }}년</td>
      </tr>
      <tr v-if="contractInfo.leaseInfo.renewCount != 0">
        <th>계약갱신차수</th>
        <td>{{ contractInfo.leaseInfo.renewCount }}회차</td>
      </tr>
      <tr>
        <th>임대차 계약기간</th>
        <td>
          <!--          임차인이 임대주택을 인도받은 날로부터-->
          <!--          {{ contractInfo.leaseInfo.rentPeriod }}개월이 되는 날까지-->
          {{
            contractInfo.leaseInfo.rentFrom != null &&
            contractInfo.leaseInfo.rentTo != null
              ? contractInfo.leaseInfo.rentFrom +
                " ~ " +
                contractInfo.leaseInfo.rentTo
              : "임차인이 임대주택을 인도받은 날로부터 24개월이 되는 날까지"
          }}
        </td>
      </tr>
    </table>

    <strong class="t-cont-title">
      임대보증금 약정
      <span class="ft-r">[단위:원]</span>
    </strong>
    <table class="t-table table6">
      <colgroup>
        <col width="33%" />
        <col width="33%" />
        <col width="*" />
      </colgroup>
      <tr class="head">
        <td class="bold">구분</td>
        <td>약정일자</td>
        <td>약정금액</td>
      </tr>
      <tr
        v-for="(payment, i) in contractInfo.leaseInfo.depositDownPayment"
        :key="'down' + i"
      >
        <th>계약금 {{ payment.count > 0 ? payment.count + "차" : "" }}</th>
        <td>{{ payment.paymentDate }}</td>
        <td>{{ payment.balance | comma }}</td>
      </tr>
      <tr
        v-for="(payment, i) in contractInfo.leaseInfo.depositSecondPayment"
        :key="'second' + i"
      >
        <th>중도금 {{ payment.count > 0 ? payment.count + "차" : "" }}</th>
        <td>
          {{ payment == null ? "-" : payment.paymentDate }}
        </td>
        <td>
          {{ payment == null ? "-" : payment.balance | comma }}
        </td>
      </tr>
      <tr
        v-for="(payment, i) in contractInfo.leaseInfo.depositBalance"
        :key="'balance' + i"
      >
        <th>잔금 {{ payment.count > 0 ? payment.count + "차" : "" }}</th>
        <td>{{ payment.paymentDate }}</td>
        <td>{{ payment.balance | comma }}</td>
      </tr>
      <tr class="total">
        <td class="bold">합계</td>
        <td></td>
        <td>{{ contractInfo.leaseInfo.rentDeposit | comma }}</td>
      </tr>
    </table>

    <!-- <table class="t-table table4">
      <colgroup>
        <col width="33%" />
        <col width="*" />
      </colgroup>
      <tr class="total">
        <th>합계</th>
        <td>648,000,000</td>
      </tr>
      <tr>
        <th>대지비</th>
        <td>406,360,800</td>
      </tr>
      <tr>
        <th>건축비</th>
        <td>219,672,000</td>
      </tr>
      <tr>
        <th>부가세</th>
        <td>21,967,200</td>
      </tr>
    </table> -->
    <strong class="t-cont-title">보증금 납부처 및 납부방법</strong>
    <table class="t-table table1 mb15">
      <colgroup>
        <col width="95px" />
        <col width="*" />
      </colgroup>
      <tr>
        <th>납부기관</th>
        <td>{{ contractInfo.bankName }}</td>
      </tr>
      <tr>
        <th>납부계좌</th>
        <td>
          {{
            contractInfo.bankAccount == null || contractInfo.bankAccount == ""
              ? "-"
              : contractInfo.bankAccount
          }}
        </td>
      </tr>
      <tr>
        <th>예금주</th>
        <td>{{ contractInfo.accountHolder }}</td>
      </tr>
    </table>

    <v-divider />
    <table class="t-table table1">
      <colgroup>
        <col width="95px" />
        <col width="*" />
      </colgroup>
      <tr>
        <th>임대사업자명</th>
        <td>{{ contractInfo.leaseInfo.rentalBusinessOperator }}</td>
      </tr>
      <tr>
        <th>주소</th>
        <td>{{ contractInfo.leaseInfo.rboAddress }}</td>
      </tr>
      <tr>
        <th>임대사업자번호</th>
        <td>{{ contractInfo.leaseInfo.rboBusinessRegNumber }}</td>
      </tr>
      <tr>
        <th>사업자번호</th>
        <td>{{ contractInfo.leaseInfo.rboRentalBRN }}</td>
      </tr>
      <tr>
        <th>전화번호</th>
        <td>{{ contractInfo.leaseInfo.customerSupportTelephone }}</td>
      </tr>
    </table>
  </v-container>

  <!-- 계약정보가 없을 때 -->
  <v-container class="pd16" v-else>
    <strong class="t-cont-title">계약정보</strong>
    <strong class="t-cont-title text-center mt-10"
      >계약 정보가 없습니다.</strong
    >
  </v-container>
</template>

<script>
import { getContract, getContractor } from "@/api/contract.js";
export default {
  components: {},
  name: "StatusForRent",
  data() {
    return {
      noMsg: false,
      contractArea: 0,
      contractInfo: {
        contractNo: "",
        contractType: "",
        contractorName: "",
        contractorIdentityNumber: "",
        contractDate: "",
        contractorAddress: "",
        contractorMobile: "",
        moveInDate: "",
        assetAddress: "",
        assetCategory: "",
        assetType: "",
        assetAreaType: "",
        dedicatedArea: 0,
        residentialSharedArea: 0,
        ohterSharedArea: 0,
        contractOption: "",
        bankName: "",
        bankAccount: "",
        accountHolder: "",
        corpRegNumber: "",
        customerSupportTelephone: "",
        sellInfo: null,
        leaseInfo: {
          mandatoryRentalPeriod: 0,
          depositDownPayment: {
            count: 1,
            paymentDate: "계약 체결시",
            balance: 0,
          },
          depositSecondPayment: null,
          depositBalance: {
            count: 1,
            paymentDate: "입주일",
            balance: 0,
          },
          rentDeposit: 0,
          rent: 0,
          rentPeriod: 0,
          renewCount: 0,
          rentFrom: "2020-01-01",
          rentTo: "2020-12-31",
          specialAgreements: "",
          rentalBusinessOperator: "",
          rboAddress: "",
          rboBusinessRegNumber: "",
          rboRentalBRN: "",
          customerSupportTelephone: "",
        },
      },
      contractor: {},
      areaType: "",
    };
  },
  computed: {
    registNumber() {
      let text = "";
      let birthday = this.contractor.contractorBirthday;

      if (birthday != null && birthday !== "" && birthday !== undefined) {
        text =
          birthday.substr(2, 2) + birthday.substr(5, 2) + birthday.substr(8, 2);
        return text + "-*******";
      } else {
        return "";
      }
    },
    home() {
      return this.$store.getters.getHome;
    },
  },
  methods: {
    loadContractInfo() {
      // 계약정보
      let query = `contract_id=${this.home.id}`;
      getContract(this.home.id)
        .then((result) => {
          console.log(result);
          this.contractInfo = result.data;
          this.noMsg = true;

          // 면적타입...
          this.areaType = this.contractInfo.assetAreaType
            .split(" ")[0]
            .toString()
            .split(".")[0];
          this.areaType += this.contractInfo.assetDesignType;

          if (this.contractInfo.contractorName == "") {
            this.noMsg = false;
          }

          //계약면적
          this.contractInfo.supplyArea.filter((item) => {
            if (item.name == "전용면적" || item.name == "주거공용") {
              this.contractArea += Number(item.area);
            }
          });
          this.contractArea = this.contractArea.toFixed(4);
        })
        .catch((e) => {
          console.log(e);
          this.noMsg = false;
        });

      // 계약자 정보
      getContractor(this.home.id).then((result) => {
        this.contractor = result.data;
      });

      // TODO 공급대금 및 납부 일정
    },
    identityNumber(idNum) {
      var idNumList = idNum.split("/");
      console.log(idNumList.length);

      if (idNumList.length > 1) {
        return (
          idNumList[0].substr(0, 6) +
          "(" +
          (idNumList[0].charAt(7) == "2" || idNumList[0].charAt(7) == "4"
            ? "여"
            : "남") +
          ") / " +
          idNumList[1].substr(0, 6) +
          "(" +
          (idNumList[1].charAt(7) == "2" || idNumList[0].charAt(1) == "4"
            ? "여"
            : "남") +
          ")"
        );
      } else {
        return (
          idNum.substr(0, 6) +
          " (" +
          (idNum.charAt(7) == "2" || idNum.charAt(7) == "4" ? "여" : "남") +
          ")"
        );
      }
    },
  },
  created() {
    this.loadContractInfo();
  },
};
</script>
