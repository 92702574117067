<template>
  <v-container class="bg-gray vh-full pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo />
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">대기신청</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops waitform">
      <div>
        <v-form>
          <v-select
            :items="villageList"
            v-model="form.village"
            ref="village"
            item-text="village_name"
            item-value="id"
            label="단지 선택"
            :readonly="form.id"
            @change="getRoomTypeAPI(form.village)"
          ></v-select>

          <v-select
            ref="room_type"
            v-model="form.room_type"
            :items="roomTypeList"
            item-text="text"
            item-value="id"
            label="신청주택형"
            :no-data-text="
              form.village
                ? '선택 가능한 주택형이 없습니다.'
                : '단지를 지정하세요.'
            "
          ></v-select>
          <v-row class="mt8">
            <v-col class="pd0 mg0 text-left" cols="2">
              <strong class="ds-b fts16 ftw4">선호층</strong>
            </v-col>
            <v-col class="text-left">
              <v-radio-group
                row
                class="pd0 mg0"
                v-model="form.floor_type"
                ref="floor_type"
              >
                <v-radio
                  v-for="floor_type in floorList"
                  :key="floor_type.code"
                  :value="floor_type.code"
                  :label="floor_type.text"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-select
            :items="monthList"
            v-model="form.move_in_month"
            item-text="text"
            item-value="value"
            ref="move_in_month"
            label="이사일정"
          ></v-select>
          <v-subheader class="pd0 fts16 ftw5 mt15">신청자 정보</v-subheader>
          <v-text-field
            v-model.trim="form.name"
            ref="name"
            maxlength="20"
            label="이름"
            readonly
          ></v-text-field>
          <v-text-field
            label="주소 (시/도/군 까지 입력 ex.경기도 성남시 분당구)"
            v-model="form.address"
            ref="address"
            maxlength="100"
            required
          ></v-text-field>
          <v-text-field
            v-model="form.age"
            ref="age"
            label="나이"
            required
            type="number"
          ></v-text-field>
          <v-row class="mt8">
            <v-col class="pd0 mg0 text-left" cols="2">
              <strong class="ds-b fts16 ftw4">성별</strong>
            </v-col>
            <v-col class="text-left">
              <v-radio-group
                row
                class="pd0 mg0"
                v-model="form.gender"
                ref="gender"
              >
                <v-radio value="M" label="남"></v-radio>
                <v-radio value="W" label="여"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-text-field
            v-model="form.mobile"
            ref="mobile"
            type="number"
            label="휴대전화"
            readonly
          ></v-text-field>
          <v-text-field
            v-model="form.email"
            ref="email"
            label="이메일"
            :readonly="true"
            required
          ></v-text-field>
          <div class="tems-box">
            <strong class="ds-b pd0 fts14 ftw5 mt15 text-left">
              개인정보 수집 및 이용
            </strong>
            <ul class="tems-item">
              <li>
                <strong>수집이용 목적</strong>
                <p>단지안내 및 상담</p>
              </li>
              <li>
                <strong>수집항목</strong>
                <p>성명, 주소, 나이, 성별, 연락처, 이메일</p>
              </li>
              <li>
                <strong>보유 및 이용기간</strong>
                <p>1년(정보주체의 삭제 요청 시 즉시 파기)</p>
              </li>
            </ul>
          </div>
          <v-row class="mt0">
            <v-radio-group class="mt5" ref="privacyAgree" row>
              <v-checkbox
                v-model="privacyAgree"
                label="개인정보 수집 및 이용 동의"
              ></v-checkbox>
            </v-radio-group>
          </v-row>
        </v-form>

        <v-card-actions class="justify-center pd0 mt20" v-if="is_modify">
          <v-btn
            class="pd10 color-2 bgc-4 pwidth48 mr-p4 fts18 ftw7"
            min-height="50"
            @click="back()"
            >뒤로가기</v-btn
          >
          <v-btn
            color="primary"
            class="fts18 ftw7 mw-400 pwidth48 mg0 fts18 ftw7"
            min-height="50"
            @click="update()"
            >변경사항 저장</v-btn
          >
        </v-card-actions>
        <v-btn
          v-if="!is_modify"
          color="primary"
          class="fts18 ftw7 mw-400 pwidth100 mt15"
          min-height="50"
          block
          @click="regist()"
        >
          대기신청
        </v-btn>
      </div>
    </v-card>
    <v-dialog v-model="updateDialog" max-width="290">
      <v-card>
        <v-card-title class="justify-center"> </v-card-title>
        <v-card-text class="text-left ftw7">
          신청주택형 변경 시 새로운 대기 순번이 발급됩니다. 수정하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="update(true)">
            예
          </v-btn>
          <v-btn color="primary darken-1" text @click="updateDialog = false">
            아니오
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style>
.v-input__slot {
  margin-bottom: 5px;
}
.waitform input {font-size:16px;}
</style>

<script>
import {
  getVillageList,
  getRoomType,
  postRentalWaitRequest,
  getRentalWaitRequest,
  putRentalWaitRequest,
  getUserData,
} from "@/api/index";

import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/main-logo.svg";

export default {
  name: "WaitRequestFormPage",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
      // Forms
      form: {
        user: 0, // 로그인 시 = id, 가입하지 않은 회원 = 0
        village: 0,
        room_type: 0, // 신청주택형
        floor_type: "",
        move_in_month: "01",
        name: "",
        address: "",
        age: "",
        gender: "",
        mobile: "",
        email: "",
      },
      privacyAgree: false, // 개인정보 수집 동의

      // Items
      villageList: [],
      roomTypeList: [],
      floorList: [],
      monthList: [
        { text: "01 월", value: "01" },
        { text: "02 월", value: "02" },
        { text: "03 월", value: "03" },
        { text: "04 월", value: "04" },
        { text: "05 월", value: "05" },
        { text: "06 월", value: "06" },
        { text: "07 월", value: "07" },
        { text: "08 월", value: "08" },
        { text: "09 월", value: "09" },
        { text: "10 월", value: "10" },
        { text: "11 월", value: "11" },
        { text: "12 월", value: "12" },
      ],

      is_modify: false,

      saved_room_type: 0,
      updateDialog: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    // APIs

    getVillageListAPI() {
      getVillageList("kind_code=FOR_RENT&&is_rental_waiting_enabled=true").then(
        (result) => {
          this.villageList = result.data;
        }
      );
    },
    getRoomTypeAPI(village) {
      getRoomType(`village_id=${village}`).then((result) => {
        this.roomTypeList = result.data;
      });
    },
    getRentalWaitRequestAPI(id) {
      getRentalWaitRequest(id).then((result) => {
        this.form = result.data;
        this.saved_room_type = this.form.room_type;
        this.getRoomTypeAPI(result.data.village);
      });
    },
    init() {
      // 선호층 - TODO : 공통코드 API
      this.floorList = [
        { code: "L", text: "1~4층" },
        { code: "H", text: "5층 이상" },
      ];
      this.getVillageListAPI();
      if (this.$route.params.request_id) {
        // Edit loaded
        this.is_modify = true;
        this.privacyAgree = true;
        this.getRentalWaitRequestAPI(this.$route.params.request_id);
      } else {
        // Create new
        this.form.user = this.user.id;
        this.form.name = this.user.name;
        this.form.mobile = this.user.mobile;
        this.form.email = this.user.email;
      }
    },

    regist() {
      if (this.validate()) {
        postRentalWaitRequest(this.form)
          .then((result) => {
            this.$store.commit("SUCCESS", "신청되었습니다.");
            this.$router.push({ name: "waitRequestView" });
          })
          .catch((error) => {
            // 중복 신청 시
            console.log(error.response.data);
            this.$store.commit("ERROR", error.response.data);
          });
      }
    },
    update(checked = false) {
      if (this.validate()) {
        if (this.saved_room_type != this.form.room_type && !checked) {
          this.updateDialog = true;
          return;
        }

        this.form.is_reset_wait_number = "Y";
        putRentalWaitRequest(this.form.id, this.form)
          .then((result) => {
            this.$store.commit("SUCCESS", "수정되었습니다.");
            this.$router.push({ name: "waitRequestView" });
          })
          .catch((error) => {
            this.$store.commit("ERROR", "수정 불가한 내역입니다.");

            console.log(error.response);
          });
      }
    },
    back() {
      this.$router.push({ name: "waitRequestView" });
    },
    validate() {
      let varToText = {
        village: "단지를",
        room_type: "신청주택형을",
        floor_type: "선호층을",
        move_in_month: "이사일정을",
        name: "성명을",
        address: "주소를",
        age: "나이를",
        gender: "성별을",
        mobile: "휴대폰 번호를",
        email: "이메일 주소를",
      };

      let isCompleted = true;

      Object.keys(this.form).forEach((key) => {
        if (varToText[key] == undefined) {
          // varToText에 지정되지 않은 것들은 검사하지 않음. (추가된 필드들 때문에 수정때 오류 걸리는 경우가 있어요)
          return;
        }
        if (!this.form[key] && isCompleted) {
          this.$store.commit("ERROR", `${varToText[key]} 선택해주세요.`);
          if (["floor_type", "age"].indexOf(key) > -1) {
            //this.$refs[key].$el.focus();
            isCompleted = false;
          } else {
            try {
              //this.$refs[key].focus();
              isCompleted = false;
            } catch (e) {
              console.log(e);
            }
          }
        }
      });

      if (!this.privacyAgree && isCompleted) {
        this.$store.commit("ERROR", "개인정보 보호 처리 방침에 동의해주세요.");
        this.$refs.privacyAgree.$el.focus();
        isCompleted = false;
      }

      return isCompleted;
    },
  },
  created() {
    this.init();
    getUserData("field=is_verified_name").then((res) => {
      if (!res.data.is_verified_name) {
        let back = `/villageInfoList`;
        if (this.$route.params.selectVillageId) {
          back = `/villageInfo/${this.$route.params.selectVillageId}`;
        }

        this.$store.commit("SET_USERVERIFICATIONINFO", {
          dialog: true,
          text: "대기신청 메뉴 이용을 위해선 실명인증이 필요합니다.",
          redirect: "/waitRequestForm",
          back: back,
          only_verified_name: true,
        });
      } else if (this.$route.params.selectVillageId != null) {
        this.form.village = this.$route.params.selectVillageId;
        this.getRoomTypeAPI(this.form.village);
      }
    });
  },
};
</script>
